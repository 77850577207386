import React from "react";
import { Form, Button } from "react-bootstrap";
import { Controller } from "react-hook-form";
import InputText from "shared/components/Form/InputText";
import InputSelect from "shared/components/Form/InputSelect";
import LabelSelect from "shared/components/LabelSelect";
import GenreSelect from "shared/components/GenreSelect";
import I18n from "shared/lib/I18n";
import Loader from "shared/components/Loader";

const CreateOrUpdate = ({
    albumId,
    initForm,
    isLoading,
    handleSubmit,
    errors,
    register,
    control,
    isSubmitting,
    isDirty,
    onSubmit,
    typeOptions,
    formatOptions,
    referenceOptions,
    location,
    locale,
}) => {
    if (isLoading) {
        return <Loader />;
    }

    return (
        <Form onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}>
            <div className="row">
                <Form.Group className="col-12">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.album.basic.title" />
                    </Form.Label>
                    <InputText
                        {...register("title")}
                        error={
                            errors.title && [
                                I18n.getTranslation(
                                    location,
                                    errors.title.message
                                ),
                            ]
                        }
                    />
                </Form.Group>
                <Form.Group className="col-12">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.album.basic.subtitle" />
                    </Form.Label>
                    <InputText
                        {...register("subtitle")}
                        error={
                            errors.subtitle && [
                                I18n.getTranslation(
                                    location,
                                    errors.subtitle.message
                                ),
                            ]
                        }
                    />
                </Form.Group>
                <Form.Group className="col-6">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.album.basic.type" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="type"
                        render={({ field: { onChange, value, ref } }) => (
                            <InputSelect
                                innerRef={ref}
                                value={
                                    value !== null && value !== undefined
                                        ? {
                                              value: value,
                                              label: I18n.getTranslation(
                                                  location,
                                                  `artist.management.disco.objects.album.basic.typeOptions.${value.toLowerCase()}`
                                              ),
                                          }
                                        : null
                                }
                                options={typeOptions}
                                onChange={(opt) => {
                                    onChange(opt ? opt.value : null);
                                }}
                                isClearable={false}
                                error={
                                    errors.type && [
                                        I18n.getTranslation(
                                            location,
                                            errors.type.message
                                        ),
                                    ]
                                }
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group className="col-6">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.album.basic.format" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="format"
                        render={({ field: { onChange, value, ref } }) => (
                            <InputSelect
                                innerRef={ref}
                                value={
                                    value !== null && value !== undefined
                                        ? {
                                              value: value,
                                              label: I18n.getTranslation(
                                                  location,
                                                  `artist.management.disco.objects.album.basic.formatOptions.${value.toLowerCase()}`
                                              ),
                                          }
                                        : null
                                }
                                options={formatOptions}
                                onChange={(opt) => {
                                    onChange(opt ? opt.value : null);
                                }}
                                isClearable={false}
                                error={
                                    errors.format && [
                                        I18n.getTranslation(
                                            location,
                                            errors.format.message
                                        ),
                                    ]
                                }
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group className="col-6">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.album.basic.label" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="label"
                        render={({ field: { onChange, value, ref } }) => (
                            <LabelSelect
                                innerRef={ref}
                                value={
                                    value !== null && value !== undefined
                                        ? {
                                              value: value.id,
                                              label: value.name,
                                          }
                                        : null
                                }
                                onMatch={(id, label) => {
                                    onChange({
                                        id: id,
                                        name: label,
                                    });
                                }}
                                onUnmatch={() => {
                                    onChange(null);
                                }}
                                enablePortal={albumId}
                                creatable
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group className="col-6">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.album.basic.publisher" />
                    </Form.Label>
                    <InputText {...register("publisher")} />
                </Form.Group>
                <Form.Group className="col-3">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.album.basic.release_date" />
                    </Form.Label>
                    <InputText
                        placeholder={I18n.getTranslation(
                            location,
                            "utils.form.date_placeholder"
                        )}
                        {...register("release_date")}
                        error={
                            errors.release_date && [
                                I18n.getTranslation(
                                    location,
                                    errors.release_date.message
                                ),
                            ]
                        }
                    />
                </Form.Group>
                <Form.Group className="col-6">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.album.basic.genre" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="genre"
                        render={({ field: { onChange, value, ref } }) => (
                            <GenreSelect
                                innerRef={ref}
                                value={
                                    value !== null && value !== undefined
                                        ? {
                                              value: value.id,
                                              label: value.name,
                                          }
                                        : null
                                }
                                onMatch={(id, label) => {
                                    onChange({
                                        id: id,
                                        name: label,
                                        protected: true,
                                    });
                                }}
                                onUnmatch={() => {
                                    onChange(null);
                                }}
                                locale={locale}
                                enablePortal={albumId}
                            />
                        )}
                    />
                </Form.Group>
                <Form.Group className="col-3">
                    <Form.Label>
                        <I18n t="artist.management.disco.objects.album.basic.reference" />
                    </Form.Label>
                    <Controller
                        control={control}
                        name="reference"
                        render={({ field: { onChange, value, ref } }) => (
                            <InputSelect
                                innerRef={ref}
                                value={
                                    value !== null && value !== undefined
                                        ? {
                                              value: value,
                                              label: value,
                                          }
                                        : null
                                }
                                options={referenceOptions}
                                onChange={(opt) => {
                                    onChange(opt ? opt.value : null);
                                }}
                                enablePortal={albumId}
                            />
                        )}
                    />
                </Form.Group>
            </div>

            <div className="d-flex flex-row-reverse">
                <Button
                    variant="secondary ml-2"
                    disabled={isSubmitting || isLoading}
                    onClick={!isSubmitting && !isLoading ? initForm : null}
                    title={I18n.getTranslation(
                        location,
                        "artist.management.disco.objects.album.basic.reset.title"
                    )}
                >
                    <I18n t="utils.button.reset" />
                </Button>
                <Button
                    variant="success"
                    disabled={isSubmitting || isLoading || !isDirty}
                    onClick={
                        !isSubmitting && !isLoading && isDirty
                            ? handleSubmit(onSubmit)
                            : null
                    }
                    title={I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.album.basic.${
                            albumId ? "update" : "create"
                        }.title`
                    )}
                >
                    <I18n t={`utils.button.${albumId ? "edit" : "add"}`} />
                </Button>
            </div>
        </Form>
    );
};

export default CreateOrUpdate;
