import { createSelector } from "reselect";
import moment from "moment";

const currentDate = moment().format("YYYY-MM-DD");

export const getLabels = createSelector(
    [(args) => args.historic],
    (historic) => {
        return [
            ...new Set(
                historic
                    .map((entrie) => entrie.date)
                    .concat(currentDate)
                    .sort()
            ),
        ];
    }
);

export const getData = createSelector(
    [(args) => args.historic, (args) => args.requests],
    (historic, requests) => {
        return historic
            .map((entrie) => ({
                x: entrie.date,
                y: entrie.limit - entrie.remaining,
            }))
            .concat({
                x: currentDate,
                y: requests,
            })
            .sort((a, b) => (a.x > b.x ? 1 : -1));
    }
);
