import React from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { Controller } from "react-hook-form";
import I18n from "shared/lib/I18n";
import InputText from "shared/components/Form/InputText";
import InputNumber from "shared/components/Form/InputNumber";
import InputSelect from "shared/components/Form/InputSelect";
import InputSwitch from "shared/components/Form/InputSwitch";
import LabelPromoSelect from "shared/components/LabelPromoSelect";
import AuthorSelect from "shared/components/AuthorSelect";
import InputDate from "shared/components/Form/InputDate";
import moment from "moment";
import clsx from "clsx";

const CreateOrUpdateModal = ({
    user,
    show,
    handleClose,
    handleSubmit,
    location,
    onSubmit,
    register,
    errors,
    isSubmitting,
    control,
    accountTypeOptions,
    accountRoleOptions,
    accountLanguageOptions,
    serverOptions,
    rolesOptions,
    accountTypeWatch,
    generateToken,
}) => {
    return (
        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <I18n
                        t={`user.manage.${user ? "update" : "create"}.title`}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={!isSubmitting ? handleSubmit(onSubmit) : null}>
                    <Form.Group>
                        <InputSwitch
                            {...register("registration_is_client")}
                            label={I18n.getTranslation(
                                location,
                                "user.manage.user.is_client"
                            )}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="user.manage.user.account_type.title" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="registration_type"
                            render={({ field: { onChange, value, ref } }) => (
                                <InputSelect
                                    innerRef={ref}
                                    value={
                                        value !== null
                                            ? {
                                                  value,
                                                  label: accountTypeOptions.find(
                                                      (a) => value === a.value
                                                  ).label,
                                              }
                                            : null
                                    }
                                    options={accountTypeOptions}
                                    onChange={(opt) => {
                                        onChange(opt ? opt.value : null);
                                    }}
                                    error={
                                        errors.registration_type && [
                                            I18n.getTranslation(
                                                location,
                                                errors.registration_type.message
                                            ),
                                        ]
                                    }
                                    enablePortal
                                />
                            )}
                        />
                    </Form.Group>
                    <div className="row">
                        <div className="col">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t={`user.manage.user.firstname`} />
                                </Form.Label>
                                <InputText
                                    {...register("firstname")}
                                    className={clsx("form-control")}
                                    error={
                                        errors.firstname && [
                                            I18n.getTranslation(
                                                location,
                                                errors.firstname.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                        </div>
                        <div className="col">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t={`user.manage.user.lastname`} />
                                </Form.Label>
                                <InputText
                                    {...register("lastname")}
                                    className={clsx("form-control")}
                                    error={
                                        errors.lastname && [
                                            I18n.getTranslation(
                                                location,
                                                errors.lastname.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="user.manage.user.email" />
                        </Form.Label>
                        <InputText
                            {...register("email")}
                            error={
                                errors.email && [
                                    I18n.getTranslation(
                                        location,
                                        errors.email.message
                                    ),
                                ]
                            }
                        />
                    </Form.Group>
                    <div className="row">
                        {accountTypeWatch === "artist_manager" && (
                            <div className="col">
                                <Form.Group>
                                    <Form.Label>
                                        <I18n t={`user.manage.user.artist`} />
                                    </Form.Label>
                                    <InputText
                                        {...register("registration_artist")}
                                        className={clsx("form-control")}
                                        error={
                                            errors.registration_artist && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.registration_artist
                                                        .message
                                                ),
                                            ]
                                        }
                                        showError={false}
                                    />
                                </Form.Group>
                            </div>
                        )}
                        {["music_service", "cmo", "label_distributor"].includes(
                            accountTypeWatch
                        ) && (
                            <div className="col">
                                <Form.Group>
                                    <Form.Label>
                                        <I18n t={`user.manage.user.company`} />
                                    </Form.Label>
                                    <InputText
                                        {...register("registration_company")}
                                        className={clsx("form-control")}
                                        error={
                                            errors.registration_company && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.registration_company
                                                        .message
                                                ),
                                            ]
                                        }
                                        showError={false}
                                    />
                                </Form.Group>
                            </div>
                        )}
                    </div>
                    {["music_service", "cmo", "label_distributor"].includes(
                        accountTypeWatch
                    ) && (
                        <div className="row">
                            <div className="col">
                                <Form.Group>
                                    <Form.Label>
                                        <I18n t="user.manage.user.role.title" />
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="registration_role"
                                        render={({
                                            field: { onChange, value, ref },
                                        }) => (
                                            <InputSelect
                                                innerRef={ref}
                                                value={
                                                    value !== null
                                                        ? {
                                                              value,
                                                              label: accountRoleOptions[
                                                                  accountTypeWatch
                                                              ]?.find(
                                                                  (a) =>
                                                                      value ===
                                                                      a.value
                                                              ).label,
                                                          }
                                                        : null
                                                }
                                                options={
                                                    accountRoleOptions[
                                                        accountTypeWatch
                                                    ]
                                                }
                                                onChange={(opt) => {
                                                    onChange(
                                                        opt ? opt.value : null
                                                    );
                                                }}
                                                error={
                                                    errors.registration_role && [
                                                        I18n.getTranslation(
                                                            location,
                                                            errors
                                                                .registration_role
                                                                .message
                                                        ),
                                                    ]
                                                }
                                                showError={false}
                                                enablePortal
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col">
                                <Form.Group>
                                    <Form.Label>
                                        <I18n t={`user.manage.user.describe`} />
                                    </Form.Label>
                                    <InputText
                                        {...register("registration_describe")}
                                        className={clsx("form-control")}
                                        error={
                                            errors.registration_describe && [
                                                I18n.getTranslation(
                                                    location,
                                                    errors.registration_describe
                                                        .message
                                                ),
                                            ]
                                        }
                                        showError={false}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    )}
                    <Form.Group>
                        <Form.Label>
                            <I18n t="user.manage.user.language" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="registration_language"
                            render={({ field: { onChange, value, ref } }) => (
                                <InputSelect
                                    innerRef={ref}
                                    value={
                                        value !== null
                                            ? {
                                                  value,
                                                  label: accountLanguageOptions.find(
                                                      (a) => value === a.value
                                                  ).label,
                                              }
                                            : null
                                    }
                                    options={accountLanguageOptions}
                                    onChange={(opt) => {
                                        onChange(opt ? opt.value : null);
                                    }}
                                    error={
                                        errors.registration_language && [
                                            I18n.getTranslation(
                                                location,
                                                errors.registration_language
                                                    .message
                                            ),
                                        ]
                                    }
                                    showError={false}
                                    enablePortal
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="user.manage.user.user_roles" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="user_roles"
                            render={({ field: { onChange, value, ref } }) => (
                                <InputSelect
                                    innerRef={ref}
                                    value={
                                        value
                                            ? value.map(({ id, libelle }) => {
                                                  return {
                                                      value: id,
                                                      label: libelle,
                                                  };
                                              })
                                            : []
                                    }
                                    options={rolesOptions}
                                    onChange={(opt) => {
                                        onChange(
                                            opt
                                                ? opt.map((row) => {
                                                      return {
                                                          id: row.value,
                                                          libelle: row.label,
                                                      };
                                                  })
                                                : []
                                        );
                                    }}
                                    isMulti
                                    error={
                                        errors.user_roles && [
                                            I18n.getTranslation(
                                                location,
                                                errors.user_roles.message
                                            ),
                                        ]
                                    }
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="user.manage.user.label" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="label"
                            render={({ field: { onChange, value, ref } }) => (
                                <LabelPromoSelect
                                    innerRef={ref}
                                    value={
                                        value !== null
                                            ? {
                                                  value: value.id,
                                                  label: value.name,
                                              }
                                            : null
                                    }
                                    onMatch={(id, label) => {
                                        onChange({
                                            id: id,
                                            name: label,
                                        });
                                    }}
                                    onUnmatch={() => {
                                        onChange(null);
                                    }}
                                    defaultOptions
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="user.manage.user.author" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="author"
                            render={({ field: { onChange, value, ref } }) => (
                                <AuthorSelect
                                    innerRef={ref}
                                    value={
                                        value !== null
                                            ? {
                                                  value: value.id,
                                                  label: value.name,
                                              }
                                            : null
                                    }
                                    onMatch={(id, label) => {
                                        onChange({
                                            id: id,
                                            name: label,
                                        });
                                    }}
                                    onUnmatch={() => {
                                        onChange(null);
                                    }}
                                    defaultOptions
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="user.manage.user.expires_at" />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="expires_at"
                            render={({ field: { onChange, value } }) => (
                                <InputDate
                                    selected={value ? new Date(value) : null}
                                    readonly
                                    isClearable
                                    onChange={(date) => {
                                        onChange(
                                            date
                                                ? moment(date).format(
                                                      "YYYY-MM-DD"
                                                  )
                                                : null
                                        );
                                    }}
                                    error={
                                        errors.expires_at && [
                                            I18n.getTranslation(
                                                location,
                                                errors.expires_at.message
                                            ),
                                        ]
                                    }
                                />
                            )}
                        />
                    </Form.Group>
                    <div className="row">
                        <div className="col">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="user.manage.user.api_limit_per_day" />
                                </Form.Label>
                                <InputNumber
                                    {...register("api_limit_per_day")}
                                    error={
                                        errors.api_limit_per_day && [
                                            I18n.getTranslation(
                                                location,
                                                errors.api_limit_per_day.message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                        </div>
                        <div className="col">
                            <Form.Group>
                                <Form.Label>
                                    <I18n t="user.manage.user.api_limit_per_second" />
                                </Form.Label>
                                <InputNumber
                                    {...register("api_limit_per_second")}
                                    error={
                                        errors.api_limit_per_second && [
                                            I18n.getTranslation(
                                                location,
                                                errors.api_limit_per_second
                                                    .message
                                            ),
                                        ]
                                    }
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <Form.Group>
                        <Form.Label>
                            <I18n t={`user.manage.user.image_server`} />
                        </Form.Label>
                        <Controller
                            control={control}
                            name="id_image_server"
                            render={({ field: { onChange, value, ref } }) => (
                                <InputSelect
                                    innerRef={ref}
                                    value={
                                        value !== null
                                            ? {
                                                  value,
                                                  label: serverOptions.find(
                                                      ({ id }) =>
                                                          String(id) ===
                                                          String(value)
                                                  )?.country,
                                              }
                                            : null
                                    }
                                    options={serverOptions.map(
                                        ({ id, country }) => {
                                            return {
                                                value: id,
                                                label: country,
                                            };
                                        }
                                    )}
                                    className="text-left"
                                    onChange={(opt) => {
                                        onChange(opt ? opt.value : null);
                                    }}
                                    isClearable={false}
                                    error={
                                        errors.id_image_server && [
                                            I18n.getTranslation(
                                                location,
                                                errors.id_image_server.message
                                            ),
                                        ]
                                    }
                                    enablePortal
                                />
                            )}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            <I18n t="user.manage.user.token" />
                        </Form.Label>
                        <InputGroup className="mb-3">
                            <InputText
                                {...register("token")}
                                error={
                                    errors.token && [
                                        I18n.getTranslation(
                                            location,
                                            errors.token.message
                                        ),
                                    ]
                                }
                                readOnly
                            />
                            <Button variant="secondary" onClick={generateToken}>
                                <I18n t="utils.button.generate" />
                            </Button>
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    <I18n t="utils.button.close" />
                </Button>
                <Button
                    variant="success"
                    disabled={isSubmitting}
                    onClick={!isSubmitting ? handleSubmit(onSubmit) : null}
                >
                    <I18n t="utils.button.save" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateOrUpdateModal;
