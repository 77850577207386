import { UPLOAD_MAX_SIZE } from "shared/constants/file";

export default {
    dashboard: "메타데이터 모니터",
    logo: "메타데이터 모니터",
    error_boundary: {
        message: "이런! 뭔가 잘못 된 것 같네요.",
        submessage: "문제를 처리하고 있습니다.",
        back: "메인 화면으로 돌아가 주세요.",
    },
    security: {
        login: {
            title: "로그인",
            username: "이메일",
            password: "비밀번호",
            noAccount: "계정이 없으세요?",
            create: "가입하기",
            forgot: "비밀번호를 잊어버리셨습니까?",
        },
        request: {
            title: "요청",
            username: "이메일",
            message:
                "이메일이 발송되었습니다. 암호를 재설정하기 위해 필요한 링크가 포함되어 있습니다. \n\n이메일을 받지 못하셨다면 스팸 폴더를 확인하거나 다시 시도해 주세요.",
            button: "비밀번호 재설정하기",
            login: "로그인으로 돌아가기",
        },
        reset: {
            title: "비밀번호 변경하기",
            password: "새로운 비밀번호",
            success: "새로운 비밀번호가 성공적으로 변경되었습니다",
            error: "비밀번호를 재설정할 수 없습니다",
            button: "비밀번호 변경하기",
            invalid_password: "유효하지 않은 비밀번호",
            login: "로그인으로 돌아가기",
        },
        register: {
            title: "계정 등록",
            subtitle: {
                trial: "저희 데이터를 사용해보시겠어요? 오늘부터 2주간 무료 체험을 시작해보세요.",
                client: "고객으로서 이미 메타데이터 모니터에 액세스할 수 있습니다. 귀하의 계정은 적절한 액세스를 제공하기 위해 확인될 것입니다.",
            },
            is_client: "이미 고객이세요?",
            email: "이메일",
            firstname: "이름",
            lastname: "성",
            password: "비밀번호",
            account_type: {
                title: "계정 유형",
                music_service: "Music service",
                cmo: "CMO",
                label_distributor: "Label / Distributor",
                artist_manager: "Artist / Manager",
            },
            role: {
                title: "역할",
                music_editor: "Music editor",
                marketing: "Marketing",
                catalog_management: "Catalog Management",
                product_management: "Product Management",
                tech: "Tech",
                executive: "Executive",
                other: "Other",
                promotion: "Promotion",
                business_development: "Business development",
                a_and_r: "A&R",
                supply_chain: "Supply chain",
                analytics: "Analytics",
            },
            describe: "묘사하다",
            language: "언어",
            label: "레이블",
            company: "회사",
            success:
                "계정이 성공적으로 생성되었습니다. 등록을 완료하고 메타데이터 모니터에 액세스하려면 이메일 주소를 확인하십시오",
            error: "계정을 생성할 수 없습니다",
            button: "시작하기",
            allreadyAccount: "이미 계정이 있나요?",
            agreeBefore:
                "등록함으로써, Music-Story에 동의하는 것으로 간주됩니다",
            termsOfService: "이용 약관",
            agreeAnd: "그리고",
            privacyPolicy: "개인정보 보호 정책",
            invalid_password: "유효하지 않은 비밀번호",
        },
        access: {
            denied: "유효하지 않은 자격 증명입니다. 이메일로 로그인을 시도하십시오.",
            expired: "만료된 계정",
            lock: "잠긴 계정",
        },
        validate: {
            success:
                "회원 가입이 성공적으로 완료되었습니다. 이제 로그인할 수 있습니다.",
            error: "계정을 확인할 수 없음",
        },
    },
    user: {
        breadcrumb: "사용자",
        manage: {
            breadcrumb: "관리",
            user: {
                head: "사용자",
                empty: "결과 없음",
                id: "아이디",
                username: "이름",
                is_client: "이미 고객이세요?",
                firstname: "이름",
                lastname: "성",
                email: "이메일",
                user_roles: "권한",
                label: "관련 레이블",
                api_limit_per_day: "하루 API 요청 한도",
                api_limit_per_second: "초당 API 요청 제한",
                image_server: "이미지 서버",
                author: "저자",
                account_type: {
                    title: "계정 유형",
                    music_service: "Music service",
                    cmo: "CMO",
                    label_distributor: "Label / Distributor",
                    artist_manager: "Artist / Manager",
                },
                role: {
                    title: "역할",
                    music_editor: "Music editor",
                    marketing: "Marketing",
                    catalog_management: "Catalog Management",
                    product_management: "Product Management",
                    tech: "Tech",
                    executive: "Executive",
                    other: "Other",
                    promotion: "Promotion",
                    business_development: "Business development",
                    a_and_r: "A&R",
                    supply_chain: "Supply chain",
                    analytics: "Analytics",
                },
                describe: "묘사하다",
                language: "언어",
                company: "회사",
                last_login: "마지막 접속",
                expires_at: "만료 날짜",
                token: "토큰",
                expires: "만료됨",
                action: "액션",
                search: "찾아보기...",
            },
            create: {
                title: "사용자 생성",
                success: "사용자가 생성되었습니다",
                error: (message) =>
                    `사용자를 생성하는 동안 오류가 발생했습니다: ${message}`,
            },
            update: {
                title: "사용자 업데이트",
                success: "사용자가 업데이트되었습니다",
                error: (message) =>
                    `사용자를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
            },
            change_password: {
                title: "비밀번호 변경",
                password: "비밀번호",
                comfirm: "비밀번호 확인",
                success: "비밀번호가 변경되었습니다",
                error: (message) =>
                    `비밀번호를 변경하는 동안 오류가 발생했습니다: ${message}`,
            },
            promote: {
                title: "사용자 승격",
                alert: {
                    title: "잠깐만요!",
                    text: "정말 이 사용자를 승격하시겠습니까?",
                },
                success: "사용자가 승격되었습니다",
                error: (message) =>
                    `사용자를 승격하는 동안 오류가 발생했습니다: ${message}`,
            },
            unpromote: {
                title: "사용자 강등",
                alert: {
                    title: "잠깐만요!",
                    text: "정말 이 사용자를 강등하시겠습니까",
                },
                success: "사용자가 강등되었습니다",
                error: (message) =>
                    `사용자를 강등하는 동안 오류가 발생했습니다: ${message}`,
            },
            lock: {
                title: "사용자 잠금",
                alert: {
                    title: "잠깐만요!",
                    text: "정말 이 사용자의 계정을 잠그시겠습니까?",
                },
                success: "사용자 계정이 잠겼습니다",
                error: (message) =>
                    `사용자의 계정을 잠그는 동안 오류가 발생했습니다: ${message}`,
            },
            unlock: {
                title: "사용자 해제",
                alert: {
                    title: "잠깐만요!",
                    text: "정말 이 사용자의 계정을 해제하시겠습니까?",
                },
                success: "사용자 계정이 해제되었습니다",
                error: (message) =>
                    `사용자 계정을 해제하는 동안 오류가 발생했습니다: ${message}`,
            },
            generate_token: {
                success: "토큰이 생성되었습니다",
                error: (message) =>
                    `토큰을 생성하는 동안 오류가 발생했습니다: ${message}`,
            },
        },
        role: {
            breadcrumb: "역할",
            manage: {
                breadcrumb: "관리",
                role: {
                    head: "역할",
                    empty: "결과 없음",
                    id: "아이디",
                    libelle: "레이블",
                    rights: "권한",
                    action: "액션",
                    search: "찾아보기...",
                },
                create: {
                    title: "역할 생성",
                    success: "역할이 생성되었습니다",
                    error: (message) =>
                        `역할을 생성하는 동안 오류가 발생했습니다: ${message}`,
                },
                update: {
                    title: "역할 업데이트",
                    success: "역할이 업데이트되었습니다",
                    error: (message) =>
                        `역할을 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
                delete: {
                    title: "역할 삭제",
                    alert: {
                        title: "잠깐만요!",
                        text: "정말 이 역할을 삭제하시겠습니까?",
                    },
                    success: "역할이 삭제되었습니다",
                    error: (message) =>
                        `역할을 삭제하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
        },
        right: {
            breadcrumb: "권한",
            manage: {
                breadcrumb: "관리",
                right: {
                    head: "권한",
                    empty: "결과 없음",
                    id: "아이디",
                    reference: "참조",
                    libelle: "레이블",
                    action: "액션",
                    search: "찾아보기...",
                },
                create: {
                    title: "권한 생성",
                    success: "권한이 생성되었습니다",
                    error: (message) =>
                        `권한을 생성하는 동안 오류가 발생했습니다: ${message}`,
                },
                update: {
                    title: "권한 업데이트",
                    success: "권한이 업데이트되었습니다",
                    error: (message) =>
                        `권한을 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
                delete: {
                    title: "권한 삭제",
                    alert: {
                        title: "잠깐만요!",
                        text: "정말 이 권한을 삭제하시겠습니까?",
                    },
                    success: "권한이 삭제되었습니다",
                    error: (message) =>
                        `권한을 삭제하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
        },
    },
    author: {
        breadcrumb: "저자",
        manage: {
            breadcrumb: "관리",
            author: {
                head: "저자",
                empty: "결과 없음",
                id: "아이디",
                firstname: "이름",
                lastname: "성",
                signature: "서명",
                action: "액션",
                search: "찾아보기...",
            },
            create: {
                title: "저자 생성",
                success: "저자가 생성되었습니다",
                error: (message) =>
                    `저자를 생성하는 동안 오류가 발생했습니다: ${message}`,
            },
            update: {
                title: "저자 업데이트",
                success: "저자가 업데이트되었습니다",
                error: (message) =>
                    `저자를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
            },
            delete: {
                title: "저자 삭제",
                alert: {
                    title: "잠깐만요!",
                    text: "정말 이 저자를 삭제하시겠습니까?",
                },
                success: "저자가 삭제되었습니다",
                error: (message) =>
                    `저자를 삭제하는 동안 오류가 발생했습니다: ${message}`,
            },
        },
    },
    artist: {
        search: {
            breadcrumb: "아티스트 목록",
            placeholder:
                "아이디 / ISNI / 이름 / 본명 / 번역된 이름 / 가명 / 별명",
            empty: "결과 없음",
            init: "직접 찾아보세요",
            add: "아티스트 추가",
            id: "아이디",
            name: "이름",
            complement: "보조 이름",
            genre: "장르",
            actions: "액션",
            mainGenre: "기본 장르",
            principalGenre: "주요 장르",
            secondaryGenre: "2차 장르",
            country: "국가",
            type: "타입",
            gender: "성별",
            role: "역할",
            reset: "검색 재설정",
            error: (message) =>
                `아티스트를 검색하는 동안 오류가 발생했습니다: ${message}`,
            majorGenreGroup: "기본 장르",
            otherGenreGroup: "기타 장르",
            filters: {
                bio_en: {
                    title: "영어 바이오그래피",
                    yes: "영어 바이오그래피 보기",
                    no: "영어 바이오그래피 보지 않기",
                },
                bio_fr: {
                    title: "프랑스어 바이오그래피",
                    yes: "프랑스어 바이오그래피 보기",
                    no: "프랑스어 바이오그래피 보지 않기",
                },
                bio_de: {
                    title: "독일어 바이오그래피",
                    yes: "독일어 바이오그래피 보기",
                    no: "독일어 바이오그래피 보지 않기",
                },
                bio_pt: {
                    title: "포르투갈어 바이오그래피",
                    yes: "포르투갈어 바이오그래피 보기",
                    no: "포르투갈어 바이오그래피 보지 않기",
                },
                bio_es: {
                    title: "스페인 전기",
                    yes: "스페인어 전기 포함",
                    no: "스페인어 전기 없이",
                },
                curation: {
                    title: "큐레이션",
                    yes: "큐레이션 사진 보기",
                    no: "큐레이션 사진 보지 않기",
                },
                getty: {
                    title: "게티이미지",
                    yes: "게티이미지 보기",
                    no: "게티이미지 보지 않기",
                },
                label: {
                    title: "레이블",
                    yes: "레이블 사진 보기",
                    no: "레이블 사진 보지 않기",
                },
                editorialized: {
                    title: "편집",
                    yes: "편집됨",
                    no: "편집되지 않음",
                },
            },
        },
        view: {
            breadcrumb: "아티스트 시각화",
            id: "뮤직스토리 아이디",
            complementary: "보조 이름",
            type: "타입",
            mainGenre: "기본 장르",
            country: "국가",
            profile: "프로필",
            univers: "유니버스",
            credits: "크레딧",
            charts: "히트곡",
            disco: "디스코그래피",
            images: "이미지",
            biography: "바이오그래피",
            popularity: "인기",
            noData: {
                univers: "이 아티스트가 사용할 수 있는 유니버스가 없습니다",
                images: "이 아티스트가 사용할 수 있는 이미지가 없습니다",
                disco: "이 아티스트가 사용할 수 있는 디스코그래피가 없습니다",
                credits: "이 아티스트가 사용할 수 있는 크레딧이 없습니다",
                charts: "이 아티스트가 사용할 수 있는 차트가 없습니다",
                biography:
                    "이 아티스트가 사용할 수 있는 바이오그래피가 없습니다",
                popularity: "이 아티스트가 사용할 수 있는 인기 항목이 없습니다",
            },
            profileInformation: {
                head: "추가 정보",
                role: "역할",
                gender: "성별",
                Personne: {
                    born: "생일",
                    birth_country: "출생 국가",
                    birth_area: "출생 지역",
                    birth_city: "출생 도시",
                    died: "사망 일자",
                    death_country: "사망 국가",
                    death_area: "사망 지역",
                    death_city: "사망 도시",
                },
                Groupe: {
                    born: "결성 날짜",
                    birth_country: "결성 국가",
                    birth_area: "결성 지역",
                    birth_city: "결성 도시",
                    died: "해체 날짜",
                    death_country: "해체 국가",
                    death_area: "해체 지역",
                    death_city: "해체 도시",
                },
                famousFor: "유명세 분야",
                awards: "수상 어워드",
            },
            profileNames: {
                head: "이름",
                realname: "본명",
                alias: "가명",
                names: "대체 언어",
            },
            profileMembers: {
                head: "멤버",
                current: "현재 멤버",
                old: "과거 멤버",
            },
            profileGroup: {
                head: "밴드",
                current: "현재 밴드",
                old: "과거 밴드",
            },
            profileExternalLink: {
                head: "외부 링크",
                isni: "ISNI",
                site: "웹사이트",
            },
            profilePartnerLink: {
                head: "파트너 링크",
            },
            universGenres: {
                head: "장르",
                main: "기본",
                principal: "주요",
                secondary: "2차",
                influence: "영향",
            },
            universRelated: {
                head: "관련 아티스트",
                related: "관련",
                successor: "후임",
                influence: "영향",
            },
            universLanguages: {
                head: "언어",
                principal: "기본 언어",
                secondary: "기타 언어",
            },
            creditsCoPerformer: {
                head: "협연",
            },
            creditsContribution: {
                head: "기여",
                description:
                    "현재 아티스트가 협업한 아티스트 (다른 아티스트 트랙 피처링, 다른 아티스트를 위한 작곡가, 다른 아티스트를 위한 악기 연주 등)",
            },
            creditsCollaboration: {
                head: "컬래버레이션",
                description:
                    "현재 아티스트와 협업한 아티스트 (작곡가, 주요 피처링 등)",
            },
            chartsRecordingCharts: {
                mainCharts: "공식 차트",
                allCharts: "전체 차트",
                cover: "표지",
                title: "타이틀",
                star: "점수",
                peak_rank: "베스트 랭킹",
                chart: "차트",
                country: "국가",
                entry_date: "진입 날짜",
                trend: "트랜드",
                year: "연도",
                multi_charts: "멀티 차트",
                multi_country: "다국가",
                resetCharts: "필터 / 분류 재설정",
                resetEditorsPick: "분류 재설정",
                hit: {
                    title: "히트곡",
                    description:
                        "아티스트의 가장 인기 있는 곡을 전문가가 엄선하여 제공합니다.",
                },
                editorial_pick: {
                    title: "에디터의 선택",
                    description: "음악 에디터가 엄선한 주목할 만한 곡들",
                },
                empty: "결과 없음",
            },
            discoAlbums: {
                head: "앨범",
                id: "아이디",
                cover: "표지",
                title: "타이틀",
                type: {
                    head: "타입",
                    live: "라이브",
                    remix: "리믹스",
                    compilation: "컴필레이션",
                    karaoke: "가라오케",
                    ring: "원반",
                    other: "기타",
                    original: "오리지널",
                },
                format: {
                    head: "포맷",
                    album: "앨범",
                    ep: "EP",
                    single: "싱글",
                    mixtape: "믹스테이프",
                    mcd: "Maxi",
                },
                year: "연도",
                genre: "장르",
                release_date: "출시 일자",
                reference: "평점",
                has_review: "리뷰",
                has_credits: "크레딧",
                has_chart: "차트",
                complementary: "관련 콘텐츠",
                empty: "결과 없음",
                reset: "필터/분류 재설정",
            },
            imagesGallery: {
                head: "갤러리",
                tags: "태그별 필터",
                empty: "갤러리에 이미지 없음",
                detail: {
                    head: "세부 사항",
                    show: "원본 사진 표시",
                    source: "출처",
                    copyright: "저작권",
                    tags: "태그",
                    metas: "메타",
                },
            },
            biographyGallery: {
                writer: "작가",
                updated_at: "업데이트된 날짜",
                words: "글",
                signs: "서명",
                biography: "바이오그래피",
                empty: "바이오그래피 없음",
                licence: {
                    title: "CD 저널 제공",
                    subtitle:
                        "CD 저널에서 제공하는 특정 라이선스 없이는 이 콘텐츠를 사용할 수 없습니다",
                },
            },
            popularityOverview: {
                head: "개요",
                global: {
                    title: "글로벌",
                    recordings: "차트 타이틀:",
                    description:
                        "아티스트의 인기도 점수는 차트 데이트를 기반으로 합니다. 이는 아티스트의 타이틀 인기도에 따른 결과이며 아티스트의 순위(인기 아티스트 순위 내)와 인기율 (최고 인기도 점수 대비 %)의 두 가지 형태로 표시됩니다.",
                },
                gold: {
                    title: "카탈로그",
                    recordings: "카탈로그 차트 타이틀:",
                    description:
                        "아티스트의 카탈로그 인기도는 18개월 전 차트에서의 순위를 기준으로 합니다. 카탈로그 인기도는 현재 빌보드 핫 100, 영국 공식 싱글 차트 및 오피셜 도이치 차트 데이터만 사용합니다.",
                },
                current: {
                    title: "현재",
                    recordings: "현재 차트 타이틀:",
                    description:
                        "아티스트의 현재 인기도는 지난 18개월 동안 차트에서 그의 활약을 기반으로 합니다.",
                },
                type: {
                    label: "타입:",
                    gold: "카탈로그",
                    current: "현재",
                },
                peak: "피크:",
            },
            popularityHistogram: {
                head: "시간 변화에 따른 인기도",
                tabs: {
                    gold: {
                        title: "카탈로그",
                        description:
                            "아티스트의 카탈로그 인기도는 18개월 전 차트에서의 순위를 기준으로 합니다. 카탈로그 인기도는 현재 빌보드 핫 100, 영국 공식 싱글 차트 및 오피셜 도이치 차트 데이터만 사용합니다.",
                        header: "카탈로그 인기도는 미국, 영국 및 DE 공식 차트 데이터만을 기준으로 하며, 더 많은 국가가 통합되고 있습니다.",
                    },
                    current: {
                        title: "현재",
                        description:
                            "현재 아티스트의 인기도는 지난 18개월 동안 차트에서 그의 활약을 기반으로 합니다.",
                    },
                },
                gold: "카탈로그 인기도",
                current: "현재 인기도",
                popover: {
                    noData: "데이터 없음",
                    popularity: "아티스트 인기도:",
                    mostPopularTitle: "가장 인기있는 타이틀:",
                    bestRank: "베스트 랭킹:",
                    bestCountry: "베스트 국그:",
                    charts: (n) => `차트에 ${n} 기반`,
                },
            },
            popularityMap: {
                head: "국가별 인기도",
                tabs: {
                    gold: {
                        title: "카탈로그",
                        description:
                            "아티스트의 카탈로그 인기도는 18개월 전 차트에서의 순위를 기준으로 합니다. 카탈로그 인기도는 현재 빌보드 핫 100, 영국 공식 싱글 차트 및 오피셜 도이치 차트 데이터만 사용합니다.",
                    },
                    current: {
                        title: "현재",
                        description:
                            "현재 아티스트의 인기도는 지난 18개월 동안 차트에서 그의 활약을 기반으로 합니다.",
                    },
                },
                popover: {
                    noData: "데이터 없음",
                    popularity: "아티스트 인기도:",
                    mostPopularTitle: "가장 인기있는 타이틀:",
                    bestRank: "베스트 랭킹:",
                    bestMonth: "베스트 순위의 달:",
                    weeksInCharts: "차트 진입 주:",
                    charts: (n) => `차트에 ${n} 기반`,
                },
            },
        },
        create: {
            breadcrumb: "생성",
            placeholder:
                "아이디 / ISNI / 이름 / 본명 / 번역된 이름 / 가명 / 별명",
            empty: "결과 없음",
            init: "직접 찾아보세요",
            source: "출처",
            name: "이름",
            complement: "보조 이름",
            type: "타입",
            gender: "성별",
            country: "국가",
            real_name: "본명",
            alias: "가명",
            member: "멤버",
            band: "밴드",
            site: "웹사이트",
            social: "소셜 네트워크",
            actions: "액션",
            create: {
                title: "아티스트 생성",
                alert: {
                    title: "잠깐만요!",
                    text: "정말 이 아티스트를 생성하시겠습니까?",
                },
                success: "아티스트가 생성되었습니다",
                error: (message) =>
                    `아티스트를 생성하는 동안 오류가 발생했습니다: ${message}`,
            },
        },
        management: {
            breadcrumb: "아티스트 수정",
            profile: "프로필",
            editorial: "편집",
            biography: "바이오그래피",
            discography: "디스코그래피",
            curation: "큐레이션",
            getty: "게티이미지",
            labelPicture: "레이블 사진",
            matching: "일치",
            charts: "차트",
            tracking: "추적 기능",
            action: {
                complete: {
                    message: "아티스트 생성 진행 중",
                    button: "Complete the creation",
                    alert: {
                        title: "잠깐만요!",
                        text: "정말 아티스트 생성을 완료하시겠습니까?",
                    },
                    success: "아티스트 생성 완료",
                    error: (message) =>
                        `아티스트를 생성을 완료하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            profileInformation: {
                head: "기본 정보",
                type: "타입",
                name: "이름",
                complement: "보조 이름",
                realname: "본명",
                gender: "성별",
                country: "국가",
                isni: "ISNI",
                isniSearch: {
                    tooltip: "ISNI 검색",
                    head: "ISNI 검색",
                    placeholder: "이름이나 ISNI로 검색",
                    error: (message) =>
                        `검색 도중 오류가 발생했습니다: ${message}`,
                    isni: "ISNI",
                    name: "이름",
                    select: "ISNI 선택",
                    empty: "결과 없음",
                },
                reset: {
                    title: "양식 재설정",
                },
                update: {
                    title: "기본 정보 업데이트",
                    success: "기본 정보가 업데이트 되었습니다",
                    error: (message) =>
                        `이 정보를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            names: {
                head: "대체 언어",
                empty: "언어 없음",
                name: "이름",
                language: "언어",
                reset: {
                    title: "양식 재설정",
                },
                update: {
                    title: "대체 언어 업데이트",
                    success: "대체 언어가 업데이트 되었습니다",
                    error: (message) =>
                        `대체 언어를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            alias: {
                head: "가명",
                empty: "가명 없음",
                alias: "가명",
                type: "타입",
                reset: {
                    title: "양식 재설정",
                },
                update: {
                    title: "가명 업데이트",
                    success: "가명이 업데이트 되었습니다",
                    error: (message) =>
                        `가명을 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            member: {
                head: "멤버",
                empty: "멤버 없음",
                artist: "이름",
                start_date: "합류",
                end_date: "탈퇴",
                role: "역할",
                reset: {
                    title: "양식 재설정",
                },
                update: {
                    title: "멤버 업데이트",
                    success: "멤버가 업데이트되었습니다",
                    error: (message) =>
                        `멤버를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            group: {
                head: "밴드",
                empty: "밴드 없음",
                artist: "이름",
                start_date: "결성",
                end_date: "해체",
                role: "역할",
                reset: {
                    title: "양식 재설정",
                },
                update: {
                    title: "밴드 업데이트",
                    success: "밴드가 업데이트되었습니다",
                    error: (message) =>
                        `밴드를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            genre: {
                head: "장르",
                empty: "장르 없음",
                genre: "이름",
                type: "타입",
                reset: {
                    title: "양식 재설정",
                },
                update: {
                    title: "장르 업데이트",
                    success: "장르가 업데이트되었습니다",
                    error: (message) =>
                        `장르를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            role: {
                head: "역할",
                empty: "역할 없음",
                role: "역할",
                main: "기본",
                reset: {
                    title: "양식 재설정",
                },
                update: {
                    title: "역할 업데이트",
                    success: "역할이 업데이트되었습니다",
                    error: (message) =>
                        `역할을 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            social: {
                head: "소셜 네트워크",
                reset: {
                    title: "양식 재설정",
                },
                update: {
                    title: "소셜 네트워크 업데이트",
                    success: "소셜 네트워크가 업데이트되었습니다",
                    error: (message) =>
                        `소셜 네트워크를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
                duplicates: {
                    tooltip:
                        "동일한 소셜 네트워크 URL을 가진 다른 아티스트가 있습니다",
                    head: "소셜 네트워크 중복",
                    body: "다음 아티스트의 소셜 네트워크 URL이 같습니다:",
                },
            },
            site: {
                head: "웹사이트",
                empty: "웹사이트 없음",
                url: "Url",
                reset: {
                    title: "양식 재설정",
                },
                update: {
                    title: "웹사이트 업데이트",
                    success: "웹사이트가 업데이트되었습니다",
                    error: (message) =>
                        `웹사이트를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            editorialInformation: {
                head: "기본 정보",
                Personne: {
                    born: "생일",
                    birth_country: "출생 국가",
                    birth_area: "출생 지역",
                    birth_city: "출생 도시",
                    died: "사망 일자",
                    death_country: "사망 국가",
                    death_area: "사망 지역",
                    death_city: "사망 도시",
                },
                Groupe: {
                    born: "결성 일자",
                    birth_country: "결성 국가",
                    birth_area: "결성 지역",
                    birth_city: "결성 도시",
                    activity_period: "활동 연도",
                    activity_period_start: "시작 날짜",
                    activity_period_end: "종료 날짜",
                    died: "해체 일자",
                    death_country: "해체 국가",
                    death_area: "해체 지역",
                    death_city: "해체 도시",
                },
                reset: {
                    title: "양식 재설정",
                },
                update: {
                    title: "기본 정보 업데이트",
                    success: "기본 정보가 업데이트되었습니다",
                    error: (message) =>
                        `기본 정보를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            highlights: {
                head: "하이라이트",
                famous_for: "유명세 분야",
                awards: "수상 어워드",
                reset: {
                    title: "양식 재설정",
                },
                update: {
                    title: "하이라이트 업데이트",
                    success: "하이라이트가 업데이트되었습니다",
                    error: (message) =>
                        `하이라이트를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            collaboration: {
                head: "컬래버레이션",
                empty: "컬래버레이션 없음",
                artist: "이름",
                start_date: "시작",
                end_date: "종료",
                role: "역할",
                reset: {
                    title: "양식 재설정",
                },
                update: {
                    title: "컬래버레이션 업데이트",
                    success: "컬래버레이션이 업데이트되었습니다",
                    error: (message) =>
                        `컬래버레이션을 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            contribution: {
                head: "기여",
                empty: "기여 없음",
                artist: "이름",
                start_date: "시작",
                end_date: "종료",
                role: "역할",
                reset: {
                    title: "양식 재설정",
                },
                update: {
                    title: "기여 업데이트",
                    success: "기여가 업데이트되었습니다",
                    error: (message) =>
                        `기여를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            related: {
                head: "관련 아티스트",
                empty: "관련 아티스트 없음",
                artist: "이름",
                type: "타입",
                reset: {
                    title: "양식 재설정",
                },
                update: {
                    title: "관련 아티스트 업데이트",
                    success: "관련 아티스트가 업데이트되었습니다",
                    error: (message) =>
                        `관련 아티스트를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            bio: {
                head: {
                    FR: "Music Story FR",
                    EN: "Music Story EN",
                    DE: "Music Story DE",
                    PT: "Music Story PT",
                    JA: "Music Story JA",
                    ES: "Music Story ES",
                },
                is_reference: "참조 전기",
                to_translate: () => (
                    <>
                        진행 중인 프로세스.
                        <br />이 전기는 해당 참조 전기에서 자동으로 번역되고
                        업데이트됩니다. 별도의 조치가 필요하지 않습니다.
                    </>
                ),
                author: "작가",
                archive: "아카이브",
                cd_journal: "CD 저널",
                reset: {
                    title: "바이오그래피 재설정",
                },
                update: {
                    title: "바이오그래피 업데이트",
                    success: "바이오그래피가 업데이트되었습니다",
                    alert: {
                        title: "확실합니까 ?",
                        text: "번역이 완료된 전기를 수정하려고 하는데 수정 후에는 더 이상 번역되지 않습니다.",
                    },
                    error: (message) =>
                        `바이오그래피를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
                translation: {
                    placeholder: "언어를 선택해주세요",
                    alert: {
                        title: "확실합니까 ?",
                        text: "이 전기를 교체하시겠습니까 ?",
                    },
                },
            },
            wikipedia: {
                head: "위키피디아",
                empty: "위키피디아 없음",
                url: "Url",
                reset: {
                    title: "양식 재설정",
                },
                update: {
                    title: "위키피디아 업데이트",
                    success: "위키피디아가 업데이트되었습니다",
                    error: (message) =>
                        `위키피디아를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
                names: {
                    head: '"대체 언어"에 위키피디아 이름 추가',
                    lang: "언어",
                    name: "이름",
                },
            },
            disco: {
                albums: {
                    head: "앨범",
                    search: "아이디 / 타이틀",
                    empty: "결과 없음",
                    id: "아이디",
                    title: "타이틀",
                    type: {
                        head: "타입",
                        live: "라이브",
                        remix: "리믹스",
                        compilation: "컴필레이션",
                        karaoke: "가라오케",
                        ring: "원반",
                        other: "기타",
                        original: "오리지널",
                    },
                    format: {
                        head: "형식.",
                        title: "형식",
                        album: "앨범",
                        ep: "EP",
                        single: "싱글",
                        mixtape: "믹스테이프",
                        mcd: "Maxi",
                    },
                    genre: "장르",
                    release_date: {
                        head: "일자",
                        title: "출시 일자",
                    },
                    cover: {
                        head: "커버.",
                        title: "커버 아트",
                    },
                },
                releases: {
                    head: "출시",
                    search: "아이디 / 바코드",
                    empty: "결과 없음",
                    id: "아이디",
                    barcode: {
                        head: "바코드",
                        title: "바코드",
                    },
                    commentary: "코멘터리",
                    support: "지원",
                    release_date: {
                        head: "일자",
                        title: "출시 일자",
                    },
                },
                sandbox: {
                    head: "샌드박스",
                    search: {
                        input: "아이디 / 타이틀 / 바코드",
                        title: "모든 디스코그래피에서 검색",
                        result: (query) => `"${query}" 검색 결과`,
                        reset: "검색 재설정",
                    },
                    empty: "결과 없음",
                    partner: "파트너",
                    id: "아이디",
                    barcode: "바코드",
                    title: "타이틀",
                    nb_track: "트랙 수",
                    actions: "액션",
                    view_product: "제품 보기",
                    compare_tracklists: "트랙리스트 비교",
                    view_matchings: "일치 보기",
                    popover: {
                        id: "아이디:",
                        title: "타이틀:",
                        artist: "아티스트:",
                        barcode: "바코드:",
                        label: "레이블:",
                        release_date: "출시 일자:",
                        cover_art_error: "커버 아트 로드에 실패했습니다",
                    },
                    matchings: {
                        head: "일치",
                        body: "이 제품은 다음 디스코그래피와 매칭됩니다:",
                        artists: "아티스트",
                        album: "앨범",
                        release: "출시",
                        close: "종료",
                    },
                    tracklistComparison: {
                        head: "트랙리스트 비교",
                        tracks: {
                            head: "뮤직스토리 트랙리스트",
                            empty: "결과 없음",
                            no_cd: "D",
                            no_piste: "T",
                            isrc: "Isrc",
                            title: "타이틀",
                            subtitle: "부제",
                            duration: "시간.",
                            hit: "히트",
                            discovery: {
                                head: "EP",
                                title: "에디터의 선택",
                            },
                        },
                        partnerTracks: {
                            head: "파트너 트랙리스트",
                            empty: "결과 없음",
                            no_cd: "D",
                            no_piste: "T",
                            isrc: "Isrc",
                            title: "타이틀",
                            duration: "시간.",
                        },
                        replace: {
                            title: "뮤직스토리 트랙리스트 교체",
                            alert: {
                                title: "잠깐만요!",
                                text: "정말로 이 발매 앨범의 트랙리스트를 교체하시겠습니까?",
                            },
                            success: "트랙리스트가 교체되었습니다",
                            error: (message) =>
                                `트랙리스트를 교체하는 동안 오류가 발생했습니다: ${message}`,
                            warningIsrc: {
                                title: "잠깐만요!",
                                text: "이 발매 앨범의 트랙시스트를 교체하려는 시도로 인해 다음과 같은 경고가 발생했습니다. 동일한 ISRC를 사용하는 음반이 발견되었습니다",
                            },
                        },
                    },
                    copyProduct: {
                        title: "제품 추가",
                        head: "제품 추가",
                        copyAsAlbum: {
                            title: "새 앨범으로 추가",
                            created: "제품이 새 앨범으로 추가되었습니다",
                            error: (message) =>
                                `제품을 앨범으로 추가하는 동안 오류가 발생했습니다: ${message}`,
                            warningBarcode: {
                                title: "잠깐만요!",
                                text: (message) =>
                                    `이 앨범을 추가하려는 시도로 인해 다음과 같은 경고가 발생했습니다: ${message}`,
                            },
                            warningIsrc: {
                                title: "잠깐만요!",
                                text: "이 앨범을 추가하려는 시도로 인해 다음과 같은 경고가 발생했습니다: 동일한 ISRC를 사용하는 음반이 발견되었습니다",
                            },
                        },
                        copyAsRelease: {
                            title: "현재 앨범에 새롭게 발매된 타이틀로 추가",
                            created:
                                "이 제품은 새롭게 발매된 타이틀로 추가되었습니다",
                            matched:
                                "이 제품은 기존에 발매된 타이틀과 일치되었습니다",
                            error: (message) =>
                                `새롭게 발매된 타이틀로 추가 혹은 일치하는 동안 오류가 발생했습니다: ${message}`,
                            warningBarcode: {
                                title: "잠깐만요!",
                                text: (message) =>
                                    `이 발매곡를 추가하려는 시도로 인해 다음과 같은 경고가 발생했습니다: ${message}`,
                            },
                            warningIsrc: {
                                title: "잠깐만요!",
                                text: "이 발매곡을 추가하려는 시도로 인해 다음과 같은 경고가 발생했습니다: 동일한 ISRC를 사용하는 음반이 발견되었습니다",
                            },
                        },
                    },
                    pagination: ({ from, to, size }) => `${size} products`,
                    filters: {
                        partners: "파트너",
                        status: "상태",
                    },
                    status: {
                        MATCHED_IN_CURRENT_DISCOGRAPHY:
                            "현재 디스코그래피에서 일치함",
                        MATCHED_IN_OTHER_DISCOGRAPHY:
                            "다른 디스코그래피에서 일치함",
                        NOT_MATCHED_COMPLETE: "일치하지 않음, 완료",
                        NOT_MATCHED_INCOMPLETE: "일치하지 않음, 미완료",
                    },
                },
                tracks: {
                    head: "트랙",
                    search: "아이디 / Isrc / 타이틀",
                    empty: "결과 없음",
                    no_cd: {
                        head: "D",
                        title: "디스크 번호",
                    },
                    no_piste: {
                        head: "T",
                        title: "트랙 번호",
                    },
                    id: "아이디",
                    isrc: "Isrc",
                    title: "타이틀",
                    subtitle: {
                        head: "부제",
                        title: "부제",
                    },
                    duration: {
                        head: "시간.",
                        title: "시간",
                    },
                },
                matching: {
                    partner: "제휴",
                    id: "아이디",
                    actions: "액션",
                    view: "트랙 보기",
                    delete: "삭제",
                },
                objects: {
                    album: {
                        tabTitle: "앨범",
                        basic: {
                            head: "기본 데이터",
                            artist: "아티스트",
                            title: "타이틀",
                            subtitle: "자막",
                            type: "타입",
                            format: "포맷",
                            label: "레이블",
                            publisher: "발행자",
                            release_date: "발매 일자",
                            genre: "장르",
                            reference: "등급",
                            typeOptions: {
                                live: "라이브",
                                remix: "리믹스",
                                compilation: "컴필레이션",
                                karaoke: "가라오케",
                                ring: "원반",
                                other: "기타",
                                original: "오리지널",
                            },
                            formatOptions: {
                                album: "앨범",
                                ep: "EP",
                                single: "싱글",
                                mixtape: "믹스테이프",
                                mcd: "Maxi",
                            },
                            reset: {
                                title: "포맷 재설정",
                            },
                            create: {
                                title: "앨범 생성",
                                success: "앨범이 생성되었습니다",
                                error: (message) =>
                                    `앨범을 생성하는 동안 오류가 발생했습니다: ${message}`,
                            },
                            update: {
                                title: "앨범 업데이트",
                                success: "앨범이 업데이트되었습니다",
                                error: (message) =>
                                    `앨범을 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                            },
                            delete: {
                                title: "앨범 삭제",
                                hasReleases:
                                    "앨범에 하나 이상의 에디션이 있습니다",
                                hasReview: "앨범에 리뷰가 있습니다",
                                alert: {
                                    title: "잠깐만요!",
                                    text: "정말 이 앨범을 삭제하시겠습니까?",
                                },
                                warning: {
                                    title: "잠깐만요!",
                                    text: (messages) =>
                                        `앨범을 삭제하는 동안 경고 발생: \n\n ${messages.map(
                                            (message) => {
                                                return `- ${message} \n`;
                                            }
                                        )}`,
                                },
                                success: "앨범이 삭제되었습니다",
                                error: (message) =>
                                    `앨범을 삭제하는 동안 오류가 발생했습니다: ${message}`,
                            },
                            transfer: {
                                title: "앨범 이전",
                                success: ({ artist, album }) =>
                                    `앨범을 "${album}" 아티스트에게 "${artist}" 성공적으로 이전`,
                                error: (message) =>
                                    `앨범을 이전하는 동안 오류가 발생했습니다 ${message}`,
                            },
                        },
                        artist: {
                            head: "아티스트",
                            main: "기본",
                            principal: "주요",
                            reset: {
                                title: "형식 재설정",
                            },
                            update: {
                                title: "아티스트 업데이트",
                                success: "아티스트가 업데이트되었습니다",
                                error: (message) =>
                                    `아티스트를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                            },
                        },
                        cover: {
                            head: "커버",
                            musicstory: "뮤직스토리",
                            partner: "파트너",
                            url: "커버 url",
                            ean: "바코드",
                            create: {
                                title: "커버 생성",
                                success: "커버가 생성되었습니다",
                                error: (message) =>
                                    `커버를 생성하는 동안 오류가 발생했습니다: ${message}`,
                            },
                            update: {
                                title: "커버 업데이트",
                                success: "커버가 업데이트되었습니다",
                                error: (message) =>
                                    `커버를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                            },
                            automatic: {
                                success: "표지 사진이 자동으로 추가되었습니다.",
                                error: (message) =>
                                    `표지 아트 자동 추가 중 오류 발생 : ${message}`,
                            },
                        },
                        review: {
                            head: "리뷰",
                            author: "에디터",
                            reset: {
                                title: "리뷰 재설정",
                            },
                            update: {
                                title: "리뷰 업데이트",
                                success: "리뷰가 업데이트되었습니다",
                                error: (message) =>
                                    `리뷰를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                            },
                        },
                        translations: {
                            head: "번역",
                            language: "언어",
                            title: "타이틀",
                            subtitle: "자막",
                            languagePlaceholder: "언어 추가",
                            reset: {
                                title: "형식 재설정",
                            },
                            update: {
                                title: "번역 업데이트",
                                success: "번역이 업데이트되었습니다",
                                error: (message) =>
                                    `번역을 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                            },
                        },
                    },
                    release: {
                        tabTitle: "발매곡",
                        basic: {
                            head: "기본 데이터",
                            barcode: "바코드",
                            release_date: "발매 일자",
                            editor: "에디터",
                            support: "지원",
                            commentary: "코멘터리",
                            reference: "레퍼런스",
                            reset: {
                                title: "양식 재설정",
                            },
                            create: {
                                title: "발매곡 생성",
                                success: "발매곡이 생성되었습니다",
                                error: (message) =>
                                    `발매곡을 생성하는 동안 오류가 발생했습니다: ${message}`,
                                warning: {
                                    title: "잠깐만요!",
                                    text: (message) =>
                                        `발매곡을 생성하려는 시도로 인해 다음과 같은 경고가 발생했습니다: ${message}`,
                                },
                            },
                            update: {
                                title: "발매곡 업데이트",
                                success: "발매곡이 업데이트되었습니다",
                                error: (message) =>
                                    `발매곡을 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                            },
                            delete: {
                                title: "발매곡 삭제",
                                alert: {
                                    title: "잠깐만요!",
                                    text: "정말 이 발매곡을 삭제하시겠습니까?",
                                },
                                warning: {
                                    title: "잠깐만요!",
                                    text: (messages) =>
                                        `이 발매곡을 삭제하려는 시도로 인해 다음과 같은 경고가 발생했습니다: \n\n ${messages.map(
                                            (message) => {
                                                return `- ${message} \n`;
                                            }
                                        )}`,
                                },
                                success: "발매곡이 삭제되었습니다",
                                error: (message) =>
                                    `발매곡을 삭제하는 동안 오류가 발생했습니다: ${message}`,
                            },
                            transfer: {
                                title: "발매곡 이전",
                                artist: "아티스트",
                                album: "앨범",
                                success: ({ artist, album, releaseId }) =>
                                    `발매곡을 "${releaseId}" 앨범 "${album}" 아티스트 "${artist}"에게 성공적으로 이전`,
                                error: (message) =>
                                    `이 앨범을 이전하는 동안 오류가 발생했습니다: ${message}`,
                                same_album_error:
                                    "이 앨범은 현재 발매된 곡에 연결된 앨범이 아니어야 합니다.",
                            },
                        },
                        matching: {
                            head: "파트너",
                            empty: "결과 없음",
                            delete: {
                                title: "일치 항목 삭제",
                                alert: {
                                    title: "잠깐만요!",
                                    text: "정말 일치 항목을 삭제하시겠습니까?",
                                },
                                success: "일치 항목이 삭제되었습니다",
                                error: (message) =>
                                    `일치 항목을 삭제하는 동안 오류가 발생했습니다: ${message}`,
                            },
                        },
                        tracklist: {
                            head: "트랙리스트",
                            tracks: {
                                empty: "결과 없음",
                                no_cd: "D",
                                no_piste: "T",
                                isrc: "Isrc",
                                title: "타이틀",
                                subtitle: "부제",
                                duration: "시간.",
                                hit: "히트",
                                genre: "장르",
                                discovery: {
                                    head: "EP",
                                    title: "에디터의 선택",
                                },
                                validation: {
                                    no_cd: "디스크 번호는 정수여야 합니다.",
                                    no_piste: "트랙 번호는 정수여야 합니다",
                                    title: "타이틀은 비워 둘 수 없습니다",
                                    duration:
                                        "곡 재생시간은 다음의 포맷을 취합니다 HH:MM:SS",
                                    duplicatePosition:
                                        "디스크와 트랙 번호가 같은 다른 트랙이 있습니다",
                                    duplicateIsrc:
                                        "ISRC가 같은 다른 트랙이 있습니다",
                                },
                            },
                            reset: {
                                title: "트랙 재설정",
                            },
                            update: {
                                title: "트랙 업데이트",
                                success: "트랙이 수정되었습니다",
                                error: (message) =>
                                    `트랙을 수정하는 동안 오류가 발생했습니다: ${message}`,
                                warningIsrc: {
                                    title: "잠깐만요!",
                                    text: "트랙리스트를 업데이트하려는 시도로 인해 다음과 같은 경고가 발생했습니다: 동일한 ISRC의 음반이 발견되었습니다",
                                },
                            },
                        },
                    },
                    track: {
                        tabTitle: "트랙",
                        basic: {
                            head: "기본 데이터",
                            title: "타이틀",
                            subtitle: "부제",
                            isrc: "ISRC",
                            duration: "재생 시간",
                            no_cd: "번호. CD",
                            no_piste: "번호. 트랙",
                            hit: "히트",
                            discovery: "에디터의 선택",
                            language: "노래 언어",
                            dialect: "방언",
                            reset: {
                                title: "형식 재설정",
                            },
                            create: {
                                title: "트랙 추가",
                                success: "트랙이 추가되었습니다",
                                error: (message) =>
                                    `트랙을 추가하는 동안 오류가 발생했습니다: ${message}`,
                                warningIsrc: {
                                    title: "잠깐만요!",
                                    text: "트랙을 추가하려는 시도로 인해 다음과 같은 경고가 발생했습니다: 동일안 ISRC의 음반이 발견되었습니다",
                                },
                            },
                            update: {
                                title: "트랙 업데이트",
                                success: "트랙이 업데이트되었습니다",
                                error: (message) =>
                                    `트랙을 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                                warningIsrc: {
                                    title: "잠깐만요!",
                                    text: "트랙을 업데이트하려는 시도로 인해 다음과 같은 경고가 발생했습니다: 동일안 ISRC의 음반이 발견되었습니다",
                                },
                            },
                            delete: {
                                title: "트랙 삭제",
                                alert: {
                                    title: "잠깐만요!",
                                    text: "정말 트렉을 삭제하시겠습니까?",
                                },
                                success: "트랙이 삭제되었습니다",
                                error: (message) =>
                                    `트랙을 삭제하는 동안 오류가 발생했습니다: ${message}`,
                            },
                        },
                        translations: {
                            head: "번역",
                            language: "언어",
                            title: "타이틀",
                            subtitle: "부제",
                            languagePlaceholder: "언어 추가",
                            reset: {
                                title: "형식 재설정",
                            },
                            update: {
                                title: "번역 업데이트",
                                success: "번역이 업데이트되었습니다",
                                error: (message) =>
                                    `번역을 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                            },
                        },
                        matching: {
                            head: "파트너",
                            empty: "결과 없음",
                            delete: {
                                title: "일치 항목 삭제",
                                alert: {
                                    title: "잠깐만요!",
                                    text: "정말 일치 항목을 삭제하시겠습니까?",
                                },
                                success: "일치 항목이 삭제되었습니다",
                                error: (message) =>
                                    `일치항목을 삭제하는 동안 오류가 발생했습니다: ${message}`,
                            },
                        },
                    },
                },
            },
            match: {
                empty: "일치 항목 없음",
                id: "아이디",
                name: "이름",
                type_match: "타입",
                status: "상태",
                pending: "일치 진행 중...",
                success: "일치 완료",
            },
            imageCuration: {
                head: "갤러리",
                empty: "갤러리에 이미지 없음",
                width: "너비",
                height: "높이",
                created_at: "생성 일자",
                copyright: "저작권",
                status: "상태",
                edit: "편집",
                edit_image: "이미지 정보 수정",
                delete: "삭제",
                delete_image: "이미지 삭제",
            },
            imageGetty: {
                gallery: {
                    head: "갤러리",
                    empty: "갤러리에 이미지 없음",
                    width: "너비",
                    height: "높이",
                    created_at: "생성 일자",
                    copyright: "저작권",
                    status: "상태",
                    edit: "편집",
                    edit_image: "이미지 정보 수정",
                    delete: "삭제",
                    delete_image: "이미지 삭제",
                },
            },
            imageLabel: {
                head: "갤러리",
                empty: "갤러리에 이미지 없음",
                width: "너비",
                height: "높이",
                created_at: "생성 일자",
                copyright: "저작권",
                status: "상태",
            },
            chartsList: {
                head: "차트",
                empty: "결과 없음",
                source: "출처",
                area: "지역",
                format: "포맷",
                country: "국가",
                origin: "출신",
                date_out: "진입 날짜",
                track_title: "타이틀",
                title: "차트 이름",
                country: "국가",
                date_in: "진입 날짜",
                date_out: "진출 날짜",
                nb_week: "주 수",
                rank_in: "랭킹 진입",
                max_rank: "최고 랭킹",
                rank_out: "랭킹 진출",
            },
            trackingPlanning: {
                head: "히스토리 기획",
                empty: "결과 없음",
                date: "일자",
                title: "타이틀",
                album: "앨범",
                category: "카테고리",
                reason: "이유",
                classification: "분류",
                tasks: "과제",
                status: {
                    TODO: "할 것",
                    COMPLETED: "완료됨",
                    TO_BE_CORRECTED: "수정 예정",
                    VALIDATED_WITHOUT_CONTROL: "제한 없이 검증됨",
                    VERIFIED_AND_VALIDATED: "인증 및 검증됨",
                    ERROR: "오류",
                },
            },
            trackingPicture: {
                head: "사진 작업 히스토리",
                empty: "결과 없음",
                picture: "사진",
                date: "일자",
                username: "사용자",
                action: "액션",
                curation_cms_artist_image_add_completed:
                    "큐레이션 사진이 추가됨",
                curation_cms_artist_image_update_completed:
                    "큐레이션 사진이 업데이트됨",
                curation_cms_artist_image_main_completed:
                    "큐레이션 사진이 기본 사진으로 설정됨",
                curation_cms_artist_image_delete_completed:
                    "큐레이션 사진이 삭제됨",
                label_cms_artist_picture_status_change_completed:
                    "레이블 사진 상태가 변경됨",
                getty_cms_artist_picture_add_completed: "게티이미지가 추가됨",
                label_cms_artist_picture_add_completed: "레이블 사진이 추가됨",
                getty_cms_artist_picture_delete_completed:
                    "게티이미지가 삭제됨",
                label_cms_artist_picture_delete_completed:
                    "레이블 사진이 삭제됨",
                getty_cms_artist_picture_check_completed: "사진 확인 완료",
                getty_move_picture_completed: "게티이미지 이동",
                label_move_picture_completed: "레이블 사진 이동",
            },
            trackingLog: {
                head: "로그",
                empty: "결과 없음",
                date: "일자",
                username: "사용자",
                action: "액션",
                cms_artist_alias_create_completed: "가명 생성됨",
                cms_artist_alias_update_completed: "가명 업데이트됨",
                cms_artist_alias_delete_completed: "가명 삭제됨",
                cms_artist_biography_create_completed: "바이오그래피 생성됨",
                cms_artist_biography_update_completed:
                    "바이오그래피 업데이트됨",
                cms_artist_biography_translate_completed: "바이오그래피 번역됨",
                cms_artist_collaboration_create_completed:
                    "컬래버레이션 생성됨",
                cms_artist_collaboration_delete_completed:
                    "컬래버레이션 삭제됨",
                cms_artist_collaboration_update_completed:
                    "컬래버레이션 업데이트됨",
                cms_artist_contribution_create_completed: "기여 생성됨",
                cms_artist_contribution_delete_completed: "기여 삭제됨",
                cms_artist_contribution_update_completed: "기여 업데이트됨",
                cms_artist_create_completed: "아티스트 생성됨",
                cms_artist_update_completed: "아티스트 업데이트됨",
                cms_artist_delete_completed: "아티스트 삭제됨",
                cms_artist_genre_create_completed: "장르 생성됨",
                cms_artist_genre_delete_completed: "장르 삭제됨",
                cms_artist_genre_update_completed: "장르 업데이트됨",
                cms_artist_group_create_completed: "그룹 생성됨",
                cms_artist_group_delete_completed: "그룹 삭제됨",
                cms_artist_group_update_completed: "그룹 업데이트됨",
                cms_artist_member_create_completed: "멤버 생성됨",
                cms_artist_member_delete_completed: "멤버 삭제됨",
                cms_artist_member_update_completed: "멤버 업데이트됨",
                cms_artist_name_create_completed: "이름 생성됨",
                cms_artist_name_update_completed: "이름 업데이트됨",
                cms_artist_name_delete_completed: "이름 삭제됨",
                cms_artist_related_create_completed: "관련 아티스트 생성됨",
                cms_artist_related_delete_completed: "관련 아티스트 삭제됨",
                cms_artist_related_update_completed: "관련 아티스트 업데이트됨",
                cms_artist_role_update_completed: "역할 업데이트됨",
                cms_artist_site_create_completed: "사이트 생성됨",
                cms_artist_site_delete_completed: "사이트 삭제됨",
                cms_artist_site_update_completed: "사이트 업데이트됨",
                cms_artist_social_create_completed: "소셜 미디어 생성됨",
                cms_artist_social_delete_completed: "소셜 미디어 삭제됨",
                cms_artist_social_update_completed: "소셜 미디어 업데이트됨",
                cms_artist_wikipedia_create_completed: "위키피디아 생성됨",
                cms_artist_wikipedia_update_completed: "위키피디아 업데이트됨",
            },
        },
        delete: {
            breadcrumb: "아티스트 삭제",
            empty: "비어있음",
            object: "대상",
            nb: "유효한",
            url: "링크",
            album: "앨범",
            alias: "가명",
            bio: "뮤직스토리",
            collab: "컬래버레이션과 기여",
            member_group: "멤버와 그룹",
            picture_curation: "사진 큐레이션",
            picture_right: "저작권 보유 사진",
            plannings: "기획",
            related1: "제휴",
            related2: "제휴된",
            site: "웹사이트",
            social: "소셜 미디어",
            wiki: "위키피디아",
            delete: {
                title: "아티스트 삭제",
                alert: {
                    title: "잠깐만요!",
                    text: "주의하세요, 중요한 대상이 언제나 이 아티스트와 관련되어 있습니다. 삭제는 취소할 수 없습니다. 계속하시겠습니까?",
                },
                success: "아티스트가 성공적으로 삭제되었습니다",
                error: (message) =>
                    `아티스트를 삭제하는 동안 오류가 발생했습니다: ${message}`,
            },
        },
    },
    album: {
        view: {
            breadcrumb: "앨범 시각화",
            id: "뮤직스토리 아이디",
            title: "타이틀",
            subtitle: "부제",
            genre: "장르",
            profile: "레코딩 탐색기",
            univers: "앨범",
            cover: "커버",
            credits: "크레딧",
            review: "리뷰",
            charts: "차트",
            similar: "비슷한 앨범",
            noData: {
                profile: "이 앨범에 사용할 수 있는 트랙리스트가 없습니다",
                credits: "이 앨범에 사용할 수 있는 크레딧이 없습니다",
            },
            profileInformation: {
                head: "상세 정보",
                type: {
                    head: "타입",
                    live: "라이브",
                    remix: "리믹스",
                    compilation: "컴필레이션",
                    karaoke: "가라오케",
                    ring: "원반",
                    other: "기타",
                    original: "오리지널",
                },
                format: {
                    head: "포맷",
                    album: "앨범",
                    ep: "EP",
                    single: "싱글",
                    mixtape: "믹스테이프",
                    mcd: "Maxi",
                },
                label: "레이블",
                release_date: "오리지널 발매 일자",
                performers: "공연자",
                featurings: "피처링",
                translations: "번역",
                reference: "평점",
            },
            profileReleases: {
                head: "발매곡",
                subtitle: "릴리스를 클릭하면 관련된 ISRC가 표시됩니다",
                description:
                    "앨범의 발매곡 리스트. 발매곡을 클릭하면 관련 ISRC가 표시됩니다. 선택한 릴리즈는 파란색으로 강조 표시되고 관련 ISRC는 노란색으로 강조 표시됩니다.",
                ean: "바코드",
                id: "아이디",
                reference: "레퍼런스",
                nb_cd: "CD 개수",
                nb_track: "트랙 개수",
                release_date: "발매 일자",
                commentary: "코멘터리",
                support: {
                    head: "지원",
                    ep: "EP",
                    num: "디지털",
                    dvd: "DVD",
                    lp: "LP",
                    "2cd": "2CD",
                    k7: "K7",
                    cd: "CD",
                },
                cline: "CLINE",
                pline: "PLINE",
                links: "링크",
                empty: "결과 없음",
            },
            profileIsrcs: {
                head: "ISRCs",
                subtitle: "ISRC를 클릭하면 관련 릴리스가 표시됩니다",
                description:
                    "앨범의 레코딩 리스트. ISRC가 속한 발매곡을 표시하려면 ISRC를 클릭하세요. 선택한 ISRC는 파란색으로 강조 표시되고 관련 릴리즈는 노란색으로 강조 표시됩니다.",
                isrc: "ISRC",
                id: "아이디",
                title: "타이틀",
                duration: "재생 시간",
                complementary: "관련 콘텐츠",
                has_credits: "크레딧",
                has_lyric: "가사",
                has_audio: "음성 해설",
                links: "링크",
                empty: "결과 없음",
            },
            profileTracklist: {
                head: "트랙리스트",
                release_head: (id) =>
                    `선택한 "${id}" 발매에 해당하는 트랙리스트`,
                isrc_head: (isrc) =>
                    `선택한 "${isrc}" ISRC에 해당하는 트랙 분류`,
                id_release: "발매곡 아이디",
                ean: "바코드",
                id_track: "트랙 아이디",
                no_cd: "디스크 번호",
                no_piste: "트랙 번호",
                id_recording: "레코딩 아이디",
                title: "타이틀",
                subtitle: "부제",
                isrc: "ISRC",
                duration: "재생 시간",
                hit: "히트 점수",
                editorial_pick: "편집자 추천 점수",
                empty: "결과 없음",
            },
            universGenres: {
                head: "장르",
                principal: "주요",
                secondary: "이차",
            },
            universLanguages: {
                head: "언어",
                principal: "기본 언어",
                secondary: "기타 언어",
            },
            universInstruments: {
                head: "악기",
            },
            universAudioDescription: {
                head: "음성 해설",
                licence: "Simbals 기술로 계산한 디스크립터",
                moods: {
                    title: "앨범의 무드",
                    subtitle: "중요도 순서로",
                    description: () => (
                        <>
                            레코딩의 자세한 분위기와 그에 상응하는 무게. <br />
                            <strong>전체 리스트:</strong> 행복한, 슬픈, 차분한,
                            활동적인, 신이 난, 놀라운/흥분한,
                            긴장된/불안한/두려운, 화난, 괴로운, 매우 기쁜,
                            확신하는, 짜증난, 실망한, 불만스러운, 기쁜, 관심
                            있는, 중립적인, 불신하는, 비참한/실망한/깜짝 놀란,
                            평온한/만족하는, 자신감 있는, 걱정하는, 불편한,
                            슬픈/우울한/음울한, 편안한/만족하는, 사색하는,
                            피곤한/졸린, 처진, 지루한/불안한
                        </>
                    ),
                },
                themes: {
                    title: "이 앨범에는 다음 테마의 트랙이 포함되어 있습니다",
                    description:
                        "리스닝 컨텍스트와 관련된 레코딩의 세부 테마는 다음을 포함합니다. 예를 들어, '운동', '식사', '크리스마스', ‘파티’, ‘비디오 게임’, '여름’, 기타…",
                },
                timbres: {
                    title: "다음 음색을 포함하는 타이틀 포함",
                    description: "레코딩의 음색에 상응하는 태그.",
                },
                arousal: {
                    title: "각성도",
                    description: () => (
                        <>
                            각성도는 레코딩의 강도, 에너지 및 활동과 관련된
                            지각적 측정을 나타냅니다.
                            <ul>
                                <li>
                                    <strong>높은 값은</strong> 일반적으로 높은
                                    에너지 트랙 (하드록, 메탈)에 해당합니다
                                </li>
                                <li>
                                    <strong>낮은 값은</strong> 잔잔한 트랙
                                    (느린, 발라드)에게 주어집니다.
                                </li>
                            </ul>
                        </>
                    ),
                },
                valence: {
                    title: "정서가",
                    description: () => (
                        <>
                            정서가는 긍정적 또는 부정적 감정, 예를 들어
                            기쁨/슬픔과 관련된 기분의 지각된 척도를 나타냅니다.
                            <ul>
                                <li>
                                    <strong>높은 값은 </strong> 긍정적인
                                    분위기의 트랙에 해당합니다.
                                </li>
                                <li>
                                    <strong>낮은 값은 </strong> 슬픈 트랙을
                                    가리킵니다.
                                </li>
                            </ul>
                        </>
                    ),
                },
                vocal_instrumental: {
                    title: "기악성",
                    description: () => (
                        <>
                            이 서술어는 트랙이 보컬을 포함하고 있는지 아닌지
                            나타냅니다.
                            <ul>
                                <li>
                                    <strong>높은 값은</strong> 트랙이 보컬을
                                    포함하지 않을 가능성을 나타냅니다. (기악곡)
                                </li>
                                <li>
                                    <strong>낮은 값은</strong> 트랙이 보컬을
                                    포함할 가능성을 나타냅니다. (노래 가사, 랩,
                                    슬램이나 스피치를 예로 들 수 있습니다.
                                </li>
                            </ul>
                        </>
                    ),
                },
                music_speech: {
                    title: "언어 능력",
                    description: () => (
                        <>
                            이 서술어는 트랙이 언어를 포함하고 있는지 아닌지
                            나타냅니다.
                            <ul>
                                <li>
                                    <strong>높은 값은</strong> 음성 언어의
                                    존재와 관련이 있습니다.(인터뷰, 오디오북,
                                    시, 코멘터리).
                                </li>
                                <li>
                                    <strong>낮은 값은</strong>
                                    보컬은 있으나 음성 언어는 없는 음악에 (노래
                                    트랙), 해당합니다.
                                </li>
                            </ul>
                        </>
                    ),
                },
                electric_acoustic: {
                    title: "반주 구간",
                    description: () => (
                        <>
                            이 서술어는 트랙에 반주 구간이 있는지 없는지
                            나타냅니다.
                            <ul>
                                <li>
                                    <strong>높은 값은</strong> 반주 구간에 (반주
                                    기타/피아노, 노래, 클래식 음악) 해당합니다.
                                </li>
                                <li>
                                    <strong>낮은 값은</strong> 트랙에 전자
                                    반주를 가리킵니다 (전자, 디스토션, 음향
                                    효과).
                                </li>
                            </ul>
                        </>
                    ),
                },
                danceability: {
                    title: "춤출 수 있는 능력",
                    description: () => (
                        <>
                            춤출 수 있는 능력은 트랙이 춤을 위해 만들어진
                            것인지를 나타냅니다. (템포와 리듬, 안정성과 규칙성에
                            대한 인식).
                            <ul>
                                <li>
                                    <strong>높은 값은</strong> 댄스 트랙에
                                    해당합니다.
                                </li>
                                <li>
                                    <strong>낮은 값은</strong> 리듬이 지각되지
                                    않는 트랙에 해당합니다.
                                </li>
                            </ul>
                        </>
                    ),
                },
                studio_live: {
                    title: "라이브성",
                    description: () => (
                        <>
                            이 서술어는 트랙이 라이브로 공연되었는지의 가능성을
                            가리킵니다.
                            <ul>
                                <li>
                                    <strong>높은 값은</strong> 라이브 트랙에
                                    해당합니다.
                                </li>
                                <li>
                                    <strong>낮은 값은</strong> 스튜디오 녹음에
                                    해당합니다.
                                </li>
                            </ul>
                        </>
                    ),
                },
                melodicity: {
                    title: "선율성",
                    description: () => (
                        <>
                            이 서술어는 트랙이 명확히 식별 가능한 멜로디를
                            포함하는지 여부를 나타냅니다.
                            <ul>
                                <li>
                                    <strong>높은 값은</strong> 따라 부르고 싶은
                                    기분이 드는 유쾌하고 화음이 조화로운 트랙에
                                    해당합니다.
                                </li>
                                <li>
                                    <strong>낮은 값은</strong> 소음,
                                    사운드스케이프 또는 복잡한 화음이나
                                    멜로디(클래식 음악, 재즈 등)의 트랙과 관련이
                                    있습니다.
                                </li>
                            </ul>
                        </>
                    ),
                },
                dissonance: {
                    title: "불협화음",
                    description: () => (
                        <>
                            이 서술어는 트랙이 얼마나 불협화음인지를 나타냅니다.
                            <ul>
                                <li>
                                    <strong>높은 값은</strong> 명확한 화음이
                                    없는 트랙 시끄러운 음색, 또는 매우 복잡한
                                    화음 (3온음, 초 등과 같은 불협화음 간격)에
                                    해당합니다.
                                </li>
                                <li>
                                    <strong>낮은 값은</strong> 단순한 화음,
                                    명확한 멜로디, 자음 하모니(예를 들어 단순한
                                    피아노 선율)가 있는 트랙에 해당합니다.
                                </li>
                            </ul>
                        </>
                    ),
                },
            },
            creditsCreditsData: {
                licence: "Muso.Ai 제공",
                musician: "뮤지션",
                songwriter: "작곡가",
                management: "매니지먼트",
                engineer: "엔지니어",
                producers: "프로듀서",
                organization: "조직",
                other: "기타",
                visual: "시각 자료",
            },
        },
    },
    recording: {
        view: {
            breadcrumb: "레코딩 시각화",
            id: "뮤직스토리 아이디",
            title: "타이틀",
            subtitle: "부제",
            isrc: "ISRC",
            genre: "장르",
            profile: "프로필",
            credits: "크레딧",
            popularity: "인기",
            charts: "차트",
            lyrics: "가사",
            audio_description: "음성 해설",
            similar: "비슷한 레코딩",
            work: "작품",
            noData: {
                lyrics: "이 레코딩에 사용할 수 있는 가사가 없습니다",
                credits: "이 레코딩에 사용할 수 있는 크레딧이 없습니다",
                charts: "이 레코딩에 사용할 수 있는 차트가 없습니다",
                audio: "이 레코딩에 사용할 수 있는 음성 해설이 없습니다",
                popularity: "이 녹화에 대한 인기도 데이터가 없습니다.",
                work: "이 녹음에 대한 작품 데이터가 없습니다.",
            },
            profileFeaturing: {
                head: "피처링",
            },
            profileInstrument: {
                head: "악기",
            },
            profileDuration: {
                head: "재생 시간",
            },
            profileRelatedIsrc: {
                head: "관련된 ISRC",
                has_credits: "크레딧",
                has_lyric: "가사",
                has_audio: "음성 해설",
            },
            profileTranslation: {
                head: "번역된 제목",
            },
            profileAlbum: {
                head: "앨범",
                cover: "커버",
                title: "타이틀",
                artists: "아티스트",
                type: {
                    head: "타입",
                    live: "라이브",
                    remix: "리믹스",
                    compilation: "컴필레이션",
                    karaoke: "가라오케",
                    ring: "원반",
                    other: "기타",
                    original: "오리지널",
                },
                format: {
                    head: "포맷",
                    album: "앨범",
                    ep: "EP",
                    single: "싱글",
                    mixtape: "믹스테이프",
                    mcd: "Maxi",
                },
                release_date: "발매 일자",
                links: "링크",
                empty: "결과 없음",
            },
            chartsRecordingCharts: {
                charts: {
                    head: "차트",
                    title: "타이틀",
                    empty: "결과 없음",
                    search: "검색하기...",
                },
                filter: {
                    format: {
                        label: "타입",
                    },
                    reference: {
                        label: "공식",
                        description: "국가의 공식 판매 순위",
                    },
                },
                trend: {
                    head: "트랜드",
                    x: "일자",
                    y: "랭킹",
                    dynamicRank: "빈 순위 제거",
                    dynamicDate: "빈 날짜 제거",
                },
            },
            lyricsInformation: {
                head: "정보",
                licence: {
                    title: "LyricFind 제공",
                    subtitle:
                        "이 콘텐츠는 LyricFind에서 제공하는 특정 라이선스 없이 사용할 수 없습니다",
                },
                lfid: "Lyricfind 아이디",
                hfa_code: "HFA 코드",
                publisher_credit: "발행인 크레딧",
                territories: "구역",
                lyric: "오리지널 가사",
            },
            audioAudioDescription: {
                head: "음성 해설",
                licence: "Simbals 기술로 계산된 음성 해설",
                moods: {
                    title: "레코딩 무드",
                    subtitle: "중요도 순서로",
                    description: () => (
                        <>
                            레코딩의 자세한 분위기와 그에 상응하는 무게. <br />
                            <strong>전체 리스트:</strong> 행복한, 슬픈, 차분한,
                            활동적인, 신이 난, 놀라운/흥분한,
                            긴장된/불안한/두려운, 화난, 괴로운, 매우 기쁜,
                            확신하는, 짜증난, 실망한, 불만스러운, 기쁜, 관심
                            있는, 중립적인, 불신하는, 비참한/실망한/깜짝 놀란,
                            평온한/만족하는, 자신감 있는, 걱정하는, 불편한,
                            슬픈/우울한/음울한, 편안한/만족하는, 사색하는,
                            피곤한/졸린, 처진, 지루한/불안한
                        </>
                    ),
                },
                themes: {
                    title: "테마",
                    description:
                        "리스닝 컨텍스트와 관련된 레코딩의 세부 테마는 다음을 포함합니다. 예를 들어, '운동', '식사', '크리스마스', ‘파티’, ‘비디오 게임’, '여름’, 기타…",
                },
                timbres: {
                    title: "음색",
                    description: "레코딩의 음색에 상응하는 태그.",
                },
                arousal: {
                    title: "각성도",
                    description: () => (
                        <>
                            각성도는 레코딩의 강도, 에너지 및 활동과 관련된
                            지각적 측정을 나타냅니다.
                            <ul>
                                <li>
                                    <strong>높은 값은</strong> 일반적으로 높은
                                    에너지 트랙 (하드록, 메탈)에 해당합니다
                                </li>
                                <li>
                                    <strong>낮은 값은</strong> 잔잔한 트랙
                                    (느린, 발라드)에게 주어집니다.
                                </li>
                            </ul>
                        </>
                    ),
                },
                valence: {
                    title: "정서가",
                    description: () => (
                        <>
                            정서가는 긍정적 또는 부정적 감정, 예를 들어
                            기쁨/슬픔과 관련된 기분의 지각된 척도를 나타냅니다.
                            <ul>
                                <li>
                                    <strong>높은 값은 </strong> 긍정적인
                                    분위기의 트랙에 해당합니다.
                                </li>
                                <li>
                                    <strong>낮은 값은 </strong> 슬픈 트랙을
                                    가리킵니다.
                                </li>
                            </ul>
                        </>
                    ),
                },
                vocal_instrumental: {
                    title: "기악성",
                    description: () => (
                        <>
                            이 서술어는 트랙이 보컬을 포함하고 있는지 아닌지
                            나타냅니다.
                            <ul>
                                <li>
                                    <strong>높은 값은</strong> 트랙이 보컬을
                                    포함하지 않을 가능성을 나타냅니다. (기악곡)
                                </li>
                                <li>
                                    <strong>낮은 값은</strong> 트랙이 보컬을
                                    포함할 가능성을 나타냅니다. (노래 가사, 랩,
                                    슬램이나 스피치를 예로 들 수 있습니다.
                                </li>
                            </ul>
                        </>
                    ),
                },
                music_speech: {
                    title: "언어 능력",
                    description: () => (
                        <>
                            이 서술어는 트랙이 언어를 포함하고 있는지 아닌지
                            나타냅니다.
                            <ul>
                                <li>
                                    <strong>높은 값은</strong> 음성 언어의
                                    존재와 관련이 있습니다.(인터뷰, 오디오북,
                                    시, 코멘터리).
                                </li>
                                <li>
                                    <strong>낮은 값은</strong>
                                    보컬은 있으나 음성 언어는 없는 음악에 (노래
                                    트랙), 해당합니다.
                                </li>
                            </ul>
                        </>
                    ),
                },
                electric_acoustic: {
                    title: "반주 구간",
                    description: () => (
                        <>
                            이 서술어는 트랙에 반주 구간이 있는지 없는지
                            나타냅니다.
                            <ul>
                                <li>
                                    <strong>높은 값은</strong> 반주 구간에 (반주
                                    기타/피아노, 노래, 클래식 음악) 해당합니다.
                                </li>
                                <li>
                                    <strong>낮은 값은</strong> 트랙에 전자
                                    반주를 가리킵니다 (전자, 디스토션, 음향
                                    효과).
                                </li>
                            </ul>
                        </>
                    ),
                },
                danceability: {
                    title: "춤출 수 있는 능력",
                    description: () => (
                        <>
                            춤출 수 있는 능력은 트랙이 춤을 위해 만들어진
                            것인지를 나타냅니다. (템포와 리듬, 안정성과 규칙성에
                            대한 인식).
                            <ul>
                                <li>
                                    <strong>높은 값은</strong> 댄스 트랙에
                                    해당합니다.
                                </li>
                                <li>
                                    <strong>낮은 값은</strong> 리듬이 지각되지
                                    않는 트랙에 해당합니다.
                                </li>
                            </ul>
                        </>
                    ),
                },
                studio_live: {
                    title: "라이브성",
                    description: () => (
                        <>
                            이 서술어는 트랙이 라이브로 공연되었는지의 가능성을
                            가리킵니다.
                            <ul>
                                <li>
                                    <strong>높은 값은</strong> 라이브 트랙에
                                    해당합니다.
                                </li>
                                <li>
                                    <strong>낮은 값은</strong> 스튜디오 녹음에
                                    해당합니다.
                                </li>
                            </ul>
                        </>
                    ),
                },
                melodicity: {
                    title: "선율성",
                    description: () => (
                        <>
                            이 서술어는 트랙이 명확히 식별 가능한 멜로디를
                            포함하는지 여부를 나타냅니다.
                            <ul>
                                <li>
                                    <strong>높은 값은</strong> 따라 부르고 싶은
                                    기분이 드는 유쾌하고 화음이 조화로운 트랙에
                                    해당합니다.
                                </li>
                                <li>
                                    <strong>낮은 값은</strong> 소음,
                                    사운드스케이프 또는 복잡한 화음이나
                                    멜로디(클래식 음악, 재즈 등)의 트랙과 관련이
                                    있습니다.
                                </li>
                            </ul>
                        </>
                    ),
                },
                dissonance: {
                    title: "불협화음",
                    description: () => (
                        <>
                            이 서술어는 트랙이 얼마나 불협화음인지를 나타냅니다.
                            <ul>
                                <li>
                                    <strong>높은 값은</strong> 명확한 화음이
                                    없는 트랙 시끄러운 음색, 또는 매우 복잡한
                                    화음 (3온음, 초 등과 같은 불협화음 간격)에
                                    해당합니다.
                                </li>
                                <li>
                                    <strong>낮은 값은</strong> 단순한 화음,
                                    명확한 멜로디, 자음 하모니(예를 들어 단순한
                                    피아노 선율)가 있는 트랙에 해당합니다.
                                </li>
                            </ul>
                        </>
                    ),
                },
                extra: {
                    rythm: "리듬",
                    timbre: "음색",
                    harmony: "화음",
                    energy: "에너지",
                },
                articulation: {
                    title: "아티큘레이션",
                    description: "아티큘레이션",
                },
                rhythmic_stability: {
                    title: "리듬 안정성",
                    description: "리듬 안정성",
                },
                event_density: {
                    title: "이벤트 정도",
                    description: "이벤트 정도",
                },
                pulse_clarity: {
                    title: "펄스 명료도",
                    description: "펄스 명료도",
                },
                bpm: {
                    title: "BPM",
                    description: "BPM",
                },
                complexity: {
                    title: "복잡도",
                    description: "복잡도",
                },
                binary: {
                    title: "바이너리",
                    description: "바이너리",
                },
                roll_off: {
                    title: "롤 오프",
                    description: "롤 오프",
                },
                brightness: {
                    title: "밝기",
                    description: "밝기",
                },
                zero_cross_rate: {
                    title: "제로 크로싱 비율",
                    description: "제로 크로싱 비율",
                },
                mfcc: {
                    title: "MFCC",
                    description: "MFCC",
                },
                mfcc01: {
                    title: "Mfcc01",
                },
                mfcc02: {
                    title: "Mfcc02",
                },
                mfcc03: {
                    title: "Mfcc03",
                },
                mfcc04: {
                    title: "Mfcc04",
                },
                mfcc05: {
                    title: "Mfcc05",
                },
                mfcc06: {
                    title: "Mfcc06",
                },
                mfcc07: {
                    title: "Mfcc07",
                },
                mfcc08: {
                    title: "Mfcc08",
                },
                mfcc09: {
                    title: "Mfcc09",
                },
                mfcc10: {
                    title: "Mfcc10",
                },
                mfcc11: {
                    title: "Mfcc11",
                },
                mfcc12: {
                    title: "Mfcc12",
                },
                mfcc13: {
                    title: "Mfcc13",
                },
                chroma: {
                    title: "크로마 (하모니, 조성)",
                    description: "크로마 (하모니, 조성)",
                },
                chroma01: {
                    title: "크로마01",
                },
                chroma02: {
                    title: "크로마02",
                },
                chroma03: {
                    title: "크로마03",
                },
                chroma04: {
                    title: "크로마04",
                },
                chroma05: {
                    title: "크로마05",
                },
                chroma06: {
                    title: "크로마06",
                },
                chroma07: {
                    title: "크로마07",
                },
                chroma08: {
                    title: "크로마08",
                },
                chroma09: {
                    title: "크로마09",
                },
                chroma10: {
                    title: "크로마10",
                },
                chroma11: {
                    title: "크로마11",
                },
                chroma12: {
                    title: "크로마12",
                },
                complexity_chroma: {
                    title: "복잡도 크로마",
                    description: "복잡도 크로마",
                },
                intensity: {
                    title: "강렬도",
                    description: "강렬도",
                },
                loudness: {
                    title: "음량",
                    description: "음량",
                },
                absolute_loudness: {
                    title: "절대 음량",
                    description: "절대 음량",
                },
                loudness_range: {
                    title: "음량 범위",
                    description: "음량 범위",
                },
                centroid: {
                    title: "중심지",
                    description: "중심지",
                },
                spread: {
                    title: "확산",
                    description: "확산",
                },
                flatness: {
                    title: "플랫",
                    description: "플랫",
                },
            },
            creditsCreditsData: {
                licence: "Muso.Ai 제공",
                musician: "뮤지션",
                songwriter: "작곡가",
                management: "매니지먼트",
                engineer: "엔지니어",
                producers: "프로듀서",
                organization: "조직",
                other: "기타",
                visual: "시각 자료",
            },
            popularityOverview: {
                head: "개요",
                global: {
                    title: "글로벌",
                    weeks: "차트 내 주 수:",
                    description:
                        "트랙의 인기도는 추적하는 차트에서 해당 트랙의 존재 여부와 순위를 기준으로 계산됩니다. 순위(인기 타이틀 순위에서 해당 타이틀의 위치)와 인기도(순위에서 가장 높은 인기 점수를 기준으로 한 % 단위)의 두 가지 형태로 표시됩니다.",
                },
                gold: {
                    title: "카탈로그",
                    weeks: "카탈로그 차트의 주 단위:",
                    description:
                        "타이틀의 카탈로그 인기는 18개월 이상 전의 공식 차트에 있는 그의 존재에 기반합니다. 카탈로그 인기는 공식 미국, 독일, 영국 차트의 데이터만을 고려합니다.",
                },
                current: {
                    title: "현재",
                    weeks: "현재 차트의 주 단위:",
                    description:
                        "현재 타이틀의 인기는 지난 18개월 동안의 차트 순위에 기반합니다.",
                },
                type: {
                    label: "타입:",
                    gold: "카탈로그",
                    current: "현재",
                },
                peak: "피크:",
            },
            popularityHistogram: {
                head: "시간 변화에 따른 인기도",
                tabs: {
                    gold: {
                        title: "카탈로그",
                        description:
                            "아티스트의 카탈로그 인기도는 18개월 전 차트에서의 순위를 기준으로 합니다. 카탈로그 인기도는 현재 빌보드 핫 100, 영국 공식 싱글 차트 및 오피셜 도이치 차트 데이터만 사용합니다.",
                        header: "카탈로그 인기도는 미국, 영국 및 DE 공식 차트 데이터만을 기준으로 하며, 더 많은 국가가 통합되고 있습니다.",
                    },
                    current: {
                        title: "현재",
                        description:
                            "현재 아티스트의 인기도는 지난 18개월 동안 차트에서 그의 활약을 기반으로 합니다.",
                    },
                },
                gold: "카탈로그 인기도",
                current: "현재 인기도",
                popover: {
                    noData: "데이터 없음",
                    popularity: "레코딩의 인기:",
                    bestRank: "베스트 랭킹:",
                    bestCountry: "베스트 국그:",
                    charts: (n) => `차트에 ${n} 기반`,
                },
            },
            popularityMap: {
                head: "국가별 인기도",
                tabs: {
                    gold: {
                        title: "카탈로그",
                        description:
                            "아티스트의 카탈로그 인기도는 18개월 전 차트에서의 순위를 기준으로 합니다. 카탈로그 인기도는 현재 빌보드 핫 100, 영국 공식 싱글 차트 및 오피셜 도이치 차트 데이터만 사용합니다.",
                    },
                    current: {
                        title: "현재",
                        description:
                            "현재 아티스트의 인기도는 지난 18개월 동안 차트에서 그의 활약을 기반으로 합니다.",
                    },
                },
                popover: {
                    noData: "데이터 없음",
                    popularity: "레코딩의 인기:",
                    bestRank: "베스트 랭킹:",
                    bestMonth: "베스트 순위의 달:",
                    weeksInCharts: "차트 진입 주:",
                    charts: (n) => `차트에 ${n} 기반`,
                },
            },
            workData: {
                iswc: "ISWC",
                hfa: "HFA 코드",
                publisher_credit: "발행인 크레딧 (Lyricfind)",
                share: {
                    head: "미국 지분 (MLC)",
                    party: "당사자",
                    ipi: "IPI",
                    role: "역할",
                    parent: "모회당사자",
                    parent_ipi: "모회사 IPI",
                    share: "지분",
                },
            },
        },
    },
    genre: {
        breadcrumb: "장르",
        genre: {
            head: "장르",
            parent: "상위 장르",
            name: "제목",
        },
        related: {
            head: "관련된",
            name: "제목",
            weight: "타입",
        },
        artist: {
            head: "톱 아티스트",
            position: "번호",
            name: "이름",
            complement: "보조",
            actions: "액션",
            country: "국가",
            type: "타입",
            role: "역할",
        },
        button: "장르 관리",
        search: "찾아보기...",
        empty: "결과 없음",
        manage: {
            breadcrumb: "관리",
            genre: {
                head: "장르",
                empty: "결과 없음",
                id: "아이디",
                name: "제목",
                description: "설명",
                related: "관련된",
                genre: "장르",
                type: "타입",
                genre_fr: "장르 프",
                genre_en: "장르 영",
                other_translations: "다른 번역",
                parent_genre: "상위 장르",
                parent_genre_placeholder:
                    "상위 장르를 만드려면 아무것도 선택하지 마십시오",
                created_at: "생성됨",
                updated_at: "업데이트됨",
                action: "액션",
                search: "찾아보기...",
            },
            create: {
                title: "장르 생성하기",
                success: "장르가 생성되었습니다",
                error: (message) =>
                    `이 장르를 생성하는 동안 오류가 발생했습니다: ${message}`,
            },
            update: {
                title: "장르 업데이트",
                success: "장르가 업데이트되었습니다",
                error: (message) =>
                    `이 장르르 업데이트하는 동안 오류가 발생했습니다: ${message}`,
            },
            delete: {
                title: "장르 삭제",
                alert: {
                    title: "잠깐만요!",
                    text: "정말 이 장르를 삭제하시겠습니까?",
                },
                success: "장르가 삭제되었습니다",
                error: (message) =>
                    `장르를 삭제하는 동안 오류가 발생했습니다: ${message}`,
            },
        },
    },
    country: {
        breadcrumb: "국가",
        head: "국가",
        button: "국가 관리",
        code: "코드",
        country_fr: "국가 프",
        country_en: "국가 영",
        country_ja: "국가 일",
        country_de: "국가 독일어",
        country_ko: "국가 한국어",
        search: "찾아보기...",
        empty: "표시할 국가 없음",
        manage: {
            breadcrumb: "관리",
            country: {
                head: "국가",
                empty: "결과 없음",
                id: "아이디",
                code: "코드",
                country_fr: "국가 프",
                country_en: "국가 영",
                other_translations: "다른 번역",
                created_at: "At 생성됨",
                updated_at: "At 업데이트됨",
                action: "액션",
                search: "찾아보기...",
            },
            create: {
                title: "국가 생성",
                success: "국가가 생성되었습니다",
                error: (message) =>
                    `국가를 생성하는 동안 오류가 발생했습니다: ${message}`,
            },
            update: {
                title: "국가 업데이트",
                success: "국가가 업데이트되었습니다",
                error: (message) =>
                    `국가를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
            },
            delete: {
                title: "국가 삭제",
                alert: {
                    title: "잠깐만요!",
                    text: "정말 이 국가를 삭제하시겠습니까?",
                },
                success: "국가가 삭제되었습니다",
                error: (message) =>
                    `국가를 삭제하는 동안 오류가 발생했습니다: ${message}`,
            },
        },
    },
    misc_entity: {
        breadcrumb: "표시",
        head: "표시",
        button: "기타 장르 관리",
        search: "찾아보기...",
        empty: "표시할 내용 없음",
        id: "아이디",
        name: "이름",
        type: "타입",
        description: "설명",
        date: "일자",
        management: {
            breadcrumb: "관리",
            profile: "프로필",
            curation: "큐레이션",
            getty: "게티이미지",
            main: "기본 정보",
            misc_entity: {
                id: "아이디",
                name: "이름",
                type: "타입",
                description: "설명",
                date: "일자",
                created_at: "생성됨",
                updated_at: "업데이트됨",
                action: "액션",
                search: "찾아보기...",
            },
            imageCuration: {
                head: "갤러리",
                empty: "갤러리에 이미지 없음",
                width: "너비",
                height: "높이",
                created_at: "생성 일자",
                copyright: "저작권",
                status: "상태",
                edit: "편집",
                edit_image: "이미지 정보 수정",
                delete: "삭제",
                delete_image: "이미지 삭제",
            },
            imageGetty: {
                gallery: {
                    head: "갤러리",
                    empty: "갤러리에 이미지 없음",
                    width: "너비",
                    height: "높이",
                    created_at: "생성 일자",
                    copyright: "저작권",
                    status: "상태",
                    edit: "편집",
                    edit_image: "이미지 정보 수정",
                    delete: "삭제",
                    delete_image: "이미지 삭제",
                },
            },
            create: {
                title: "기타 장르 생성",
                success: "기타 장르가 생성되었습니다",
                error: (message) =>
                    `기타 장르를 생성하는 동안 오류가 발생했습니다: ${message}`,
            },
            update: {
                title: "기타 장르 업데이트",
            },
            delete: {
                title: "기타 장르 삭제",
                alert: {
                    title: "잠깐만요!",
                    text: "정말 기타 장르를 삭제하시겠습니까?",
                },
                success: "기타 장르가 삭제되었습니다",
                error: (message) =>
                    `기타 장르를 삭제하는 동안 오류가 발생했습니다: ${message}`,
            },
        },
    },
    role: {
        breadcrumb: "역할",
        head: "역할",
        button: "역할 관리",
        role_fr: "역할 프",
        role_en: "역할 영",
        role_ja: "역할 일",
        role_de: "역할 독일어",
        role_ko: "역할 한국어",
        search: "찾아보기...",
        empty: "표시할 역할 없음",
        manage: {
            breadcrumb: "관리",
            role: {
                head: "역할",
                empty: "결과 없음",
                id: "아이디",
                role_fr: "역할 프",
                role_en: "역할 영",
                other_translations: "기타 번역",
                created_at: "생성됨",
                updated_at: "업데이트 됨",
                action: "액션",
                search: "찾아보기...",
            },
            create: {
                title: "역할 생성",
                success: "역할이 생성되었습니다",
                error: (message) =>
                    `역할을 생성하는 동안 오류가 발생했습니다: ${message}`,
            },
            update: {
                title: "역할 업데이트",
                success: "역할이 업데이트되었습니다",
                error: (message) =>
                    `역할을 업데이트하는 동안 오류가 발생했습니다: ${message}`,
            },
            delete: {
                title: "역할 삭제",
                alert: {
                    title: "잠깐만요!",
                    text: "정말 이 역할을 삭제하시겠습니까?",
                },
                success: "역할이 삭제되었습니다",
                error: (message) =>
                    `역할을 삭제하는 동안 오류가 발생했습니다: ${message}`,
            },
        },
    },
    plannings: {
        breadcrumb: "기획",
        manage: {
            breadcrumb: "기획 관리",
            planning: {
                empty: "결과 없음",
                building: "구축",
                progress: "진행중",
                done: "저장됨",
                title: "타이틀",
                startDate: "시작 일자",
                endDate: "종료 일자",
                stats: "진행",
                itemsCount: "결과",
            },
            create: {
                title: "기획 생성",
            },
            update: {
                title: "기획 수정",
            },
        },
        view: {
            breadcrumb: "기획 보기",
            partner: {
                all: "개요",
            },
            action: {
                validate: {
                    title: "기획 검증",
                    swal: {
                        title: "잠깐만요!",
                        notCompleted: "이 기획은 완전히 완료되지 않았습니다",
                        text: "정말 이 기획을 검증하시겠습니까?",
                    },
                },
                deleted: {
                    title: "기획 삭제",
                    swal: {
                        title: "잠깐만요!",
                        tasksExist: "이 기획은 비어있지 않습니다",
                        text: "정말 이 기획을 삭제하시겠습니까?",
                    },
                },
            },
            tasks: {
                title: "작업",
                empty: "결과 없음",
                manager: "관리자",
                status: "상태",
                artist: "아티스트",
                album: "앨범",
                track: "트랙",
                category: "카테고리",
                assignement: "과제",
                classification: "분류.",
                tasks: "작업",
                reason: "이유",
                information: "정보",
                nbRelated: "관련 번호",
                albumsWithoutGenre: "장르가 없는 앨범",
                hasBioFR: "프랑스어 바이오 있음",
                hasBioEN: "영어 바이오 있음",
                hasBioDE: "독일어 바이오 있음",
                hasBioJA: "일본어 바이오 있음",
                hasBioES: "스페인어 약력 있음",
                hasBioPT: "포르투갈어 바이오 있음",
                hasReview: "리뷰 있음",
                hasPicture: "사진 있음",
                hasCuration: "큐레이션 있음",
                lastUpdated: "마지막 계획 항목의 날짜",
                genre: "장르",
                language: "언어",
                country: "국가",
                action: {
                    topLink: "관련 톱 링크",
                    lyricsSampleLink: "가사 평가",
                    discoLink: "디스코그래피",
                    create: {
                        title: "작업 생성",
                    },
                    update: {
                        title: "작업 업데이트",
                    },
                    delete: {
                        title: "작업 삭제",
                        alert: {
                            title: "잠깐만요!",
                            text: "정말 이 작업을 삭제하시겠습니까?",
                        },
                    },
                },
                subtasks: {
                    change_status: "상태 변경 :",
                    confirm_change_status: {
                        TODO: {
                            title: "잠깐만요!",
                            text: "이 작업의 상태를 할 일로 변경하시겠습니까?",
                        },
                        COMPLETED: {
                            title: "잠깐만요!",
                            text: "이 작업의 상태를 완료로 변경하시겠습니까?",
                        },
                        TO_BE_CORRECTED: {
                            title: "잠깐만요!",
                            text: "이 작업의 상태를 수정 예정으로 변경하시겠습니까?",
                            comment: {
                                title: "코멘트 추가",
                                error: "코멘트가 필요합니다",
                            },
                        },
                        VERIFIED_AND_VALIDATED: {
                            title: "잠깐만요!",
                            text: "이 작업의 상태를 인증 및 검증됨으로 변경하시겠습니까?",
                        },
                        VALIDATED_WITHOUT_CONTROL: {
                            title: "잠깐만요!",
                            text: "이 작업의 상태를 제한 없이 검증됨으로 변경하시겠습니까?",
                        },
                        ERROR: {
                            title: "잠깐만요!",
                            text: "이 작업의 상태를 오류로 변경하시겠습니까?",
                            comment: {
                                title: "코멘트 추가",
                            },
                        },
                    },
                    status: {
                        UNASSIGNED: "미지정",
                        TODO: "할일",
                        COMPLETED: "완료",
                        TO_BE_CORRECTED: "수정 예정",
                        VALIDATED_WITHOUT_CONTROL: "제한 없이 검증됨",
                        VERIFIED_AND_VALIDATED: "인증 및 검증됨",
                        ERROR: "오류",
                    },
                },
            },
            stats: {
                title: "통계",
                manager: "관리자",
            },
            progress: {
                title: "진행",
                username: "사용자이름",
            },
            budget: {
                title: "예산",
                username: "사용자이름",
                task_type: "작업 타입",
            },
            history: {
                title: "히스토리 표시",
                head: "히스토리",
                empty: "결과 없음",
                search: "아티스트 찾아보기",
                action: {
                    created_by: "에 의해 생성됨",
                    deleted_by: "에 의해 삭제됨",
                    modified_by: "에 의해 수정됨",
                    release_matched_by: "앨범 일치",
                    release_unmatched_by: "앨범 불일치",
                    track_matched_by: "트랙 일치",
                    track_unmatched_by: "트랙 불일치",
                    status_changed_by: (new_status) =>
                        `에 의해 ${new_status} 으로 확인 됨`,
                    status_comment: (comment) => `코멘트 : ${comment}`,
                },
            },
        },
        tasks: {
            planning: "기획",
            artist: "아티스트",
            nbRelated: "관련 번호",
            hasBioFR: "프랑스어 바이오 있음",
            hasBioEN: "영어 바이오 있음",
            hasBioDE: "독일어 바이오 있음",
            hasBioJA: "일본어 바이오 있음",
            hasBioES: "스페인어 약력 있음",
            hasBioPT: "포르투갈어 바이오 있음",
            hasPicture: "사진 있음",
            hasCuration: "큐레이션 있음",
            album: "앨범",
            track: "트랙",
            category: "카테고리",
            classification: "분류",
            assignement: "과제",
            reason: "이유",
            error: {
                required: "이 필드는 필수입니다",
                albumTrackRequired: "앨범 또는 트랙 필드가 필요합니다",
            },
            subtasks: {
                title: "작업",
                typePlaceholder: "작업...",
                assignementPlaceholder: "영향을 받음...",
                new: "작업 추가",
                remove: "작업 삭제",
                typeGroups: {
                    ART: "아티스트",
                    ART_C: "아티스트 수정",
                    BIO_FR: "프랑스어 바이오그래피",
                    BIO_EN: "영어 바이오그래피",
                    BIO_DE: "독일어 바이오그래피",
                    BIO_PT: "포르투갈 전기",
                    BIO_JP: "일본 전기",
                    BIO_FR_C: "프랑스어 바이오그래피 수정",
                    BIO_EN_C: "영어 바이오그래피 수정",
                    BIO_DE_C: "독일어 바이오그래피 수정",
                    BIO_JP_C: "일본 바이오그래피 수정",
                    RVW: "리뷰",
                    RVW_C: "리뷰 수정",
                    APL_AS: "가사 평가",
                    APL_CAS: "가사 역평가 ",
                },
                error: {
                    oneTypePerGroup: (category) =>
                        `${category} 카테고리 안에 하나의 작업만 있어야 합니다`,
                },
            },
        },
        production: {
            breadcrumb: "랜딩 페이지",
            industry: "시장 통계",
            ms: "데이터베이스 통계",
            my_planning: "내 계획",
            tasks: {
                artist: "아티스트",
                album: "앨범",
                track: "트랙",
                genre: "장르",
                country: "국가",
                category: "카테고리-이유",
                classification: "분류",
                tasks: "작업",
                related_tasks: "Related Tasks",
                subtasks: {
                    change_status: "상태 변경 :",
                    match_required: "앨범 또는 트랙에 일치 항목이 필요합니다",
                    confirm_change_status: {
                        COMPLETED: {
                            title: "잠깐만요!",
                            text: "이 작업의 상태를 완료로 변경하시겠습니까?",
                        },
                        ERROR: {
                            title: "잠깐만요!",
                            text: "이 작업의 상태를 오류로 변경하시겠습니까?",
                            comment: {
                                title: "코멘트 추가",
                            },
                        },
                    },
                    status: {
                        TODO: "할 일",
                        COMPLETED: "완료되",
                        TO_BE_CORRECTED: "수정 예정",
                        VALIDATED_WITHOUT_CONTROL: "제한 없이 검증됨",
                        VERIFIED_AND_VALIDATED: "인증 및 검증됨",
                        ERROR: "오류",
                    },
                },
            },
        },
        client: {
            title: "뮤직스토리 프로덕션 기획",
            sub_title: ({ title, start_date, end_date }) =>
                ` ${start_date} 에서 ${end_date} 까지 ${
                    title ? `(${title})` : ""
                }`,
            status: ({ status }) => {
                const translatedStatus = {
                    BUILDING: "상태 : 구축",
                    PROGRESS: "상태 : 진행중",
                    DONE: "상태 : 저장됨",
                };
                return translatedStatus[status];
            },
            tasks: {
                artist: "아티스트",
                album: "앨범",
                track: "트랙",
                category: "카테고리",
                status_artist: "아티스트 프로필",
                status_bio_fr: "프랑스어 바이오",
                status_bio_en: "영어 바이오",
                status_bio_de: "독일어 바이오",
                status_bio_ja: "일본어 바이오",
                status_review: "리뷰",
                status_picture: "사진",
                status_curation: "큐레이션",
                status: {
                    creation: "생성",
                    update: "업데이트",
                    new: "새로운",
                    progress: "생성중",
                    exist: "이미 존재함",
                    missing: "찾을 수 없음",
                },
            },
        },
        picture: {
            breadcrumb: "사진 작업",
            planning: {
                head: "기획",
                title: "타이틀",
                empty: "결과 없음",
                status: "상태",
                building: "구축",
                progress: "진행중",
                done: "저장됨",
                startDate: "시작 일자",
                endDate: "종료 일자",
            },
            tasks: {
                title: "작업",
                empty: "결과 없음",
                artist: "아티스트",
                album: "앨범",
                track: "트랙",
                latest_added_date: "최근 추가 일자",
                status: "상태",
                information: "정보",
                label: "레이블",
                getty: "게티이미지",
                not_found: "찾을 수 없음",
                comment: "코멘트",
                last_label_created_at: "최근 레이블 사진 추가 일자",
                last_getty_created_at: "마지막 게티이미지 추가 일자",
                max_getty_date: "최근 게티이미지 추가 일자",
                has_disco: "디스코",
                last_check_date: "마지막 인증 일자",
                itemsCount: (count) => {
                    return `${count} 결과${count > 1 ? "s" : ""}`;
                },
            },
        },
        billing: {
            breadcrumb: "청구",
            empty: "사용 가능한 청구 없음",
            month: {
                period: ({ start_date, end_date }) =>
                    `기간 ${start_date} 에서 ${end_date}까지`,
            },
            subtasks: {
                artist: "아티스트",
                album: "앨범",
                track: "트랙",
                validated_at: "검증 일자",
            },
        },
        statistic: {
            breadcrumb: "통계",
            tracking_period: "기간별 추적",
            chart_tracking: "차트 추적",
            ressources_tracking: "출처 추적",
            planning_annual: "연간 기획",
            charts_annual_tracking: "연간 차트 추적",
            resources_annual_tracking: "연간 출처 추적",
        },
    },
    apple: {
        sample: {
            assessment: {
                informations: {
                    head: "정보",
                    catalogType: "카탈로그 타입",
                    month: "월",
                    artist: "아티스트",
                    album: "앨범",
                    title: "타이틀",
                    duration: "기간",
                    isrc: "Isrc",
                    genre: "장르",
                    country: "국가",
                    language: "언어",
                    status: {
                        TODO: "할 일",
                        COMPLETED: "완료",
                        TO_BE_CORRECTED: "수정 예정",
                        VALIDATED_WITHOUT_CONTROL: "제한 없이 검증됨",
                        VERIFIED_AND_VALIDATED: "인증 및 검증됨",
                        ERROR: "오류",
                    },
                    planning: "기획",
                    manager: "관리자",
                },
                lyric: {
                    head: "가사",
                    empty: "결과 없음",
                    nbLine: "라인",
                    timing: "타이밍",
                    text: "텍스트",
                    rating: {
                        filter: "이 라인에 대한 등급 참조",
                        criteria: "평가 기준",
                        errorType: "오류 타입",
                        comment: "코멘트",
                        create: {
                            title: "등급 추가",
                            success: "등급이 추가되었습니다",
                            error: (message) =>
                                `등급을 추가하는 동안 오류가 발생했습니다: ${message}`,
                        },
                    },
                },
                rating: {
                    reset: "모든 평가 보기",
                    line: (line_number) => `라인 : ${line_number}`,
                    criteria: "평가 기준 :",
                    errorType: "오류 타입 :",
                    comment: "코멘트 :",
                    delete: {
                        tooltip: "등급 삭제",
                        alert: {
                            title: "잠깐만요!",
                            text: "정말 이 등급을 삭제하시겠습니까?",
                        },
                        success: "등급이 삭제되었습니다.",
                    },
                },
                player: {
                    action: {
                        previous: "처음으로 돌아가기 (X)",
                        back10: "10초로 돌아가기 (C)",
                        pause: "음악 잠시 멈춤 (SPACE)",
                        play: "음악 재생 (SPACE)",
                    },
                    login: "로그인",
                    logout: "로그아웃",
                    playerError: {
                        unavailable: "사용 가능한 플레이어가 없습니다",
                        restricted: "제한된 콘텐츠",
                    },
                },
                ratingType: {
                    assessment: "평가",
                    counterAssessment: "역평가",
                },
            },
            management: {
                sample: {
                    head: "샘플",
                    empty: "결과 없음",
                    date: "일자",
                },
                catalog: "카탈로그",
                stats: "시작",
                progress: "진행",
                lyrics: {
                    head: "가사",
                    empty: "결과 없음",
                    catalog_type: "카탈로그 타입",
                    provider: "제공자",
                    user: "사용자",
                    artist: "아티스트",
                    album: "앨범",
                    title: "타이틀",
                    isrc: "Isrc",
                    genre: "장르",
                    language: "언어",
                    country: "국가",
                    assessment: "평가",
                    counter_assessment: "역평가",
                    filters: {
                        planning: "기획",
                        assignement: "영향을 받음",
                        nb_line: "행 수",
                        assessment: {
                            yes: "평가 있음",
                            no: "평가 없음",
                        },
                        counter_assessment: {
                            yes: "역평가 있음",
                            no: "역평가 없음",
                        },
                    },
                    action: {
                        addToPlanning: {
                            title: "기획에 추가",
                            exist: "기획에 이미 존재함",
                        },
                    },
                },
            },
            report: {
                sample: {
                    head: "샘플",
                    empty: "결과 없음",
                    date: "일자",
                },
                scorecard_provider: "평점표 제공자",
                scorecard_apple_user: "평점표 애플 사용자",
                ranking_language_genre: "언어/장르 랭킹",
                explorer: "익스플로러",
                error: "오류 세부 정보",
            },
        },
    },
    cnm: {
        repositories: {
            repositories: {
                title: "저장소",
                empty: "결과 없음",
            },
            years: {
                title: "년도",
                empty: "결과 없음",
            },
            upload: {
                button: {
                    title: "업로드",
                },
                modal: {
                    title: "저장소 업로드",
                    file: "파일",
                    file_placeholder: {
                        csv: "CSV 파일 선택",
                        json: "JSON 파일 선택",
                    },
                    browse: "검색",
                    type: "타입",
                    year: "연도",
                    submit: "업로드",
                    submitting: "업로딩",
                },
                conflict: {
                    usedIn: ({ name }) => `${name} is used in `,
                    titleLink: ({ artist, title }) => `${title} by ${artist}`,
                },
                success: "저장소가 업로드되었습니다",
            },
            csa_artists: {
                title: "CSA 아티스트",
                upload_help:
                    "CSV 파일에는 아티스트의 이름이 포함된 열이 하나 있어야 하며 머리글 행은 없어야 합니다.",
                empty: "결과 없음",
                search: "검색하기...",
                name: "이름",
                conflict:
                    "아래 CSA 아티스트는 통합된 상단에 사용되지만 현재 업로드된 파일은 없습니다:",
            },
            cnm_artists_titles: {
                title: "CNM 아티스트/타이틀",
                artists: {
                    head: "아티스트",
                    search: "검색하기...",
                    id: "아이디",
                    name: "이름",
                    msArtists: "MS 아티스트",
                    noArtist: "아티스트 없는 타이틀",
                    edit: {
                        tooltip: "아티스트 수정",
                        title: "아티스트 수정",
                        msArtistsPlaceholder: "아티스트 선택",
                        submit: "수정",
                        success: "아티스트가 수정되었습니다",
                    },
                    delete: {
                        tooltip: "아티스트 삭제",
                        title: "아티스트 삭제",
                        text: "정말 이 아티스트를 삭제하시겠습니까?",
                        submit: "삭제",
                        success: "아티스트가 삭제되었습니다",
                    },
                },
                titles: {
                    head: ({ noArtist, artistName }) =>
                        noArtist
                            ? "아티스트 없는 타이틀"
                            : artistName
                            ? `${artistName}의 타이틀`
                            : "타이틀",
                    search: "검색하기...",
                    id: "아이디",
                    artist: "아티스트",
                    title: "타이틀",
                    msRecordings: "MS 레코딩",
                    artistPlaceholder: "아티스트 선택",
                    msRecordingsPlaceholder: "레코딩 선택",
                    edit: {
                        tooltip: "타이틀 수정",
                        title: "타이틀 수정",
                        submit: "수정",
                        success: "타이틀이 수정되었습니다",
                    },
                    delete: {
                        tooltip: "타이틀 삭제",
                        title: "타이틀 삭제",
                        text: "이 타이틀을 삭제하시겠습니까?",
                        matchings:
                            "이 타이틀이 다음 플랫폼 차트에 일치되어 있으므로 삭제할 수 없습니다.",
                        position: (positions) => `rank(s) ${positions}`,
                        submit: "삭제",
                        success: "타이틀이 삭제되었습니다",
                    },
                },
            },
            cnm_genres: {
                title: "CNM 장르",
                upload_help:
                    "CSV 파일에는 아티스트의 이름이 포함된 열이 하나 있어야 하며 머리글 행은 없어야 합니다.",
                genres: {
                    title: "CNM 장르",
                    empty: "결과 없음",
                    search: "검색하기...",
                    cnmGenre: "CNM 장르",
                    msGenres: "MS 장르",
                },
                matchings: {
                    title: "일치",
                    search: "검색하기...",
                    empty: "결과 없음",
                    filter: {
                        msMainGenre: "기본 MS 장르",
                        cnmGenre: "CNM 장르",
                        notMatched: "일치하지 않음",
                    },
                    msGenre: "MS 장르",
                    cnmGenre: "CNM 장르",
                    cnmGenrePlaceholder: "CNM 장르 선택",
                    matchSelection: {
                        button: "선택 일치",
                        title: "선택된 MS 장르 일치",
                        genrePlaceholder: "CNM 장르 선택",
                        modifiedMatchings:
                            "경고, 다음 일치 항목이 대체됩니다.:",
                        submit: "일치",
                    },
                    copyMatchings: {
                        button: "복사 일치",
                        title: "다른 연도의 저장소에서 일치 항목 복사",
                        genrePlaceholder: "연도 선택",
                        deletedGenres: (yearSrc) =>
                            `${yearSrc}에서 삭제된 장르:`,
                        newGenres: (yearDst) => `${yearDst}의 새로운 장르:`,
                        modifiedMatchings: {
                            title: ({ yearSrc, yearDst }) =>
                                `경고, 다음 MS 장르가 저장소 ${yearSrc} 및 ${yearDst}에서 일치하는 항목이 다릅니다. ${yearDst} 의 일치 항목은 유지됩니다:`,
                            item: ({
                                yearSrc,
                                yearDst,
                                msName,
                                dstCNMName,
                                srcCNMName,
                            }) =>
                                `${msName} (MS) – ${srcCNMName} in ${yearSrc} and ${dstCNMName} in ${yearDst} (CNM)`,
                        },
                        submit: "복사",
                        success: "일치 항목이 복사되었습니다",
                    },
                },
                matchingSuccess: "일치 항목이 수정되었습니다",
            },
            cnm_labels: {
                title: "CNM 레이블",
                upload_help:
                    "CSV 파일에는 아티스트의 이름과 타입이 포함된 열이 두 줄 있어야 하며 머리글 행은 없어야 합니다.",
                empty: "결과 없음",
                search: "검색하기...",
                name: "이름",
                type: "타입",
                add: {
                    button: "추가하기",
                    tooltip: "레이블 추가",
                    success: "레이블이 추가되었습니다",
                },
                edit: {
                    tooltip: "레이블 수정",
                    success: "레이블이 수정되었습니다",
                },
                delete: {
                    tooltip: "레이블 삭제",
                    title: "레이블 삭제하기",
                    text: "정말 이 레이블을 삭제하시겠습니까?",
                    matchings:
                        "이 레이블은 통합된 상단의 다음 타이틀에 사용됩니다:",
                    titleLink: ({ artist, title }) => `${title} by ${artist}`,
                    submit: "삭제",
                    success: "레이블이 삭제되었습니다",
                },
                createOrUpdate: {
                    create: {
                        title: "레이블 추가",
                        submit: "추가하기",
                    },
                    update: {
                        title: "레이블 수정",
                        submit: "수정하기",
                    },
                    cnmName: "오리지널 CNM 이름",
                    name: "이름",
                    type: {
                        label: "타입",
                        placeholder: "타입 선택",
                        major: "메이저",
                        indie: "인디",
                    },
                },
                conflict:
                    "아래 CNM 레이블은 통합된 상단에 사용되지만 업로드된 파일에는 없습니다:",
            },
            cnm_distributors: {
                title: "CNM 배포자",
                upload_help:
                    "CSV 파일에는 배포자의 이름과 타입을 포함하는 두 개의 열이 있고 머리글 행은 없어야 합니다",
                empty: "결과 없음",
                search: "검색하기...",
                name: "이름",
                type: "타입",
                add: {
                    button: "추가",
                    tooltip: "배포자 추가",
                    success: "배포자가 추가되었습니다",
                },
                edit: {
                    tooltip: "배포자 수정",
                    success: "배포자가 수정되었습니다",
                },
                delete: {
                    tooltip: "배포자 삭제",
                    title: "배포자 삭제하기",
                    text: "정말 이 배포자를 삭제하시겠습니까?",
                    matchings:
                        "이 배포자는 통합된 상단의 다음 타이틀에 사용됩니다:",
                    titleLink: ({ artist, title }) => `${title} by ${artist}`,
                    submit: "삭제하기",
                    success: "배포자가 삭제되었습니다",
                },
                createOrUpdate: {
                    create: {
                        title: "배포자 추가",
                        submit: "추가하기",
                    },
                    update: {
                        title: "배포자 수정",
                        submit: "수정하기",
                    },
                    cnmName: "오리지널 CNM 이름",
                    name: "이름",
                    type: {
                        label: "타입",
                        typePlaceholder: "타입 선택",
                        distributor: "배포자",
                        topDistributor: "상단 배포자",
                    },
                },
                conflict:
                    "아래 CNM 배포자는 통합된 상단에 사용되지만 업로드된 파일에는 없습니다:",
            },
            cypok_languages: {
                title: "Cypok 언어",
                upload_help:
                    "이 파일은 the Newline-Delimited JSON 형식을 사용해야 합니다",
                empty: "결과 없음",
                artist: "아티스트",
                title_column: "타이틀",
                languages: "언어",
                search: "검색하기...",
            },
            platforms: {
                title: "플랫폼",
                empty: "플랫폼 없음",
                name: "이름",
                add: {
                    button: "추가하기",
                    tooltip: "플랫폼 추가",
                    success: "플랫폼이 추가되었습니다",
                },
                edit: {
                    tooltip: "플랫폼 수정",
                    success: "플랫폼이 수정되었습니다",
                },
                enable: {
                    tooltip: "플랫폼 활성화",
                    success: "플랫폼이 활성화되었습니다",
                },
                disable: {
                    tooltip: "플랫폼 비활성화",
                    success: "플랫폼이 비활성화되었습니다",
                },
                createOrUpdate: {
                    create: {
                        title: "플랫폼 추가",
                        submit: "추가하기",
                    },
                    update: {
                        title: "플랫폼 수정",
                        submit: "수정하기",
                    },
                    name: "이름",
                },
            },
        },
        aggregation: {
            yearSelect: {
                type: {
                    TEST: "테스트",
                    REAL: "진짜",
                },
            },
            cnmTitleList: {
                head: "CNM 타이틀",
                position: "랭킹",
                artist: "아티스트",
                title: "타이틀",
                platforms: "플랫폼",
                isrcs: "ISRC",
                duplicate: "중복.",
                mixed: "믹스.",
                streams: "스트리밍",
                check: "확인된 제목 표시",
                uncheck: "미확인 제목 표시",
                empty: "결과 없음",
                search: "검색하기...",
                filters: {
                    missingPlatforms: "플랫폼 찾을 수 없음",
                    numberOfMatchedPlatforms: "플랫폼 수",
                    status: {
                        placeholder: "상태",
                        none: "없음",
                        checked: "확인됨",
                        discarded: "폐기됨",
                    },
                },
                discardButton: {
                    discard: "타이틀 폐기",
                    recover: "타이틀 복원",
                    commentTitle: "이유: ",
                    head: "타이틀 폐기",
                    comment: "이유",
                    submit: "폐기",
                },
            },
            search: {
                head: "비슷한 CNM 타이틀",
                sameIsrc: "공통 ISRC가 있는 타이틀",
                artist: "아티스트",
                title: "타이틀",
                platforms: "플랫폼",
                streams: "스트리밍",
                empty: "결과 없음",
                form: {
                    search: "검색하기...",
                },
            },
            platformTitleList: {
                head: "플랫폼 타이틀",
                position: "랭킹",
                artist: "아티스트",
                title: "타이틀",
                subtitle: "부제",
                isrcs: "ISRC",
                duration: "기간.",
                streams: "스트리밍",
                empty: "결과 없음",
                move: "타이틀 이동",
            },
            createButton: {
                head: "선택에서 CNM 타이틀 생성",
                artist: "아티스트",
                title: "타이틀",
                success: "타이틀이 생성되었습니다",
            },
        },
        charts: {
            platform: {
                nav: "차트 - 플랫폼",
                year: {
                    head: "연도",
                    empty: "결과 없음",
                    name: "이름",
                    type: {
                        TEST: "테스트",
                        REAL: "진짜",
                    },
                },
                pf: {
                    head: "플랫폼",
                    empty: "결과 없음",
                    name: "이름",
                    show_disabled: "비활성화된 플랫폼 표시",
                },
                upload: {
                    title: "차트 업로드",
                    file: "파일",
                    file_placeholder: "CSV 파일 선택",
                    browse: "검색하기",
                    year: "연도",
                    type: {
                        label: "타입",
                        TEST: "테스트",
                        REAL: "진짜",
                    },
                    submit: "업로드",
                    submitting: "업로드 중",
                    success: "차트가 업로드되었습니다",
                    confirm: {
                        head: "정말 이 차트를 업로드하시겠습니까? 다음 필드가 수정됩니다:",
                        difference: ({ track, modifiedFields }) =>
                            `${track.artist_display} – ${track.title} (ID ${
                                track.track_id
                            }, rank ${track.position}): ${modifiedFields.join(
                                ", "
                            )}`,
                    },
                },
                matching: {
                    head: "일치 항목",
                    empty: "결과 없음",
                    search: "검색하기...",
                    position: "N°",
                    artist: "아티스트",
                    title: "타이틀",
                    isrc: "Isrc",
                    cnm_artist: "CNM 아티스트",
                    cnm_title: "CNM 타이틀",
                    streams: "스트리밍",
                    filters: {
                        charts: {
                            placeholder: "차트",
                            initial: "초기",
                            complement: "보완",
                        },
                    },
                },
            },
            deduplicated: {
                nav: "차트 - 중복됨",
                year: {
                    head: "연도",
                    empty: "결과 없음",
                    name: "이름",
                    type: {
                        TEST: "테스트",
                        REAL: "진짜",
                    },
                },
                pf: {
                    head: "플랫폼",
                    empty: "결과 없음",
                    name: "이름",
                    show_disabled: "비활성화된 플랫폼 표시",
                },
                chart: {
                    head: "차트",
                    empty: "결과 없음",
                    search: "검색하기...",
                    position: "N°",
                    artist: "아티스트",
                    title: "타이틀",
                    isrc: "Isrc",
                    streams: "스트리밍",
                },
            },
            aggregate: {
                nav: "차트 - 집계",
                year: {
                    head: "연도",
                    empty: "결과 없음",
                    name: "이름",
                    type: {
                        TEST: "테스트",
                        REAL: "진짜",
                    },
                },
                chart: {
                    head: "차트",
                    empty: "결과 없음",
                    search: "검색하기...",
                    rank: "랭킹",
                    artist: "아티스트",
                    title: "타이틀",
                    isrcs: "Isrc(s)",
                    platforms: "플랫폼",
                    streams: "스트리밍",
                    export: {
                        head: "내보내기",
                        requestForComplement: "보완 요청",
                        requestForConsolidationData: "통합 데이터 요청",
                        cypok: "Cypok",
                    },
                },
            },
        },
        report: {
            types: {
                title: "타입",
                empty: "타입 없음",
            },
            years: {
                title: "연도",
                empty: "연도 없음",
                type: {
                    TEST: "테스트",
                    REAL: "리얼",
                },
            },
            progress: {
                title: "진행",
                aggregate: "상단 집계",
                upload: "업로드",
                matching: "일치",
                building: "상위 1000 구축",
                coverage: "상단 집계 범위",
                consolidation: "상위 1000 통합",
                logs: {
                    title: "로그",
                    uploadChart: "상단 업로드됨",
                    uploadCypok: "Cypok 데이터 업로드됨",
                    requestForComplement: "보완 요청 내보냄",
                    requestForCypok: "Cypok 데이터 요청 내보냄",
                },
                lock: {
                    title: "표시기 잠금",
                    disabled: "표시기를 테스트 배치에서 잠글 수 없습니다.",
                    success: "표시기가 잠겼습니다",
                },
                unlock: {
                    title: "표시기 잠금 해제",
                    disabled:
                        "표시기를 테스트 배치에서 잠금 해제할 수 없습니다.",
                    success: "표시기가 잠금 해제되었습니다",
                },
            },
            consolidate: {
                yearSelect: {
                    type: {
                        TEST: "테스트",
                        REAL: "진짜",
                    },
                },
                chart: {
                    head: "차트",
                    search: "검색하기...",
                    empty: "결과 없음",
                    rank: "N°",
                    artist: "아티스트",
                    title: "타이틀",
                    platformSelect: {
                        aggregate: "집계",
                        global: "글로벌",
                    },
                    filter: {
                        firstLetter: "첫 글자.",
                        status: {
                            placeholder: "상태",
                            none: "없음",
                            validated: "인증됨",
                            discarded: "폐기됨",
                            reported: "신고됨",
                        },
                    },
                },
                edit: {
                    head: "타이틀",
                    header: {
                        platforms: "플랫폼",
                        openInAggregation: "집계 인터페이스에서 이 타이틀 열기",
                        artist: "아티스트",
                        artist_warning:
                            "이 정보는 아티스트의 모든 타이틀에서 수정됩니다.",
                        title: "타이틀",
                        vocals: "보컬",
                        problems: "문제",
                    },
                    title: {
                        label: "타이틀",
                    },
                    msArtists: {
                        label: "MS 아티스트",
                    },
                    isrcs: {
                        label: "ISRC",
                        placeholder: "ISRC 선택",
                        reference: "ISRC (MS / 플랫폼)",
                        select: "ISRC 선택",
                        unselect: "ISRC 선택 해제",
                        selectAll: "모든 ISRC 선택",
                    },
                    genre: {
                        label: "장르",
                        placeholder: "장르 선택",
                        reference: "장르 (MS / 플랫폼)",
                        select: "장르 선택",
                        unselect: "장르 선택 해제",
                    },
                    leadGender: {
                        label: "리더의 성별 (예술적 독립체)",
                        placeholder: "성별 선택",
                        reference: "리더의 성별 (MS)",
                        select: "성별 선택",
                        unselect: "성별 선택 해제",
                        F: "여성",
                        M: "남성",
                        MIXED: "양성",
                    },
                    countries: {
                        label: "국적",
                        placeholder: "아티스트의 국가를 선택하십시오",
                    },
                    voice: {
                        label: "리더의 성별 (목소리)",
                        placeholder: "목소리 선택",
                        F: "여셩",
                        M: "남성",
                        MIXED: "양성",
                        INSTRUMENTAL: "기악",
                    },
                    publicationDate: {
                        label: "발매 일자",
                        reference: "발매 일자 (MS / 플랫폼)",
                        select: "날짜 선택",
                        unselect: "날짜 선택 해제",
                    },
                    ageType: {
                        label: "타입",
                        new: "새로운",
                        recurring: "반복",
                        gold: "골드",
                        warning: ({ from, to, date }) =>
                            `이 타입은 ${from} 에서 ${to} 로 ${date}에 변경되었습니다`,
                    },
                    label: {
                        label: "레이블",
                        placeholder: "레이블 선택",
                        create: "레이블 생성",
                        reference: "레이블 (MS / 플랫폼)",
                        copy: "레이블 복사",
                    },
                    distributor: {
                        label: "배포자",
                        placeholder: "배포자 선택",
                        create: "배포자 생성",
                        reference: "배포자 (플랫폼)",
                        copy: "배포자 복사",
                    },
                    cnmArtist: {
                        label: "CNM 아티스트",
                        placeholder: "CNM 아티스트 선택",
                        name: "이름",
                        create: {
                            title: "아티스트 생성",
                            success: "아티스트가 생성되었습니다",
                        },
                        update: {
                            title: "아티스트 수정",
                            success: "아티스트가 수정되었습니다",
                        },
                    },
                    csaArtist: {
                        label: "CSA 아티스트",
                        placeholder: "CSA 아티스트 선택",
                    },
                    levelOfDevelopment: {
                        label: "발전 레벨",
                        confirmed: "확인됨",
                        new: "새로운 재능",
                    },
                    language: {
                        label: "언어",
                        placeholder: "언어 선택",
                        lyricfindReference: "언어 (Lyricfind)",
                        select: "언어 선택",
                        unselect: "언어 선택 해제",
                        FRENCH: "프랑스어",
                        INTERNATIONAL: "국제적인",
                        INSTRUMENTAL: "기악",
                    },
                    platformTitles: {
                        platform: "플랫폼",
                        artist: "아티스트",
                        title: "타이틀",
                        isrcs: "ISRC",
                        duration: "기간.",
                        streams: "스트리밍",
                        empty: "결과 없음",
                    },
                    validate: "타이틀 인증",
                    discard: {
                        head: "타이틀 폐기",
                        shouldBeFalse: "폐기된 타이틀은 인증할 수 없습니다",
                    },
                    report: {
                        head: "타이틀 신고",
                        shouldBeFalse: "신고된 타이틀은 인증할 수 없습니다",
                    },
                    discardReason: {
                        head: "이유",
                        placeholder: "이유 선택",
                        required: "타이틀은 이유 없이 폐기할 수 없습니다",
                    },
                    reportReason: "이유",
                    success: "타이틀이 수정되었습니다",
                    error: "정보를 저장하는 동안 오류가 발생했습니다",
                },
            },
            indicators: {
                title: "표시기",
                top_titles: "상단 타이틀",
                top_artists: "상단 아티스트",
                indicators: "표시기",
            },
        },
    },
    charts: {
        breadcrumb: "차트",
        display: {
            breadcrumb: "디스플레이",
        },
        translation: {
            breadcrumb: "번역",
        },
    },
    partner_tops: {
        partners: {
            title: "파트너",
            empty: "파트너 없음",
        },
        dates: {
            title: "일자",
            empty: "일자 없음",
        },
        tops: {
            title: "상단",
            empty: "상단 없음",
        },
        artists: {
            title: "아티스트",
            empty: "아티스트 없음",
            rank: "랭킹",
            name: "이름",
            matchings: "일치 항목",
            editMatchings: "일치 항목 수정",
            status: "상태",
            biographies: "바이오그래피",
            biographies_fr: "프랑스어",
            biographies_en: "영어",
            pictures: "사진",
            discarded: ({ date, user, comment }) =>
                `${date} 에 ${user} 에 의해 폐기됨 - "${comment}"`,
            filters: {
                max_rank: {
                    title: "랭킹",
                    top: (n) => `상단 ${n}`,
                },
                matched: {
                    title: "일치",
                    yes: "일치됨",
                    no: "일치하지 않음",
                },
                discarded: {
                    title: "폐기됨",
                    yes: "폐기됨",
                    no: "폐기되지 않음",
                },
                bio_en: {
                    title: "영어 바이오그래피",
                    yes: "영어 바이오그래피 있음",
                    no: "영어 바이오그래피 없음",
                },
                bio_fr: {
                    title: "프랑스어 바이오그래피",
                    yes: "프랑스어 바이오그래피 있음",
                    no: "프랑스어 바이오그래피 없음",
                },
                pictures: {
                    title: "사진 ",
                    valid: "유효한 사진",
                    improve: "개선할 사진",
                    missing: "사진을 찾을 수 없음",
                },
            },
            matching: {
                create: {
                    success: "일치 항목이 생성되었습니다",
                    duplicate: "일치 항목이 이미 존재합니다",
                    error: (message) =>
                        `일치 항목을 생성하는 동안 오류가 발생했습니다: ${message}`,
                },
                update: {
                    success: "일치 항목이 수정되었습니다",
                    not_found:
                        "일치 항목을 수정하는 동안 오류가 발생했습니다: 일치 항목이 더 이상 존재하지 않습니다",
                    duplicate:
                        "일치 항목을 수정하는 동안 오류가 발생했습니다: 일치 항목이 이미 존재합니다",
                    error: (message) =>
                        `일치 항목을 수정하는 동안 오류가 발생했습니다: ${message}`,
                },
                delete: {
                    success: "일치 항목이 삭제되었습니다",
                    not_found:
                        "일치 항목을 삭제하는 동안 오류가 발생했습니다: 일치 항목이 더 이상 존재하지 않습니다",
                    error: (message) =>
                        `일치 항목을 수정하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            discard: {
                button: {
                    title: "폐기",
                },
                modal: {
                    title: "잠깐만요!",
                    text: "정말 이 아티스트를 폐기하시겠습니까 ?",
                    placeholder: "코멘트",
                },
                success: "아티스트가 폐기되었습니다",
                duplicate:
                    "아티스트를 폐기하는 동안 오류가 발생했습니다: 아티스트가 이미 폐기되었습니다",
                error: (message) =>
                    `아티스트를 폐기하는 동안 오류가 발생했습니다: ${message}`,
            },
            recover: {
                button: {
                    title: "복원",
                },
                modal: {
                    title: "잠깐만요!",
                    text: "정말 이 아티스트를 복원하시겠습니까?",
                },
                success: "아티스트가 복원되었습니다",
                not_found:
                    "아티스트를 복원하는 동안 오류가 발생했습니다: 아티스트가 이미 폐기되었습니다",
                error: (message) =>
                    `일치 항목을 복원하는 동안 오류가 발생했습니다: ${message}`,
            },
        },
        artistsV3: {
            title: "아티스트",
            empty: "아티스트 없음",
            rank: "랭킹",
            artist: "아티스트",
            ms_artist: "MS 아티스트",
            discography_score: "일치된 앨범",
            biography_en: "영어 바이오그래피",
            biography_fr: "프랑스어 바이오그래피",
            curation: "큐레이션",
            pictures: "사진",
            discarded_on: (date) => `${date}에 폐기됨`,
            filters: {
                max_rank: {
                    title: "랭킹",
                    top: (n) => `상담 ${n}`,
                },
                matched: {
                    title: "일치 항목",
                    yes: "일치 항목 있음",
                    no: "일치 항목 없음",
                },
                discarded: {
                    title: "폐기됨",
                    yes: "폐기됨",
                    no: "폐기되지 않음",
                },
                bio_en: {
                    title: "영어 바이오그래피",
                    yes: "영어 바이오그래피 있음",
                    no: "영어 바이오그래피 없음",
                },
                bio_fr: {
                    title: "프랑스어 바이오그래피",
                    yes: "영어 바이오그래피 있음",
                    no: "영어 바이오그래피 없음",
                },
                curation: {
                    title: "큐레이션",
                    yes: "큐레이션 있음",
                    no: "큐레이션 없음",
                },
                pictures: {
                    title: "사진",
                    yes: "사진 있음",
                    no: "사진 없음",
                },
            },
            deezer: {
                market: "시장",
                musicbrainz: "MusicBrainz",
                facebook: "페이스북",
                filters: {
                    market: {
                        title: "시장",
                    },
                },
                pictures: {
                    head: "Deezer",
                    backstage: "Backstage",
                    other: "기타",
                    none: "없음",
                },
            },
            napster: {
                country: "국가",
                filters: {
                    country: {
                        title: "국가",
                    },
                },
            },
            matching: {
                create: {
                    success: "일치 항목이 생성되었습니다",
                    duplicate: "일치 항목이 이미 존재합니다",
                    error: (message) =>
                        `일치 항목을 생성하는 동안 오류가 발생했습니다: ${message}`,
                },
                update: {
                    success: "일치 항목이 수정되었습니다",
                    not_found:
                        "일치 항목을 수정하는 동안 오류가 발생했습니다: 일치 항목이 더 이상 존재하지 않습니다",
                    duplicate:
                        "일치 항목을 수정하는 동안 오류가 발생했습니다: 일치 항목이 이미 존재합니다",
                    error: (message) =>
                        `일치 항목을 수정하는 동안 오류가 발생했습니다: ${message}`,
                },
                delete: {
                    success: "일치 항목이 삭제되었습니다",
                    not_found:
                        "일치 항목을 삭제하는 동안 오류가 발생했습니다: 일치 항목이 더 이상 존재하지 않습니다",
                    error: (message) =>
                        `일치 항목을 삭제하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            discard: {
                button: {
                    title: "폐기됨",
                },
                modal: {
                    title: "잠깐만요!",
                    text: "정말 이 아티스트를 폐기하시겠습니까?",
                    placeholder: "코멘트",
                },
                success: "아티스트가 폐기되었습니다",
                duplicate:
                    "아티스트를 폐기하는 동안 오류가 발생했습니다: 아티스트가 이미 폐기되었습니다",
                error: (message) =>
                    `아티스트를 폐기하는 동안 오류가 발생했습니다: ${message}`,
            },
            recover: {
                button: {
                    title: "복원",
                },
                modal: {
                    title: "잠깐만요!",
                    text: "정말 이 아티스트를 복원하시겠습니까?",
                },
                success: "아티스트가 복원되었습니다",
                not_found:
                    "아티스트를 복원하는 동안 오류가 발생했습니다: 아티스트가 이미 폐기되었습니다",
                error: (message) =>
                    `아티스트를 복원하는 동안 오류가 발생했습니다: ${message}`,
            },
        },
        pictures: {
            none: "없음",
            getty: "게티이미지",
            curation: "큐레이션",
            label: "레이블",
            backstage: "Deezer - Backstage",
            other: "Deezer - 기타",
            last_label_creation: "마지막 레이블 사진이 추가된 날짜",
            last_getty_creation: "마지막 게티이미지가 추가된 날짜",
            last_getty_date: "가장 최근 게티이미지가 찍힌 날짜",
            last_check_date: "마지막 확인 날짜",
        },
    },
    pictures: {
        breadcrumb: "사진",
        label: {
            gallery: {
                breadcrumb: "클라이언트가 사용 가능",
                empty: "결과 없음",
                head: (label) => `레이블 사진: ${label}`,
                search: "아티스트 검색하기",
                artist: "아티스트",
                action: "액션",
                expired: "만료된 사진",
                takedownLabel: "사진을 내린 날짜",
                add: "이미지 추가",
            },
            status: {
                breadcrumb: "보류 중 / 추적 중",
                empty: "결과 없음",
                head: (label) => `레이블 사진: ${label}`,
                search: "아티스트 검색하기",
                picture: "사진",
                artist: "아티스트",
                status: "상태",
                filterStatusLabel: "추적 표시:",
                historic: "히스토리",
                statusChange: "상태 변경",
                createdAt: "제출 일자",
                formatedStatus: (status) => status.replaceAll("_", " "),
                definition: {
                    AWAITING: "사진이 고려되었으며 곧 처리될 것입니다",
                    AWAITING_FOR_PROCESSING:
                        "사진이 승인되어 문서화 대기 중입니다.",
                    DOCUMENTED:
                        "사진이 인증 절차를 통과하여 현재 사용할 수 있습니다.",
                    REJECTED:
                        "사진이 뮤직스토리 기준과 일치하지 않으므로 유효성을 검증할 수 없습니다",
                },
            },
            validation: {
                breadcrumb: "진행 중 이미지",
                empty: "결과 없음",
                validationHead: "인증",
                processingHead: "진행 중",
                search: "아티스트나 레이블 검색하기",
                picture: "사진",
                artist: "아티스트",
                label: "레이블",
                title: "타이틀",
                copyright: "저작권",
                date_takedown: "사진 내린 날짜",
                action: "액션",
                validate: {
                    title: "검증",
                    alert: {
                        title: "잠깐만요!",
                        text: "정말 이 사진을 검증하시겠습니까?",
                    },
                    success: "사진이 검증되었습니다",
                    error: (message) =>
                        `사진을 검증하는 동안 오류가 발생했습니다: ${message}`,
                },
                reject: {
                    title: "거절",
                    alert: {
                        title: "잠깐만요!",
                        text: "정말 이 사진을 거절하시겠습니까?",
                        commentTitle: "코멘트 추가",
                    },
                    success: "사진이 거절되었습니다",
                    error: (message) =>
                        `사진을 거절하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            rejected: {
                breadcrumb: "거절된 이미지",
                empty: "결과 없음",
                head: "거절된 이미지",
                search: "아티스트나 레이블 검색하기",
                picture: "사진",
                artist: "아티스트",
                label: "레이블",
                title: "타이틀",
                copyright: "저작권",
                date_takedown: "사진 내린 날짜",
                comment: "코멘트",
                action: "액션",
                retrieve: {
                    title: "회수하기",
                    alert: {
                        title: "잠깐만요!",
                        text: "정말 이 사진을 회수하시겠습니까?",
                    },
                    success: "사진이 회수되었습니다",
                    error: (message) =>
                        `사진을 회수하는 동안 오류가 발생했습니다: ${message}`,
                },
                edit: {
                    title: "코멘트 업데이트",
                    success: "코멘트가 업데이트되었습니다",
                    error: (message) =>
                        `코멘트를 업데이트하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
        },
    },
    labelPromo: {
        breadcrumb: "레이블",
        manage: {
            breadcrumb: "관리",
            label: {
                head: "레이블",
                empty: "결과 없음",
                id: "아이디",
                name: "이름",
                created_at: "생성됨",
                updated_at: "업데이트됨",
                status: "상태",
                action: "액션",
                search: "검색하기...",
            },
            show: {
                title: "사용자 시각화",
                empty: "연관된 사용자 없음",
            },
            create: {
                title: "레이블 생성",
                success: "레이블이 생성되었습니다",
                error: (message) =>
                    `레이블을 생성하는 동안 오류가 발생했습니다: ${message}`,
            },
            update: {
                title: "레이블 업데이트",
                success: "레이블이 업데이트되었습니다",
                error: (message) =>
                    `레이블을 업데이트하는 동안 오류가 발생했습니다: ${message}`,
            },
            delete: {
                title: "레이블 삭제",
                alert: {
                    title: "잠깐만요!",
                    text: "정말 이 레이블을 삭제하시겠습니까?",
                },
                success: "레이블이 삭제되었습니다",
                error: (message) =>
                    `레이블을 삭제하는 동안 오류가 발생했습니다: ${message}`,
            },
        },
    },
    artistTitle: {
        breadcrumb: "아티스트-타이틀",
        searchForm: {
            artist: "아티스트",
            title: "타이틀",
            search: "검색하기",
        },
        resultsList: {
            count: (count) => `${count} 결과`,
            isrc: (isrc) => (isrc ? `ISRC : ${isrc}` : "ISRC 없음"),
            recordingId: (id) => `레코딩 아이디 : ${id}`,
            artist: {
                head: "아티스트",
                picture: "아티스트 사진",
                id: (id) => `아티스트 아이디 : ${id}`,
            },
            album: {
                head: "앨범",
                picture: "앨범 사진",
                id: (id) => `앨범 아이디 : ${id}`,
            },
        },
    },
    recommendation: {
        form: {
            positive: "필드는 양수 여야합니다",
            min: "필드는 0보다 작을 수 없습니다",
            max: "필드는 100보다 커서는 안됩니다",
            select_options: {
                low: "낮은 (0-0.3333)",
                medium: "중간 (0.3333-0.6666)",
                high: "높은 (0.6666-1)",
            },
            select_options_bpm: {
                low: "낮은 (0-76)",
                medium: "중간 (76-160)",
                high: "높은 (160 이상)",
            },
        },
        breadcrumb: "확장 권장 사항",
        results: "결과",
        empty: "결과 없음",
        search: "검색...",
        ms_track_ids: "MS 트랙 ID",
        ms_track_id: "MS 트랙 ID",
        ms_artist_ids: "MS 아티스트 ID",
        min_accuracy: "최소 정확도",
        fa_bpm: "Bpm",
        fa_arousal: "흥분",
        fa_moods: "분위기",
        fa_valence: "감정 가치",
        fa_intensity: "강도",
        year_range: "연도 범위",
        fa_descriptor: "기술 용어",
        name: "이름",
        title: "제목",
        subtitle: "부제",
        genre: "장르",
        original_album: "앨범",
        original_release_date: "출시 날짜",
        artists: "아티스트",
        mbid: "Mbid",
        match: "일치",
        popularity: "인기",
    },
    data_external: {
        breadcrumb: "데이터 외부",
        internationals_charts: {
            breadcrumb: "국제 차트",
            charts: {
                nav: "차트",
                empty: "결과 없음",
                id: "아이디",
                name: "이름",
                positions: "포지션 수",
                treatments: "트리트먼트 범위",
                search: "검색하기...",
            },
            stats: {
                nav: "통계",
                empty: "결과 없음",
                search: "검색하기...",
                title: "타이틀",
                country: "국가",
                start_date: "일자",
                treatment: "일치됨",
                matched_simple: "단순함",
                creation: "생성",
                nb_discarded: "폐기됨",
                nb_forgot: "잊어버림",
            },
            coverage: {
                nav: "커버리지 정보",
                empty: "결과 없음",
                search: "검색하기...",
                simple: "단순한 일치",
                creation: "생성 개요",
                discarded: "폐기됨 - 확인했으나 처리되지 않음",
                forgot: "폐기됨 - 잊어버림",
                title: "타이틀",
                country: "국가",
                area: "지역",
                date: "일자",
                rank: "랭킹",
                artist: "아티스트",
                album_track: "앨범/트랙",
                ms_artist: "MS 아티스트",
                comment: "코멘트",
                user: "사용자",
            },
        },
        deezer_top_songs: {
            breadcrumb: "Deezer - 상단 노래",
            stats: {
                nav: "시작",
                empty: "결과 없음",
                date: "일자",
                country: "국가",
                treatment: "트리트먼트",
                nb_match_simple_ms: "단순 MS",
                nb_match_creation_ms: "Ms 생성",
                nb_discard_ms: "폐기된 MS",
                nb_match_lf: "LF 일치",
                nb_request_lf: "LF 요청",
                nb_forgot_lf: "LF 잊어버림",
                nb_match_lf_cas1: "Cas 1 일치",
                nb_match_lf_cas23_with_request: "Cas 2/3 요청",
                nb_match_lf_cas23_without_request: "Cas 2/3 요청 없음",
                nb_forgot_ms_lf: "MS + LF 잊어버림",
            },
            coverage: {
                nav: "커버리지 정보",
                empty: "결과 없음",
                search: "검색하기...",
                simple: "단순 일치 항목",
                creation: "생성 개요",
                new_lyrics: "가사 일치 항목",
                discarded_full: "폐기됨",
                forgot_full: "전부 잊어버림",
                forgot_lf: "LF 잊어버림",
                forgot_2_3: "2/3 잊어버림",
                date: "일자",
                country: "국가",
                id_deezer: "Deezer 아이디",
                artist: "아티스트",
                album: "앨범",
                song: "노래",
                ms_artist: "MS 아티스트",
                ms_album: "MS 앨범",
                lf_composition: "구성",
                comment: "코멘트",
                user: "사용자",
            },
        },
        universal_artist: {
            breadcrumb: "유니버설",
            stats: {
                nav: "시작",
                empty: "결과 없음",
                date: "일자",
                total: "글로벌",
                matched_total: "주간",
                matched_simple: "단순",
                matched_creation: "생성",
                discarded: "폐기됨",
            },
            coverage: {
                nav: "커버리지 정보",
                empty: "결과 없음",
                search: "검색하기...",
                simple: "단순",
                creation: "생성",
                discarded: "폐기됨",
                id: "아이디",
                name: "이름",
                ms_artist: "MS 아티스트",
                comment: "코멘트",
                user: "사용자",
            },
        },
    },
    api: {
        breadcrumb: "API",
        monitoring: {
            breadcrumb: "모니터링",
            token: "API 토큰",
            status: {
                head: "상태",
                remaining: "남은",
                limit: "제한",
                retry: "재시도 후",
                retry_description:
                    "이해 관계를 끊을 때까지 쿼리가 0이 남아 있을 때만 초기화 날짜가 표시됩니다. 이는 새로운 쿼리를 작성할 수 있는 날짜를 나타냅니다.",
                status: "상태",
            },
            historic: {
                head: "역사적인",
                date: "날짜",
                requests: "요청",
            },
        },
    },
    moodmedia: {
        breadcrumb: "무드미디어",
        search: {
            breadcrumb: "검색하기",
            results: "결과",
            empty: "결과 없음",
            title: "타이틀",
            artist: "아티스트",
            id: "아이디",
            original_release_date: "발매 일자",
            ms_album_title: "앨범 타이틀",
            name: "이름",
            subtitle: "부제",
            artists: "아티스트",
            mbid: "Mbid",
            match: "일치",
            popularity: "인기도",
            link: "WS2",
        },
        recommendation: {
            form: {
                error: "MS 필드와 MM 필드를 동시에 입력할 수 없습니다",
                positive: "필드는 양수여야 합니다",
                min: "필드는 0보다 작으면 안 됩니다",
                max: "필드는 100보다 크면 안 됩니다",
            },
            breadcrumb: "추천",
            results: "결과",
            empty: "결과 없음",
            search: "검색하기...",
            ms_track_ids: "MS 트랙 아이디",
            ms_track_id: "MS 트랙 아이디",
            ms_artist_ids: "MS 아티스트 아이디",
            mm_track_ids: "MM 트랙 아이디",
            mm_track_id: "MM 트랙 아이디",
            filter_no: "필터 번호",
            catalog: "카탈로그",
            min_accuracy: "최소 정확도",
            name: "이름",
            title: "타이틀",
            subtitle: "부제",
            genre: "장르",
            original_album: "앨범",
            original_release_date: "발매 일자",
            artists: "아티스트",
            mbid: "Mbid",
            match: "일치",
            popularity: "인기도",
        },
    },
    snep: {
        file: {
            breadcrumb: "SNEP 파일 관리",
            fileList: {
                head: "파일",
                search: "아이디 / 타입 / 일자",
                empty: "파일 없음",
                id: "아이디",
                name: "차트 타입",
                period: "기간",
                date: "일자",
                imported: "불러옴",
                exportedSnep: "SNEP 내보냄",
                exportedYadeck: "Yadeck 내보냄",
                upload: {
                    button: "추가",
                    head: "SNEP 파일 추가",
                    file: "컴퓨터에서 파일을 선택하려면 클릭합니다",
                    success: "파일이 추가되었습니다",
                },
                import: {
                    head: "SNEP 불러오기",
                    name: "파일",
                    startDate: "시작",
                    endDate: "종료",
                    chart: {
                        label: "차트",
                        placeholder: "차트 선택",
                        album: "앨범",
                        track: "트랙",
                    },
                    success: "파일을 불러왔습니다",
                },
                export: {
                    pending: "파일을 내보내는 중입니다...",
                    success: "파일을 내보냈습니다",
                },
                clean: {
                    title: "내보내기 삭제",
                    success: "내보내기가 삭제되었습니다",
                },
                delete: {
                    title: "파일 삭제",
                    success: "파일이 삭제되었습니다",
                },
            },
        },
    },
    matching: {
        tops: {
            breadcrumb: "일치",
        },
        artist: {
            partner: {
                head: "파트너",
                name: "이름",
            },
            list: {
                head: "리스트",
                empty: "리스트 없음",
                date: "#",
                name: "이름",
                matched: "일치됨",
                completed: "완료됨",
            },
            artist: {
                head: "아티스트",
                empty: "아티스트 없음",
                id: "#",
                name: "이름",
                firstname: "이름",
                lastname: "성",
                complementary: "보조 이름",
                discography: "디스코그래피",
                spotify: "스포티파이",
                musicbrainz: "MusicBrainz",
                max_created_at: "일자",
                complementary: "보조 이름",
                type: "타입",
                getty_picture: "게티이미지",
                picture: (nb) => `${nb} picture${nb > 1 ? "s" : ""}`,
                artist: "뮤직스토리 아티스트",
                action: "액션",
                checked: "확인됨",
                edit_matchings: "일치 항목 수정",
                match: {
                    success: "일치 항목이 생성되었습니다",
                    error: (message) =>
                        `일치 항목을 생성하는 동안 오류가 발생했습니다: ${message}`,
                },
                unmatch: {
                    success: "일치 항목이 삭제되었습니다",
                    error: (message) =>
                        `일치 항목을 삭제하는 동안 오류가 발생했습니다: ${message}`,
                },
                mark: {
                    button: {
                        title: "생략'으로 표시",
                    },
                    modal: {
                        title: "잠깐만요!",
                        text: "정말 이 아티스트를 생략하시겠습니까?",
                        placeholder: "코멘트",
                    },
                    success: "아티스트를 생략했습니다",
                    error: (message) =>
                        `아티스트를 생략하는 동안 오류가 발생했습니다: ${message}`,
                },
                unmark: {
                    button: {
                        title: "'생략 안 함'으로 표시",
                    },
                    success: "아티스트가 복원되었습니다",
                    error: (message) =>
                        `아티스트를 복원하는 동안 오류가 발생했습니다: ${message}`,
                },
                check: {
                    button: {
                        title: "'확인됨'으로 표시",
                    },
                    success: "아티스트가 확인되었습니다",
                    error: (message) =>
                        `아티스트를 확인하는 동안 오류가 발생했습니다: ${message}`,
                },
                artistCreated: {
                    button: {
                        title: "'아티스트 생성됨'으로 표시",
                    },
                    success: "아티스트가 생성됨으로 표시되었습니다",
                    error: (message) =>
                        `아티스트를 표시하는 동안 오류가 발생했습니다 : ${message}`,
                },
                unartistCreated: {
                    button: {
                        title: "'아티스트 생성되지 않음'으로 표시",
                    },
                    success: "아티스트가 생성되지 않음으로 표시되었습니다",
                    error: (message) =>
                        `아티스트를 표시 취소하는 동안 오류가 발생했습니다 : ${message}`,
                },
                filter: {
                    matched: {
                        placeholder: "일치됨",
                        true: "일치됨",
                        false: "일치하지 않음",
                    },
                    completed: {
                        placeholder: "완료됨",
                        true: "완료됨",
                        false: "완료되지 않음",
                    },
                    checked: {
                        placeholder: "확인됨",
                        true: "확인됨",
                        false: "확인되지 않음",
                    },
                    skiped: {
                        placeholder: "생략함",
                        true: "생략함",
                        false: "생략하지 않음",
                    },
                },
                legend: {
                    title: "레전드",
                    completed: "완료됨",
                    matched: "일치됨",
                    skiped: "생략함",
                    notAutoMatched: "자동 일치하지 않음(중복)",
                    error: "오류",
                },
            },
        },
        album: {
            partner: {
                head: "파트너",
                name: "이름",
            },
            list: {
                head: "리스트",
                empty: "리스트 없음",
                date: "#",
                name: "이름",
                matched: "일치됨",
            },
            album: {
                head: "앨범",
                empty: "앨범 없음",
                rank: "랭킹",
                cover: "커버",
                id: "#",
                title: "타이틀",
                snep_artist: "Snep 아티스트",
                artist: "뮤직스토리 아티스트",
                album: "뮤직스토리 앨범",
                spotify_album: "스포티파이 앨범",
                fnac_album: "Fnac 앨범",
                match: {
                    success: "일치 항목이 생성되었습니다",
                    error: (message) =>
                        `일치 항목을 생성하는 동안 오류가 발생했습니다: ${message}`,
                },
                unmatch: {
                    success: "일치 항목이 삭제되었습니다",
                    error: (message) =>
                        `일치 항목을 삭제하는 동안 오류가 발생했습니다: ${message}`,
                },
                filter: {
                    matched: {
                        placeholder: "일치됨",
                        true: "일치됨",
                        false: "일치하지 않음",
                    },
                },
                legend: {
                    title: "레전드",
                    matched: "일치됨",
                    partially_matched: "부분 일치됨",
                },
            },
        },
        track: {
            partner: {
                head: "파트너",
                name: "이름",
            },
            list: {
                head: "리스트",
                empty: "리스트 없음",
                date: "#",
                name: "이름",
                matched: "일치됨",
                progress: "진행",
            },
            track: {
                head: "트랙",
                empty: "트랙 없음",
                rank: "랭킹",
                cover: "커버",
                id: "#",
                title: "타이틀",
                snep_artist: "Snep 아티스트",
                artist: "뮤직스토리 아티스트",
                recording: "뮤직스토리 레코딩",
                duration: "기간",
                artist_album: "아티스트 - 앨범",
                case: "케이스",
                lyricfind: "Lyricfind 트랙",
                actions: "액션",
                spotify_track: "스포티파이 트랙",
                fnac_album: "Fnac 앨범",
                match: {
                    success: "일치 항목이 생성되었습니다",
                    error: (message) =>
                        `일치 항목을 생성하는 동안 오류가 발생했습니다: ${message}`,
                },
                unmatch: {
                    success: "일치 항목이 삭제되었습니다",
                    error: (message) =>
                        `일치 항목을 삭제하는 동안 오류가 발생했습니다: ${message}`,
                },
                request: {
                    button: {
                        enabled: "Lyricfind에 가사 요청 제출",
                        disabled: "Lyricfind에 가사 요청을 제출했습니다",
                    },
                    success: "Lyricfind에 가사 요청을 제출했습니다",
                    error: (message) =>
                        `가사 요청을 제출하는 동안 오류가 발생했습니다: ${message}`,
                },
                discard: {
                    button: {
                        title: "폐기됨",
                    },
                    modal: {
                        title: "잠깐만요!",
                        text: "정말 이 아티스트를 폐기하시겠습니까?",
                        placeholder: "코멘트",
                    },
                    success: "타이틀이 폐기되었습니다",
                    error: (message) =>
                        `타이틀을 폐기하는 동안 오류가 발생했습니다: ${message}`,
                },
                recover: {
                    button: {
                        title: "복원",
                    },
                    success: "타이틀이 복원되었습니다",
                    error: (message) =>
                        `타이틀을 복원하는 동안 오류가 발생했습니다: ${message}`,
                },
                filter: {
                    matched: {
                        placeholder: "일치됨",
                        true: "일치하지 않음",
                        false: "일치하지 않음",
                    },
                    discarded: {
                        placeholder: "폐기됨",
                        true: "폐기됨",
                        false: "폐기하지 않음",
                    },
                },
                legend: {
                    title: "레전드",
                    matched: "일치됨",
                    completed: "완료됨",
                    partially_matched: "부분 일치됨",
                    discarded: "폐기됨",
                },
            },
        },
    },
    profile: {
        personal_details: {
            title: "개인 정보",
            breadcrumb: "프로필",
            head: "기본 정보",
            account_type: {
                title: "계정 유형",
                music_service: "Music service",
                cmo: "CMO",
                label_distributor: "Label / Distributor",
                artist_manager: "Artist / Manager",
            },
            firstname: "이름",
            lastname: "성",
            email: "이메일",
            company: "회사",
            label: "레이블",
            artist: "아티스트",
            role: {
                title: "역할",
                music_editor: "Music editor",
                marketing: "Marketing",
                catalog_management: "Catalog Management",
                product_management: "Product Management",
                tech: "Tech",
                executive: "Executive",
                other: "Other",
                promotion: "Promotion",
                business_development: "Business development",
                a_and_r: "A&R",
                supply_chain: "Supply chain",
                analytics: "Analytics",
            },
            describe: "묘사하다",
            language: "언어",
            expires_at: "만료 일자",
            token: "API 토큰",
            success: "정보가 저장되었습니다",
        },
        change_password: {
            title: "비밀번호 변경",
            old_password: "기존 비밀번호",
            new_password: "새로운 비밀번호",
            confirm_password: "비밀번호 확인",
            success: "비밀번호가 업데이트되었습니다.",
        },
    },
    search: {
        breadcrumb: "검색하기",
        placeholder: "아티스트 / 앨범 / 레코딩",
        all: "모두",
        artists: "아티스트",
        albums: "앨범",
        recordings: "레코딩",
        empty: "결과 없음",
        more: "더 보기",
        artistsData: {
            head: "아티스트",
            cover: "이미지",
            id: "아이디",
            name: "이름",
            popularity: "인기도",
            peak: "인기의 정점",
            complementary: "보조 이름",
            type: "타입",
            genre: "장르",
            country: "국가",
            role: "역할",
            actions: "액션",
        },
        albumsData: {
            head: "앨범",
            cover: "커버",
            id: "아이디",
            title: "타이틀",
            artist: "아티스트",
            type: {
                head: "타입",
                live: "라이브",
                remix: "리믹스",
                compilation: "컴필레이션",
                karaoke: "가라오케",
                ring: "원반",
                other: "기타",
                original: "오리지널",
            },
            format: {
                head: "포맷",
                album: "앨범",
                ep: "EP",
                single: "싱글",
                mixtape: "믹스테이프",
                mcd: "Maxi",
            },
            genre: "장르",
            release_date: "오리지널 발매 일자",
            label: "레이블",
            actions: "액션",
        },
        recordingsData: {
            head: "레코딩",
            cover: "커버",
            id: "아이디",
            title: "타이틀",
            subtitle: "부제",
            popularity: "인기도",
            artist: "아티스트",
            duration: "기간",
            genre: "장르",
            release_date: "오리지널 발매 일자",
            complementary: "관련 콘텐츠",
            has_credits: "크레딧",
            has_lyric: "가사",
            has_audio: "음성 해설",
            actions: "액션",
        },
    },
    base: {
        user: {
            account: "프로필",
            logout: "로그아웃",
        },
        nav: {
            home: "홈",
            search: "검색하기",
            title: {
                partners: "파트너",
                admin: "관리",
            },
            apple: {
                title: "애플",
                sample: {
                    title: "샘플",
                    assessment: "평가",
                    management: "관리",
                    report: "신고",
                    guideline: "가이드라인",
                },
            },
            documentation: {
                title: "문서",
            },
            data: {
                title: "데이터",
                artists: {
                    title: "아티스트",
                    search: "고급 검색",
                    create: "생성",
                    followup: "생석/삭제 추적",
                },
                roles: "역할",
                country: "국가",
                genres: "장르",
                charts: {
                    title: "차트",
                    display: "디스플레이",
                    translation: "번역",
                },
                labelPromo: "레이블",
                data_external: {
                    title: "외부 일자",
                    internationals_charts: "국제 차트",
                    deezer_top_songs: "Deezer - 상단 노래",
                    universal_artist: "유니버설",
                },
                misc_entity: "표시",
                api: {
                    title: "API",
                    getting_started: "시작하기",
                    api_reference: "API 참조",
                    monitoring: "모니터링",
                    status: "상태",
                },
            },
            tools: {
                title: "도구",
                artistTitle: "아티스트-타이틀",
            },
            plannings: {
                title: "기획",
                overview: "개요",
                manage: "기획 관리",
                picture: "사진 작업",
                billing: "청구",
                statistic: "통계",
            },
            pictures: {
                title: "사진",

                label: {
                    gallery: "클라이언트가 사용 가능",
                    status: "진행 중 / 추적",
                    validation: "처리 예정 이미지",
                    rejected: "거절된 이미지",
                },
                curation: {
                    followup: "큐레이션 후속 조치",
                },
                getty: {
                    followup: "게티이미지 후속 조치",
                },
            },
            matching: {
                title: "일치 항목",
                artist: "아티스트",
                album: "앨범",
                track: "트랙",
                tops: "차트",
                lyricsSample: "가사 샘플",
                partner_tops: "상단 아티스트",
            },
            snep: {
                title: "SNEP",
                file: "상단 관리",
                chart: "차트 관리",
            },
            cnm: {
                title: "CNM",
                repositories: "저장소",
                aggregation: "집계",
                consolidation: "통합",
                charts: "차트",
                report: "신고",
            },
            moodmedia: {
                title: "무드미디어",
                search: "검색하기",
                recommendation: "추천",
            },
            admin: {
                users: {
                    title: "사용자",
                    user: "사용자 관리",
                    author: "저자 관리",
                },
                rights: {
                    title: "권한",
                    role: "역할 관리",
                    right: "권한 관리",
                    group: "그룹 관리",
                },
            },
        },
    },
    common: {
        explicit: {
            long: "명시적",
            short: "E",
        },
        multiSearch: {
            placeholder: "아티스트 / 앨범 / 레코딩",
            explanation: () => (
                <>
                    아티스트, 앨범, 녹음을 검색하여 API 또는 데이터플로우로 사용
                    가능한 모든 관련 메타데이터를 탐색하세요:
                    <ul className="mb-0">
                        <li>
                            아티스트: ISNI, 전기, 이미지, 인기도, 관련
                            아티스트...
                        </li>
                        <li>
                            앨범: UPC 메타데이터, 기본 앨범, 장르, 원래 출시
                            날짜...
                        </li>
                        <li>
                            녹음: ISRC 메타데이터, 크레딧, 가사, 오디오 설명...
                        </li>
                    </ul>
                </>
            ),
            search: "뮤직스토리 검색하기",
            artist: "아티스트",
            album: "앨범",
            type: {
                live: "라이브",
                remix: "리믹스",
                compilation: "컴필레이션",
                karaoke: "가라오케",
                ring: "원반",
                other: "기타",
                original: "오리지널",
            },
            format: {
                album: "앨범",
                ep: "EP",
                single: "싱글",
                mixtape: "믹스테이프",
                mcd: "Maxi",
            },
            recording: "레코딩",
            has_credits: "크레딧",
            has_lyric: "가사",
            has_audio: "음성 해설",
            more: "더 보기",
            empty: "결과 없음",
        },
        matching: {
            status: {
                not_found: "찾을 수 없음",
                matched: "일치됨",
                mixed: "혼합 디스코그래피",
                duplicates: "중복",
                unmatched: "일치하지 않음",
            },
        },
        partners: {
            "7D": "7D",
            AZ: "아마존",
            DG: "Discogs",
            DZ: "Deezer",
            IT: "아이튠즈",
            MB: "MusicBrainz",
            NA: "Napster",
            PR: "PriceMinister",
            QB: "Qobuz",
            UN: "유니버설",
            SP: "스포티파이",
            CJ: "CD 저널",
            LM: "LineMusic",
        },
        apiDownloadButton: {
            pending: "파일을 생성하는 중입니다",
            success: "파일이 생성되었습니다",
        },
        editMatchingsTable: {
            empty: "후보 없음",
            id: "아이디",
            name: "이름",
            type: {
                title: "타입",
                discarded: "폐기됨",
                valid: "수동으로 인증됨",
                invalid: "수동으로 무효화됨",
                matchedByName: "이름 일치됨",
                matchedByDiscography: "디스코그래피로 일치됨",
            },
            representativity: "대표성.",
            actions: {
                validate: "일치 항목 인증",
                cancelValidation: "수동 인증 취소",
                invalidate: "일치 무효화",
                cancelInvalidation: "수동 인증 취소",
            },
        },
        editMatchingsModal: {
            status: "상태:",
            matched: "일치됨",
            not_found: "일치하지 않음",
        },
        pictureCheckButton: {
            title: "확인",
            confirmModal: {
                title: "잠깐만요!",
                text: "이 아티스트의 사진을 인증하시겠습니까?",
            },
            commentModal: {
                title: "코멘트 추가",
                success: "코멘트가 추가되었습니다",
                error: (message) =>
                    `코멘트를 추가하는 동안 오류가 발생했습니다: ${message}`,
            },
        },
        pictureTagsModal: {
            title: "원본 이미지 태그 업데이트",
            titleVersion: "스퀘어 이미지 태그 업데이트",
            metas: "메타",
            tags: "태그",
            description: {
                2: () => (
                    <>
                        <strong>실내:</strong> 다른 카테고리에 맞지 않는 실내
                        사진
                        <br />
                        <strong>실외:</strong> 다른 카테고리에 맞지 않는 실외
                        사진
                        <br />
                        <strong>무대:</strong> 공연 중 찍은 무대 사진
                    </>
                ),
                7: () => (
                    <>
                        <strong>1:</strong> Image size greater than or equal to
                        2500px.
                        <br />
                        <strong>2:</strong> Image size between 1500px (included)
                        and 2500px (excluded).
                        <br />
                        <strong>3:</strong> Image size between 800px (included)
                        and 1500px (excluded).
                    </>
                ),
                4: () => (
                    <>
                        사진이 찍힌 시간. 날짜를 정확하게 알기 어려워 5년 단위로
                        적용하였습니다. 아티스트의 경력 중 어느 부분에 사진이
                        촬영되었는지 표시하는 것이 목표입니다. 촬영 날짜를
                        정확히 확인할 수 있는 경우를 위해{" "}
                        <strong>촬영 날짜 태그</strong>도 적용하였습니다.
                        게티이미지는 이미지에 "메타"가 언급되어 있는지
                        확인하세요.
                    </>
                ),
                5: () => (
                    <>
                        <strong>개인:</strong> 사진 속 아티스트가 한 명일 때
                        <br />
                        <strong>그룹:</strong> 사진 속 아티스트가 여러명이지만
                        그룹 전체는 아닐 때
                        <br />
                        <strong>전체 그룹:</strong> 사진에 전체 밴드 멤버가
                        표시됩니다.
                    </>
                ),
                6: () => (
                    <>
                        <strong>초상화:</strong> 머리부터 어깨까지
                        <br />
                        <strong>반신 초상화:</strong> 머리부터 허리까지
                        <br />
                        <strong>전신 초상화:</strong> 몸 전체
                    </>
                ),
            },
            shotDate: "촬영 일자",
            msArtists: "MS 아티스트",
            msArtistsPlaceholder: "아티스트 선택",
            open_tags: {
                title: "Open tags",
                placeholder: "Enter open tags",
            },
            tags_getty: "Tags Getty",
            success: "태그가 업데이트되었습니다",
            alert: {
                title: "확실하신가요?",
                text: "이 창을 닫으면 입력한 데이터가 손실됩니다.",
            },
            upload: "업로드",
            legal: "법률",
            cropping: "자르기",
            tagging: "태그 지정",
            warning:
                "인공지능이 생성한 예측이 낮은 점수를 받았으니 확인하시기 바랍니다.",
        },
        pictureCropModal: {
            head: "이미지 크기 조정",
            width: "너비",
            height: "높이",
            rotateLeft: "왼쪽으로 회전",
            rotateRight: "오른쪽으로 회전",
            success: "성공적으로 크기가 조정되었습니다",
        },
        pictureMoveModal: {
            title: "이미지 이동",
            artist: "아티스트",
            success: "이미지가 이동되었습니다",
        },
        multiRecordingSelect: {
            globalSearch: "모든 디스코그래피에서 검색하기",
        },
        imageUploadModal: {
            head: "이미지 추가",
            link: "여기에 링크 추가",
            browse: "찾아보기",
            file: "컴퓨터에서 이미지를 선택하려면 클릭하세요",
            success: "이미지가 업로드되었습니다",
            alert: {
                title: "확실하신가요?",
                text: "이 창을 닫으면 입력한 데이터가 손실됩니다.",
            },
            upload: "업로드",
            legal: "법률",
            cropping: "자르기",
            tagging: "태그 지정",
            warning_upscale:
                "업로드하면 이미지가 업스케일됩니다. 이 과정은 몇 초 정도 걸릴 수 있습니다. 다음 단계에서 이미지 품질을 다시 확인하세요.",
            warning_downscale:
                "업로드 시 이미지 크기가 축소됩니다. 몇 초 정도 걸릴 수 있습니다. 다음 단계에서 이미지 품질을 다시 확인하세요.",
        },
        pictureInformationModal: {
            head: "이미지 정보",
            source: "출처",
            source_url: "URL 출처",
            author: "사진작가",
            copyright: "저작권 고지",
            year: "연도",
            copyright_owner: "저작권 소유자",
            license: "라이선스",
            complementary: "보충",
            artist_site: "예술가 웹사이트",
            artist_label: "아티스트 라벨",
            other: "다른",
            description: {
                source: "이미지를 업로드하는 웹사이트의 출처.",
                source_url: "출처 웹사이트에서의 이미지 URL.",
                author: "이미지의 저자.",
                copyright:
                    "우리의 이미지를 사용하는 모든 사람이 표시해야 하는 법적 텍스트.",
                year: "이미지가 발행된 년도.",
                copyright_owner:
                    "이미지의 재산권 소유자입니다. 사진과 다를 경우에만 해당.",
                complementary:
                    "이미지에 대한 추가 정보, 예를 들어 이미지가 게시된 다른 웹사이트의 URL.",
            },
            success: "이미지 정보가 저장되었습니다",
            alert: {
                title: "확실하신가요?",
                text: "이 창을 닫으면 입력한 데이터가 손실됩니다.",
            },
            upload: "업로드",
            legal: "법률",
            cropping: "자르기",
            tagging: "태그 지정",
            licenseInformation: {
                head: "라이선스 세부정보",
                1: {
                    title: "GNU/FDL",
                    body: "The GNU / FDL (Free Documentation Licence) allows anyone to modify, copy and distribute the document if it is republished under this license. We'll take care of to indicate in any case the source (URL of the page where the document was found) and the type of license. In fact, this license was originally intended for the user manuals of free software distributed under the GNU/GPL license and it was always necessary to include this license with a history of modifications, authors' names etc... Over time, this license has been extended to other documents (images, sounds...) with which we do not always find a copy of the license (which must be the English version, that's what is specified in this same license!). As far as we are concerned, if we use a licensed image GNU/FDL by adding the license type, author and source, we will have already done our best. work.",
                },
                2: {
                    title: "CC-BY",
                    body: "크리에이티브 커먼즈: 친자관계 (BY): 저작권자의 이름을 인용하여 저작권이 저작권자에게 귀속되는 경우, 저작물의 유통을 포함한 파생저작물의 창작도 제한 없이 허용될 뿐 아니라 상업적 목적을 포함한 저작물의 모든 이용을 저작권자가 허락합니다. 본 라이선스는 저작물의 유통과 최대 이용을 위해 권장합니다.",
                },
                3: {
                    title: "CC-BY-SA",
                    body: "크리에이티브 커먼즈: 친자 관계 + 동일한 조건의 공유(BYSA): 저작물이 원본 저작물과 동일한 라이선스로 배포되는 경우, 저작권자는 원본 저작물의 이용(상업적 목적 포함) 및 파생 저작물의 창작을 허가합니다. 이 라이선스는 종종 카피레프트 라이선스 프리 소프트웨어와 비교됩니다. 위키피디아에서 사용하는 라이선스입니다.",
                },
                4: {
                    title: "CC-BY-ND",
                    body: "크리에이티브 커먼즈: 귀속 + 수정 불가(BYND): 권리자는 원저작물의 이용(상업적 목적 포함)은 허가하나, 파생저작물의 창작은 허가하지 않습니다.",
                },
                5: {
                    title: "CC-BY-NC",
                    body: "크리에이티브 커먼즈: 귀속 + 상업적 이용금지(BYNC): 저작물의 이용 및 파생저작물의 창작은 상업적 이용(상업적 이용은 여전히 그 인가 대상임)이 아닌 한 저작권자가 그 저작물의 이용을 허가합니다.",
                },
                6: {
                    title: "CC-BY-NC-SA",
                    body: "크리에이티브 커먼즈: 귀속 + 상업적 이용금지 + 동일한 조건의 공유(BYNCSA): 저작물이 원본 저작물과 동일한 라이선스로 배포되는 경우, 저작권자는 원본 저작물을 상업적 이용 이외의 목적으로 이용하는 것은 물론 파생저작물의 창작도 허용합니다.",
                },
                7: {
                    title: "CC-BY-NC-ND",
                    body: "크리에이티브 커먼즈: 귀속 + 상업적 이용금지 + 수정금지 (BY NCND) : 권리자는 원본 저작물을 비상업적 목적으로 이용하는 것은 인정하나, 파생저작물의 창작은 인정하지 않습니다.",
                },
                8: {
                    title: "무단 전재 금지",
                    body: "무단 전재를 금지합니다.",
                },
                9: {
                    title: "저작권 무료",
                    body: "저작권 무료",
                },
                10: {
                    title: "Public Domain",
                    body: "퍼블릭 도메인이란 저작권이 없는 상태로, 해당 저작물에 저작권이나 기타 법적 제한이 적용되지 않는 것을 의미합니다.",
                },
                11: {
                    title: "CC0",
                    body: "CC0(일명 CC 제로)는 크리에이터가 저작권을 포기하고 자신의 저작물을 전 세계 퍼블릭 도메인에 공개할 수 있는 공공 헌납 도구입니다. CC0를 사용하면 재사용자는 아무런 조건 없이 모든 매체나 형식으로 자료를 배포, 리믹스, 각색 및 구축할 수 있습니다.",
                },
            },
        },
        pictureInformationModalGetty: {
            upload: "업로드",
            legal: "법률",
            cropping: "자르기",
            tagging: "태그 지정",
            source: "출처",
            source_url: "URL 출처",
            author: "사진작가",
            copyright: "저작권 고지",
            year: "연도",
            copyright_owner: "저작권 소유자",
            license: "라이선스",
            complementary: "보충",
            description: {
                source: "이미지를 업로드하는 웹사이트의 출처.",
                source_url: "출처 웹사이트에서의 이미지 URL.",
                author: "이미지의 저자.",
                copyright:
                    "우리의 이미지를 사용하는 모든 사람이 표시해야 하는 법적 텍스트.",
                year: "이미지가 발행된 년도.",
                copyright_owner:
                    "이미지의 재산권 소유자입니다. 사진과 다를 경우에만 해당.",
                complementary:
                    "이미지에 대한 추가 정보, 예를 들어 컬렉션의 세부 사항 및 허용된 사용.",
            },
        },
        imageCropModal: {
            head: "이미지 크기 조정",
            width: "너비",
            height: "높으",
            rotateLeft: "왼쪽으로 회전",
            rotateRight: "오른쪽으로 회전",
            success: "이미지 크기가 성공적으로 조정되었습니다",
            alert: {
                title: "확실하신가요?",
                text: "이 창을 닫으면 입력한 데이터가 손실됩니다.",
            },
            upload: "업로드",
            legal: "법률",
            cropping: "자르기",
            tagging: "태그 지정",
        },
        labelUploadModal: {
            head: "아티스트 이미지 추가하기",
            link: "여기에 링크 추가",
            browse: "찾아보기",
            file: "컴퓨터에서 이미지를 선택하려면 클릭하세요",
            title: "타이틀",
            copyright: "저작권",
            takedown: "사진 내린 일자",
            label: "레이블",
            success: "이미지가 업로드되었습니다",
        },
        labelInformationModal: {
            head: "이미지 정보",
            title: "타이틀",
            copyright: "저작권",
            takedown: "사진 내린 일자",
            success: "이미지 정보가 저장되었습니다",
        },
        charts: {
            categoryList: {
                empty: "결과 없음",
                head: "카테고리",
                title: "타이틀",
                source: "출처",
                area: "지역",
                country: "국가",
                main: "기본",
                format: "포맷",
                link: "링크",
            },
            chartsList: {
                empty: "결과 없음",
                head: "차트 리스트",
                year: "연도",
                month: "월",
                startDate: "시작 일자",
                endDate: "종료 일자",
                matched: "일치됨",
                dataMatched: "데이터 일치됨",
                translated: "번역됨",
                userData: "할 일",
            },
            charts: {
                empty: "결과 없음",
                head: "차트",
                rank: "랭킹",
                artist: "아티스트",
                album: "앨범",
                track: "트랙",
                previousRank: "이전 랭킹",
                dateIn: "진입 일자",
                dateOut: "진출 일자",
                nbWeek: "주 수",
                rankIn: "랭킹 진입 일자",
                maxRank: "최고 랭킹",
                rankOut: "랭킹 진출 일자",
                msArtist: "MS 아티스트",
                msAlbum: "MS 앨범",
                msRecordings: "MS 레코딩",
                informations: "정보",
                hasBioFR: "프랑스어 바이오 있음",
                hasBioEN: "영어 바이오 있음",
                hasBioDE: "독일어 바이오 있음",
                hasBioJA: "일본어 바이오 있음",
                hasBioES: "스페인어 약력 있음",
                hasBioPT: "포르투갈어 바이오 있음",
                hasPictures: "사진 있음",
                hasCuration: "큐레이션 있음",
                hasReview: "리뷰 있음",
                lastUpdated: "마지막 계획 항목의 날짜",
                nbRelated: "관련된 자료 수",
                albumsWithoutGenre: "장르 없는 앨범",
                discarded: "폐기됨",
                matched: "일치됨",
                translated: "번역됨",
                creation: "생성",
                filter: {
                    matched: {
                        placeholder: "일치됨",
                        true: "일치됨",
                        false: "일치하지 않음",
                    },
                    newEntry: {
                        placeholder: "신규 진입",
                        true: "신규 진입",
                        false: "기존 진입",
                    },
                    toBeTreated: {
                        placeholder: "처리 예정",
                        true: "처리 예정",
                    },
                    discarded: {
                        placeholder: "폐기됨",
                        true: "폐기됨",
                        false: "폐기되지 않음",
                    },
                },
                albumTranslationsForm: {
                    artist: "아티스트",
                    album: "앨범",
                    translatedTitle: "번역된 타이틀",
                    translatedName: "번역된 이름",
                    multipleNamesWarning:
                        "일치하는 아티스트의 번역된 이름이 여러 개 있습니다." +
                        "다음 번역은 지워집니다:",
                    save: {
                        success: "번역이 저장되었습니다",
                        error: (message) =>
                            `번역을 저장하는 동안 오류가 발생했습니다: ${message}`,
                        tooltip: "번역 저장",
                    },
                    reset: "양식 재설정",
                },
                trackTranslationsForm: {
                    artist: "아티스트",
                    albums: "앨범",
                    recordings: "레코딩",
                    albumFormat: {
                        album: "앨범",
                        ep: "EP",
                        single: "싱글",
                        mixtape: "믹스테이프",
                        mcd: "Maxi",
                    },
                    albumType: {
                        live: "라이브",
                        remix: "리믹스",
                        compilation: "컴필레이션",
                        karaoke: "가라오케",
                        ring: "원반",
                        other: "기타",
                        original: "오리지널",
                    },
                    translatedTitle: "번역된 타이틀",
                    translatedSubtitle: "번역된 부제",
                    translatedName: "번역된 이름",
                    multipleNamesWarning:
                        "일치하는 아티스트의 번역된 이름이 여러 개 있습니다. " +
                        "다음 번역은 지워집니다:",
                    multipleTitlesWarning:
                        "일치된 모든 녹음에 동일한 번역된 제목이 없습니다. " +
                        "일치 항목이 정확한지 확인해주세요. " +
                        "다음 번역은 지워집니다:",
                    noTitle: "타이틀 없음",
                    noSubtitle: "부제 없음",
                    noTranslation: "번역 없음",
                    save: {
                        success: "번역이 저장되었습니다",
                        error: (message) =>
                            `번역을 저장하는 동안 오류가 발생했습니다: ${message}`,
                        tooltip: "번역 저장",
                    },
                    reset: "양식 재설정",
                },
                discardButton: {
                    discardTooltip: "폐기",
                    recoverTooltip: "복원",
                    modalTitle: "코멘트",
                },
                addToPlanningButton: {
                    title: "기획 추가",
                },
            },
        },
        picture: {
            action: {
                add: "이미지 추가",
                edit: "이미지 정보 수정",
                tags: "원본 이미지 태그 수정",
                tagsVersion: "스퀘어 이미지 태그 수정",
                crop: "이미지 크기 조정",
                move: "이미지 이동",
                del: {
                    title: "이미지 삭제",
                    alert: {
                        title: "잠깐만요!",
                        text: "정말 이 이미지를 삭제하시겠습니까?",
                    },
                    success: "이미지가 삭제되었습니다",
                    error: (message) =>
                        `이미지를 삭제하는 동안 오류가 발생했습니다: ${message}`,
                },
            },
            getty: {
                bas: {
                    head: "샌드박스",
                    empty: "샌드박스에 이미지 없음",
                    width: "너비",
                    height: "높이",
                    created_at: "생성 일자",
                    loading: "로딩 중...",
                    more: "더 보기",
                    filters: {
                        search_type: {
                            title: "검색 타입",
                            best_match: "일치 항목 대안",
                            most_popular: "최고 인기도",
                        },
                    },
                    action: {
                        detail: {
                            head: "샌드박스 사진 세부 정보",
                            dimensions: "크기",
                            id: "아이디",
                            title: "타이틀",
                            collection_name: "컬렉션_이름",
                            caption: "캡션",
                            people: "대중",
                            date_camera_shot: "촬영_날짜",
                            date_created: "생성_날짜",
                            date_submitted: "제출_날짜",
                            success: "이미지가 추가되었습니다",
                            error: (message) =>
                                `이미지를 추가하는 동안 오류가 발생했습니다: ${message}`,
                        },
                    },
                },
            },
        },
    },
    utils: {
        select: {
            empty: "결과 없음",
            placeholder: "검색하기...",
            loading: "로딩 중...",
            noOptions: "옵션 없음",
            create: "생성하기",
        },
        copy: {
            success: "텍스트가 클립보드에 복사되었습니다",
            error: "텍스트를 클립보드에 복사할 수 없습니다",
        },
        pagination: {
            total: ({ size }) => `${size} 결과`,
            first_page: "처음 페이지",
            last_page: "마지막 페이지",
            previous_page: "이전 페이지",
            next_page: "다음 페이지",
        },
        form: {
            required: "필수 필드입니다",
            number: "이 필드는 숫자가 아닙니다.",
            date_placeholder: "YYYY-MM-DD",
            date_format: "잘못된 형식입니다 (YYYY-MM-DD)",
            date_zero: "'0000-00-00'일자가 유효하지 않습니다",
            barcode_format: "바코드는 12자리 또는 13자리 숫자여야 합니다",
            isrc_format: "ISRC 형식이 올바르지 않음 (AANNN0000000)",
            duration_format: "기간 형식이 잘못되었습니다(hh:mm:ss)",
            duration_placeholder: "hh:mm:ss",
            year_format: "연도 형식이 잘못되었습니다(YYYY)",
            end_date_greater_than_start:
                "종료 날짜는 시작 날짜보다 크거나 같아야 합니다.",
            url: "Url이 유효하지 않습니다",
            wysiwyg: {
                placeholder: "작성 시작...",
                bold: "굵은",
                italic: "이탤릭체",
                underline: "밑줄",
                strikethrough: "취소선",
            },
            email: "이메일이 유효하지 않습니다",
            isni_format:
                "ISI는 16자리 코드 또는 15자리 코드 뒤에 X자가 와야 합니다.",
            upload_max_size: `파일이 너무 큽니다. (Max: ${
                UPLOAD_MAX_SIZE / 1000000
            } Mo)`,
            must_contain_uppercase: "적어도 하나의 대문자를 포함해야 합니다",
            must_contain_lowercase: "적어도 하나의 소문자를 포함해야 합니다",
            must_contain_8_characters: "8자 이상이어야 합니다",
            must_contain_digit: "적어도 하나의 숫자를 포함해야 합니다",
            must_contain_letter: "적어도 하나의 문자를 포함해야 합니다",
            passwords_must_match: "비밀번호가 일치하지 않습니다",
            not_hit_discovery_at_same_time:
                "히트 및 에디터의 선택 필드는 동시에 입력할 수 없습니다.",
        },
        button: {
            cancel: "취소",
            confirm: "확인",
            create: "생성",
            back: "뒤로",
            add: "추가",
            edit: "수정",
            delete: "삭제",
            deleteAll: "모두 삭제",
            validate: "검증",
            previous: "이전",
            next: "다음",
            now: "현재",
            save: "저장",
            close: "닫기",
            transfer: "이동",
            reset: "재설정",
            approve: "승인",
            reject: "거절",
            export: "내보내기",
            generate: "제작",
            upload: "업로드",
            search: "검색",
            merge: "통합",
            continue: "계속",
            download: "다운로드",
            browse: "찾아보기",
            login: "로그인",
            translate: "번역",
        },
        sweetalert: {
            warning: "경고",
            error: "오류",
            cancel: "아니요, 취소합니다",
            close: "닫기",
            confirm: "네, 확인합니다",
            ok: "네, 알겠습니다",
        },
        filters: {
            all: "전부",
            none: "없음",
        },
        lang: {
            PT: "포르투갈어",
            JA: "일본어",
            DE: "독일어",
            EN: "영어",
            ES: "스페인어",
            FR: "프랑스어",
            KO: "한국어",
            CD_JOURNAL: "CD 저널",
        },
        nth: (n) => (
            <>
                {n?.toLocaleString("en")}
                <sup>
                    {n % 10 === 1
                        ? "st"
                        : n % 10 === 2
                        ? "nd"
                        : n % 10 === 3
                        ? "rd"
                        : "th"}
                </sup>
            </>
        ),
    },
    landing: {
        breadcrumb: "랜딩 페이지",
        industry: "시장 통계",
        ms: "데이터베이스 통계",
        industryStats: {
            chart_title: (top) => `공식 상위${top ?? ""}위 기준 - `,
            chart_date: ({ start_date, end_date }) =>
                ` (${start_date ?? ""}부터${end_date ?? ""}까지)`,
            top_title: (country) =>
                `현재 가장 인기 있는 타이틀 - ${country ?? ""}`,
            top_title_description:
                "현재 달에 선택한 국가에서 가장 인기 있는 타이틀입니다.",
            top_artist: (country) =>
                `현재 가장 인기 있는 아티스트 - ${country ?? ""}`,
            top_artist_description:
                "현재 달에 선택한 국가에서 가장 인기 있는 아티스트입니다.",
            top_progression: ({ id_country, country }) =>
                id_country == 33
                    ? `당일 베스트 응모작 - ${country ?? ""}`
                    : `금주의 베스트 응모작 - ${country ?? ""}`,
            top_progression_description: (id_country) =>
                id_country == 33
                    ? "차트 베스트 신규 항목."
                    : "차트 베스트 신규 항목.",
            sexe_repartition: (country) =>
                `유형별 아티스트 분석 - ${country ?? ""}`,
            sexe_repartition_description:
                "선택한 국가의 공식 차트에서 '그룹' 및 '솔로' 아티스트(솔로 아티스트의 경우 성별)의 비율을 나타냅니다.",
            genre_repartition: (country) =>
                `음악 장르별 분포 - ${country ?? ""}`,
            genre_repartition_description:
                "선택한 국가의 차트에 표시된 음악 장르의 분포입니다.",
            language_repartition: (country) => `성 언어 - ${country ?? ""}`,
            language_repartition_description:
                "선택한 국가의 공식 차트에서 노래하는 언어의 분포입니다.",
            top_emerging: (country) => `신진 아티스트 - ${country ?? ""}`,
            top_emerging_description:
                "지난 3개월 동안 차트에 처음 등장한 가장 인기 있는 아티스트입니다.",
            current_catalog: (country) =>
                `연차 유형별 곡 배포 - ${country ?? ""}`,
            current_catalog_description:
                "선택한 국가의 공식 차트에서 '현재'(출시일 18개월 미만) 및 '카탈로그'(출시일 18개월 이상) 타이틀을 표시합니다.",
            no_data: "알 수 없음",
            other: "기타",
            gold: "카탈로그",
            current: "현재",
            person: "개인",
            band: "밴드",
            man: "남자",
            woman: "여자",
            top_3: "상위 3:",
            total: "합계: ",
        },
        msStats: {
            artists: {
                title: "아티스트",
                artists_with_photo: "이미지가 있는 아티스트",
                artists_with_bio: "전기가 있는 아티스트",
                biographies_description: "지원되는 언어는 다음과 같습니다.",
            },
            albums: {
                title: "앨범",
                canonical_albums: "카노이컬 앨범",
                albums_with_cover_art: "커버 아트가 있는 앨범",
                releases: "발매곡",
            },
            recordings: {
                title: "레코딩",
                unique_recording: "고유 레코딩",
            },
        },
    },
};
