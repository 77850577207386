import React from "react";
import I18n from "shared/lib/I18n";
import Tippy from "@tippyjs/react";
import ImageLoader from "shared/components/ImageLoader";
import { Nav, Tab, OverlayTrigger, Popover } from "react-bootstrap";
import clsx from "clsx";
import InputSwitch from "shared/components/Form/InputSwitch";
import ArtistLink from "shared/components/ArtistLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";
import placeholder from "assets/static/images/logo_placeholder.png";
import Featuring from "./Univers/Featuring";
import Translation from "./Univers/Translation";
import Type from "./Univers/Type";
import Label from "./Univers/Label";
import ReleaseDate from "./Univers/ReleaseDate";
import Format from "./Univers/Format";
import Profile from "./Profile";
import Genres from "./Univers/Genres";
import Languages from "./Univers/Languages";
import Instruments from "./Univers/Instruments";
import AudioDescription from "./AudioDescription";
import Credits from "./Credits";
import { Helmet } from "react-helmet";
import RatingStar from "shared/components/RatingStar";
import { displayAsString } from "shared/functions/Date";
import Widget from "shared/components/Widget";
import Releases from "./Univers/Releases";
import Tracklist from "./Univers/Tracklist";
import "./AlbumView.css";

const AlbumView = ({
    album,
    headerObserverRef,
    isHeaderSticky,
    selectedTab,
    onSelectTab,
    locale,
    location,
    containerRef,
    containerTopOffset,
    redirectToEdit,
    hasData,
    setNoData,
    user,
}) => {
    return (
        <div className="content-wrap album-view">
            <main id="content" className="content" role="main">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <I18n t="dashboard" />
                    </li>
                    {album.artists && (
                        <li className="breadcrumb-item">
                            {album.artists?.map((artist, index) => {
                                return (
                                    <React.Fragment key={artist.id}>
                                        {index !== 0 && (
                                            <span className="mx-1">-</span>
                                        )}
                                        <ArtistLink
                                            artistId={artist.id}
                                            content={artist.name}
                                            view
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </li>
                    )}
                    <li className="breadcrumb-item active">
                        <I18n t="album.view.breadcrumb" />
                    </li>
                </ol>
                <div id="header-observer" ref={headerObserverRef}></div>
                {album?.id && (
                    <div
                        id="header"
                        className={clsx(
                            "d-flex header",
                            isHeaderSticky && "header-sticky"
                        )}
                    >
                        <OverlayTrigger
                            placement="auto"
                            overlay={
                                <Popover id={`popover-positioned-top`}>
                                    <Popover.Content className="bg-black rounded-sm">
                                        <ImageLoader
                                            src={album?.cover}
                                            fallbackSrc={placeholder}
                                            style={{
                                                width: "400px",
                                                height: "400px",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </Popover.Content>
                                </Popover>
                            }
                        >
                            <span>
                                <ImageLoader
                                    src={album?.cover}
                                    fallbackSrc={placeholder}
                                    fallbackClassName="placeholder-img"
                                    className="header-img border"
                                />
                            </span>
                        </OverlayTrigger>
                        <div className="header-data d-flex flex-column justify-content-around flex-grow-1">
                            <h4 className="mb-0">
                                <Tippy
                                    content={I18n.getTranslation(
                                        location,
                                        "album.view.id"
                                    )}
                                    trigger="mouseenter"
                                >
                                    <span>{album.id}</span>
                                </Tippy>
                            </h4>
                            <h2 className="mb-0">
                                <strong>
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "album.view.title"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span>{album?.title}</span>
                                    </Tippy>
                                </strong>
                                {album.subtitle && (
                                    <Tippy
                                        content={I18n.getTranslation(
                                            location,
                                            "album.view.subtitle"
                                        )}
                                        trigger="mouseenter"
                                    >
                                        <span> - {album.subtitle}</span>
                                    </Tippy>
                                )}
                            </h2>
                            <h4 className="mb-0">
                                {album.artists.map((artist, index) => (
                                    <React.Fragment key={artist.id}>
                                        {index !== 0 && (
                                            <span className="mx-2">-</span>
                                        )}
                                        <ArtistLink
                                            artistId={artist.id}
                                            content={artist.name}
                                            view
                                        />
                                    </React.Fragment>
                                ))}
                            </h4>
                            <h4 className="mb-0">
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    {album.format && (
                                        <Tippy
                                            content={I18n.getTranslation(
                                                location,
                                                "album.view.profileInformation.format.head"
                                            )}
                                            trigger="mouseenter"
                                        >
                                            <span>
                                                <I18n
                                                    t={`album.view.profileInformation.format.${album.format.toLowerCase()}`}
                                                />
                                            </span>
                                        </Tippy>
                                    )}
                                    {album.type && (
                                        <>
                                            <span className="mx-2">-</span>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "album.view.profileInformation.type.head"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span>
                                                    <I18n
                                                        t={`album.view.profileInformation.type.${album.type.toLowerCase()}`}
                                                    />
                                                </span>
                                            </Tippy>
                                        </>
                                    )}
                                    {album.release_date && (
                                        <>
                                            <span className="mx-2">-</span>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "album.view.profileInformation.release_date"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span>
                                                    {displayAsString(
                                                        album.release_date,
                                                        locale
                                                    )}
                                                </span>
                                            </Tippy>
                                        </>
                                    )}
                                    {album.label && (
                                        <>
                                            <span className="mx-2">-</span>
                                            <Tippy
                                                content={I18n.getTranslation(
                                                    location,
                                                    "album.view.profileInformation.label"
                                                )}
                                                trigger="mouseenter"
                                            >
                                                <span>{album.label?.name}</span>
                                            </Tippy>
                                        </>
                                    )}
                                    {album.reference &&
                                        album.reference !== "0" && (
                                            <>
                                                <span className="mx-2">-</span>
                                                <Tippy
                                                    content={I18n.getTranslation(
                                                        location,
                                                        "album.view.profileInformation.reference"
                                                    )}
                                                    trigger="mouseenter"
                                                >
                                                    <span>
                                                        <RatingStar
                                                            maxStars={5}
                                                            value={parseFloat(
                                                                album.reference.replace(
                                                                    ",",
                                                                    "."
                                                                )
                                                            )}
                                                        />
                                                    </span>
                                                </Tippy>
                                            </>
                                        )}
                                </div>
                            </h4>
                        </div>
                        {user.hasRight("artist.disco.manage") && (
                            <div
                                className={clsx(
                                    "align-self-center d-flex align-items-center",
                                    isHeaderSticky && "mr-2"
                                )}
                            >
                                <FontAwesomeIcon icon={faEye} fixedWidth />
                                <InputSwitch
                                    checked={false}
                                    onChange={redirectToEdit}
                                />
                                <FontAwesomeIcon icon={faPen} fixedWidth />
                            </div>
                        )}
                    </div>
                )}
                <Tab.Container
                    id="album-view-tab"
                    activeKey={selectedTab}
                    defaultActiveKey="univers"
                    onSelect={onSelectTab}
                    mountOnEnter
                    unmountOnExit
                >
                    <Nav
                        variant="pills"
                        className={clsx(
                            "album-view-nav",
                            isHeaderSticky && "nav-sticky"
                        )}
                        justify
                    >
                        <Nav.Item>
                            <Nav.Link eventKey="univers">
                                <I18n t="album.view.univers" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="profile">
                                <I18n t="album.view.profile" />
                            </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                            <Nav.Link eventKey="cover">
                                <I18n t="album.view.cover" />
                            </Nav.Link>
                        </Nav.Item> */}
                        <Nav.Item>
                            <Nav.Link eventKey="credits">
                                <I18n t="album.view.credits" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="audio-description">
                                <I18n t="recording.view.audio_description" />
                            </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                            <Nav.Link eventKey="review">
                                <I18n t="album.view.review" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="charts">
                                <I18n t="album.view.charts" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="similar">
                                <I18n t="album.view.similar" />
                            </Nav.Link>
                        </Nav.Item> */}
                    </Nav>
                    <Tab.Content
                        ref={containerRef}
                        className={clsx(
                            "album-view-nav-content nav-content overflow-hidden"
                        )}
                        style={{
                            marginTop: isHeaderSticky
                                ? `${containerTopOffset}px`
                                : "1.2rem",
                        }}
                    >
                        <Tab.Pane eventKey="univers" className="nav-pane">
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "album.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "album.view.univers"
                                )}`}</title>
                            </Helmet>
                            <Widget>
                                <Widget.Body>
                                    {album?.id && (
                                        <>
                                            <div className="row">
                                                <div className="col border-right">
                                                    <Format
                                                        format={album.format}
                                                    />
                                                </div>
                                                <div className="col border-right">
                                                    <Type type={album.type} />
                                                </div>
                                                <div className="col border-right">
                                                    <ReleaseDate
                                                        release_date={
                                                            album.release_date
                                                        }
                                                        locale={locale}
                                                    />
                                                </div>
                                                <div className="col border-right">
                                                    <Label
                                                        label={
                                                            album.label?.name
                                                        }
                                                    />
                                                </div>
                                                <div className="col">
                                                    <Genres />
                                                </div>
                                            </div>
                                            <hr className="my-2" />
                                            <div className="row">
                                                <div className="col border-right">
                                                    <Languages />
                                                </div>
                                                {album.translations.length !==
                                                    0 && (
                                                    <div className="col border-right">
                                                        <Translation
                                                            album={album}
                                                        />
                                                    </div>
                                                )}
                                                <div className="col border-right">
                                                    <Featuring />
                                                </div>
                                                <div className="col">
                                                    <Instruments />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </Widget.Body>
                            </Widget>
                            {!Object.values(hasData).every((e) => !e) && (
                                <>
                                    <div className="row">
                                        <div className="col">
                                            <Releases setNoData={setNoData} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <Tracklist />
                                        </div>
                                    </div>
                                </>
                            )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="profile" className="nav-pane">
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "album.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "album.view.profile"
                                )}`}</title>
                            </Helmet>
                            <Profile />
                        </Tab.Pane>
                        {/* <Tab.Pane
                            eventKey="cover"
                            className="nav-pane text-center"
                        >
                            <h1>COMING SOON</h1>
                        </Tab.Pane> */}
                        <Tab.Pane eventKey="credits" className="nav-pane">
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "album.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "album.view.credits"
                                )}`}</title>
                            </Helmet>
                            <Credits />
                        </Tab.Pane>
                        <Tab.Pane
                            eventKey="audio-description"
                            className="nav-pane"
                        >
                            <Helmet>
                                <title>{`${I18n.getTranslation(
                                    location,
                                    "dashboard"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "album.view.breadcrumb"
                                )} - ${I18n.getTranslation(
                                    location,
                                    "recording.view.audio_description"
                                )}`}</title>
                            </Helmet>
                            <AudioDescription />
                        </Tab.Pane>
                        {/* <Tab.Pane
                            eventKey="review"
                            className="nav-pane text-center"
                        >
                            <h1>COMING SOON</h1>
                        </Tab.Pane>
                        <Tab.Pane
                            eventKey="charts"
                            className="nav-pane text-center"
                        >
                            <h1>COMING SOON</h1>
                        </Tab.Pane>
                        <Tab.Pane
                            eventKey="similar"
                            className="nav-pane text-center"
                        >
                            <h1>COMING SOON</h1>
                        </Tab.Pane> */}
                    </Tab.Content>
                </Tab.Container>
            </main>
        </div>
    );
};

export default AlbumView;
