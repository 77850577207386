import { UPLOAD_MAX_SIZE } from "shared/constants/file";

export default {
    dashboard: "Metadata Monitor",
    logo: "Metadata Monitor",
    error_boundary: {
        message: "Oops ! Looks like something went wrong.",
        submessage: "We're working on it",
        back: "Go to home page",
    },
    security: {
        login: {
            title: "Log in",
            username: "Email",
            password: "Password",
            noAccount: "Don't have an account ?",
            create: "Sign up",
            forgot: "Forgotten password ?",
        },
        request: {
            title: "Request",
            username: "Email address",
            message:
                "An email has been sent. It contains a link you must click to reset your password.\n\nIf you don't get an email check your spam folder or try again.",
            button: "Reset password",
            login: "Back to login",
        },
        reset: {
            title: "Change password",
            password: "New password",
            success: "The password has been reset successfully",
            error: "Unable to reset password",
            button: "Change password",
            invalid_password: "Invalid password",
            login: "Back to login",
        },
        register: {
            title: "Register Account",
            subtitle: {
                trial: "Want to try our data? Start a 2 weeks free trial today.",
                client: "As a client, you already have access to the Metadata Monitor. Your account will be verified to provide you with an according access.",
            },
            is_client: "Already client ?",
            email: "Email",
            firstname: "Firstname",
            lastname: "Lastname",
            password: "Password",
            account_type: {
                title: "Account type",
                music_service: "Music service",
                cmo: "CMO",
                label_distributor: "Label / Distributor",
                artist_manager: "Artist / Manager",
            },
            role: {
                title: "Role",
                music_editor: "Music editor",
                marketing: "Marketing",
                catalog_management: "Catalog Management",
                product_management: "Product Management",
                tech: "Tech",
                executive: "Executive",
                other: "Other",
                promotion: "Promotion",
                business_development: "Business development",
                a_and_r: "A&R",
                supply_chain: "Supply chain",
                analytics: "Analytics",
            },
            describe: "Precise",
            language: "Language",
            label: "Label",
            company: "Company",
            artist: "Artist",
            success:
                "Your account has been successfully created. Please validate your email address to finalize your registration and access the Metadata Monitor.",
            error: "Unable to create account",
            button: "Get Started",
            allreadyAccount: "Already have an account ?",
            agreeBefore: "By registering you agree to the Music-Story",
            termsOfService: "Terms of Use",
            agreeAnd: "and",
            privacyPolicy: "Privacy Policy",
            invalid_password: "Invalid password",
        },
        access: {
            denied: "Invalid credentials. Try login with your email.",
            expired: "Account expired",
            lock: "Account locked",
        },
        validate: {
            success:
                "Your registration has been successfully finalized. You can now log in.",
            error: "Unable to validate account",
        },
    },
    user: {
        breadcrumb: "Users",
        manage: {
            breadcrumb: "Management",
            user: {
                head: "Users",
                empty: "No Results",
                id: "Id",
                username: "Name",
                is_client: "Already client ?",
                firstname: "Firstname",
                lastname: "Lastname",
                email: "Email",
                user_roles: "Rights",
                label: "Associated label",
                api_limit_per_day: "API request limit per day",
                api_limit_per_second: "API request limit per second",
                image_server: "Image server",
                author: "Author",
                company: "Company",
                artist: "Artist",
                account_type: {
                    title: "Account type",
                    music_service: "Music service",
                    cmo: "CMO",
                    label_distributor: "Label / Distributor",
                    artist_manager: "Artist / Manager",
                },
                role: {
                    title: "Role",
                    music_editor: "Music editor",
                    marketing: "Marketing",
                    catalog_management: "Catalog Management",
                    product_management: "Product Management",
                    tech: "Tech",
                    executive: "Executive",
                    other: "Other",
                    promotion: "Promotion",
                    business_development: "Business development",
                    a_and_r: "A&R",
                    supply_chain: "Supply chain",
                    analytics: "Analytics",
                },
                describe: "Precise",
                language: "Language",
                last_login: "Last connection",
                expires_at: "Expiration date",
                token: "Token",
                expires: "Expired",
                action: "Action",
                search: "Search...",
            },
            create: {
                title: "Create user",
                success: "User created",
                error: (message) =>
                    `Error while creating this user: ${message}`,
            },
            update: {
                title: "Update user",
                success: "User updated",
                error: (message) =>
                    `Error while updating this user: ${message}`,
            },
            change_password: {
                title: "Change password",
                password: "Password",
                comfirm: "Comfirm password",
                success: "Password updated",
                error: (message) =>
                    `Error while updating the password: ${message}`,
            },
            promote: {
                title: "Promote user",
                alert: {
                    title: "Are you sure ?",
                    text: "To want to promote this user ?",
                },
                success: "User promoted",
                error: (message) =>
                    `Error while promoting this user: ${message}`,
            },
            unpromote: {
                title: "Unpromote user",
                alert: {
                    title: "Are you sure ?",
                    text: "To want to unpromote this user ?",
                },
                success: "User unpromoted",
                error: (message) =>
                    `Error while unpromoting this user: ${message}`,
            },
            lock: {
                title: "Lock user",
                alert: {
                    title: "Are you sure ?",
                    text: "To want to lock this user ?",
                },
                success: "User lockted",
                error: (message) => `Error while locking this user: ${message}`,
            },
            unlock: {
                title: "Unlock user",
                alert: {
                    title: "Are you sure ?",
                    text: "To want to unlock this user ?",
                },
                success: "User unlocked",
                error: (message) =>
                    `Error while unlocking this user: ${message}`,
            },
            generate_token: {
                success: "Token generated",
                error: (message) =>
                    `Error while generating the token: ${message}`,
            },
        },
        role: {
            breadcrumb: "Roles",
            manage: {
                breadcrumb: "Management",
                role: {
                    head: "Roles",
                    empty: "No Results",
                    id: "Id",
                    libelle: "Label",
                    rights: "Rights",
                    action: "Action",
                    search: "Search...",
                },
                create: {
                    title: "Create role",
                    success: "Role created",
                    error: (message) =>
                        `Error while creating this role: ${message}`,
                },
                update: {
                    title: "Update role",
                    success: "Role updated",
                    error: (message) =>
                        `Error while updating this role: ${message}`,
                },
                delete: {
                    title: "Delete role",
                    alert: {
                        title: "Are you sure ?",
                        text: "To want to delete this role ?",
                    },
                    success: "Role deleted",
                    error: (message) =>
                        `Error while deleting this role: ${message}`,
                },
            },
        },
        right: {
            breadcrumb: "Rights",
            manage: {
                breadcrumb: "Management",
                right: {
                    head: "Rights",
                    empty: "No Results",
                    id: "Id",
                    reference: "Reference",
                    libelle: "Label",
                    action: "Action",
                    search: "Search...",
                },
                create: {
                    title: "Create right",
                    success: "Right created",
                    error: (message) =>
                        `Error while creating this right: ${message}`,
                },
                update: {
                    title: "Update right",
                    success: "Right updated",
                    error: (message) =>
                        `Error while updating this right: ${message}`,
                },
                delete: {
                    title: "Delete right",
                    alert: {
                        title: "Are you sure ?",
                        text: "To want to delete this right ?",
                    },
                    success: "Right deleted",
                    error: (message) =>
                        `Error while deleting this right: ${message}`,
                },
            },
        },
    },
    author: {
        breadcrumb: "Authors",
        manage: {
            breadcrumb: "Management",
            author: {
                head: "Authors",
                empty: "No Results",
                id: "Id",
                firstname: "Firstname",
                lastname: "Lastname",
                signature: "Signature",
                action: "Action",
                search: "Search...",
            },
            create: {
                title: "Create author",
                success: "Author created",
                error: (message) =>
                    `Error while creating this author: ${message}`,
            },
            update: {
                title: "Update author",
                success: "Author updated",
                error: (message) =>
                    `Error while updating this author: ${message}`,
            },
            delete: {
                title: "Delete author",
                alert: {
                    title: "Are you sure ?",
                    text: "To want to delete this author ?",
                },
                success: "Author deleted",
                error: (message) =>
                    `Error while deleting this author: ${message}`,
            },
        },
    },
    artist: {
        search: {
            breadcrumb: "List of artists",
            placeholder:
                "ID / ISNI / name / real name / translated name / alias / nickname",
            empty: "No Results",
            init: "Please do a search",
            add: "Add an artist",
            id: "Id",
            name: "Name",
            complement: "Complementary",
            genre: "Genre",
            actions: "Actions",
            mainGenre: "Main genre",
            principalGenre: "Principal genre",
            secondaryGenre: "Secondary genre",
            country: "Country",
            type: "Type",
            gender: "Gender",
            role: "Role",
            reset: "Reset search",
            error: (message) => `Error while searching artist: ${message}`,
            majorGenreGroup: "Main genres",
            otherGenreGroup: "Other genres",
            filters: {
                bio_en: {
                    title: "EN biography",
                    yes: "With EN biography",
                    no: "Without EN biography",
                },
                bio_fr: {
                    title: "FR biography",
                    yes: "With FR biography",
                    no: "Without FR biography",
                },
                bio_de: {
                    title: "DE biography",
                    yes: "With DE biography",
                    no: "Without DE biography",
                },
                bio_pt: {
                    title: "PT biography",
                    yes: "With PT biography",
                    no: "Without PT biography",
                },
                bio_es: {
                    title: "ES biography",
                    yes: "With ES biography",
                    no: "Without ES biography",
                },
                curation: {
                    title: "Curation",
                    yes: "With curation pictures",
                    no: "Without curation pictures",
                },
                getty: {
                    title: "Getty",
                    yes: "With getty pictures",
                    no: "Without getty pictures",
                },
                label: {
                    title: "Label",
                    yes: "With label pictures",
                    no: "Without label pictures",
                },
                editorialized: {
                    title: "Editorialization",
                    yes: "Is editorialized",
                    no: "Is not editorialized",
                },
            },
        },
        view: {
            breadcrumb: "Artist visualization",
            id: "Music-Story ID",
            complementary: "Complementary",
            type: "Type",
            mainGenre: "Main genre",
            country: "Country",
            profile: "Profile",
            univers: "Universe",
            credits: "Credits",
            charts: "Top songs",
            disco: "Discography",
            images: "Images",
            biography: "Biography",
            popularity: "Popularity",
            noData: {
                univers: "No universe available for this artist",
                images: "No images available for this artist",
                disco: "No discography available for this artist",
                credits: "No credits available for this artist",
                charts: "No top songs available for this artist",
                biography: "No biography available for this artist",
                popularity: "No popularity available for this artist",
            },
            profileInformation: {
                head: "Additional information",
                role: "Role",
                gender: "Gender",
                Personne: {
                    born: "Birth date",
                    birth_country: "Country of birth",
                    birth_area: "Area of birth",
                    birth_city: "City of birth",
                    died: "Death date",
                    death_country: "Country of death",
                    death_area: "Area of death",
                    death_city: "City of death",
                },
                Groupe: {
                    born: "Formation date",
                    birth_country: "Country of formation",
                    birth_area: "Area of formation",
                    birth_city: "City of formation",
                    died: "Disband date",
                    death_country: "Country of disband",
                    death_area: "Area of disband",
                    death_city: "City of disband",
                },
                famousFor: "Famous for",
                awards: "Awards",
            },
            profileNames: {
                head: "Names",
                realname: "Real first name last name",
                alias: "Alias",
                names: "Alternate languages",
            },
            profileMembers: {
                head: "Members",
                current: "Current members",
                old: "Former members",
            },
            profileGroup: {
                head: "Bands",
                current: "Current bands",
                old: "Former bands",
            },
            profileExternalLink: {
                head: "External links",
                isni: "ISNI",
                site: "Websites",
            },
            profilePartnerLink: {
                head: "Partner links",
            },
            universGenres: {
                head: "Genre",
                main: "Main",
                principal: "Principal",
                secondary: "Secondary",
                influence: "Influence",
            },
            universRelated: {
                head: "Related artist",
                related: "Related",
                successor: "Successor",
                influence: "Influence",
            },
            universLanguages: {
                head: "Languages",
                principal: "Main language",
                secondary: "Other languages",
            },
            creditsCoPerformer: {
                head: "Co-performances",
            },
            creditsContribution: {
                head: "Contributions",
                description:
                    "Artists for whom the current artist has collaborated (such as: featured on another artist's tracks, composer for another artist, playing any instrument for another artist…)",
            },
            creditsCollaboration: {
                head: "Collaborations",
                description:
                    "Artists who have collaborated with the current artist (such as his composer, his main featurings, …)",
            },
            chartsRecordingCharts: {
                mainCharts: "Official charts",
                allCharts: "All charts",
                cover: "Cover",
                title: "Title",
                star: "Score",
                peak_rank: "Best rank",
                chart: "Chart",
                country: "Country",
                entry_date: "Entry date",
                trend: "Trend",
                year: "Year",
                multi_charts: "Multi-charts",
                multi_country: "Multi-country",
                resetCharts: "Reset filters / sorting",
                resetEditorsPick: "Reset sorting",
                hit: {
                    title: "Hits",
                    description:
                        "A selection of the artist most popular songs from our music editors",
                },
                editorial_pick: {
                    title: "Editor's pick",
                    description:
                        "A selection of noteworthy songs from our music editors",
                },
                empty: "No results",
            },
            discoAlbums: {
                head: "Albums",
                id: "Id",
                cover: "Cover",
                title: "Title",
                type: {
                    head: "Type",
                    live: "Live",
                    remix: "Remix",
                    compilation: "Compilation",
                    karaoke: "Karaoke",
                    ring: "Ring",
                    other: "Other",
                    original: "Original",
                },
                format: {
                    head: "Format",
                    album: "Album",
                    ep: "EP",
                    single: "Single",
                    mixtape: "Mixtape",
                    mcd: "Maxi",
                },
                year: "Year",
                genre: "Genre",
                release_date: "Release date",
                reference: "Rating",
                has_review: "Review",
                has_credits: "Credits",
                has_chart: "Chart",
                complementary: "Related content",
                empty: "No results",
                reset: "Reset filters / sorting",
            },
            imagesGallery: {
                head: "Gallery",
                tags: "Filter by tags",
                empty: "No images in the gallery",
                detail: {
                    head: "Detail",
                    show: "Show original picture",
                    source: "Source",
                    copyright: "Copyright",
                    tags: "Tags",
                    metas: "Meta",
                },
            },
            biographyGallery: {
                writer: "Writer",
                updated_at: "Updated on",
                words: "Words",
                signs: "Signs",
                biography: "Biography",
                empty: "No biography",
                licence: {
                    title: "Courtesy of CD Journal",
                    subtitle:
                        "This content may not be used without a specific license delivered by CD Journal",
                },
            },
            popularityOverview: {
                head: "Overview",
                global: {
                    title: "Global",
                    recordings: "Titles in charts:",
                    description:
                        "The popularity score of an artist is based on charts data. It is the result of the artist's titles popularity and is represented in two forms: the rank of the artist (within our ranking of popular artists) and the popularity rate (in %, relative to the highest popularity score).",
                },
                gold: {
                    title: "Catalog",
                    recordings: "Titles in catalog charts:",
                    description:
                        "The Catalog popularity of an artist is based on his presence in the charts more than 18 months ago.\nThe Catalog popularity only uses Billboard Hot 100, UK Official Singles Chart & Offizielle Deutsche Charts data at the moment.",
                },
                current: {
                    title: "Current",
                    recordings: "Titles in current charts:",
                    description:
                        "The current popularity of an artist is based on his presence in the charts during the last 18 months.",
                },
                type: {
                    label: "Type:",
                    gold: "Catalog",
                    current: "Current",
                },
                peak: "Peak:",
            },
            popularityHistogram: {
                head: "Popularity over time",
                tabs: {
                    gold: {
                        title: "Catalog",
                        description:
                            "The Catalog popularity of an artist is based on his presence in the charts more than 18 months ago.\nThe Catalog popularity only uses Billboard Hot 100, UK Official Singles Chart & Offizielle Deutsche Charts data at the moment.",
                        header: "Catalog popularity is solely based on the US, UK and DE official charts data, more countries are being integrated.",
                    },
                    current: {
                        title: "Current",
                        description:
                            "The current popularity of an artist is based on his presence in the charts during the last 18 months.",
                    },
                },
                gold: "Catalog popularity",
                current: "Current popularity",
                popover: {
                    noData: "No data",
                    popularity: "Artist's popularity:",
                    mostPopularTitle: "Most popular title:",
                    bestRank: "Best rank:",
                    bestCountry: "Best country:",
                    charts: (n) => `Based on ${n} charts`,
                },
            },
            popularityMap: {
                head: "Popularity per country",
                tabs: {
                    gold: {
                        title: "Catalog",
                        description:
                            "The Catalog popularity of an artist is based on his presence in the charts more than 18 months ago.\nThe Catalog popularity only uses the Billboard Hot 100, UK Official Singles Chart & Offizielle Deutsche Charts data at the moment.",
                    },
                    current: {
                        title: "Current",
                        description:
                            "The current popularity of an artist is based on his presence in the charts during the last 18 months.",
                    },
                },
                popover: {
                    noData: "No data",
                    popularity: "Artist's popularity:",
                    mostPopularTitle: "Most popular title:",
                    bestRank: "Best rank:",
                    bestMonth: "Month of best rank:",
                    weeksInCharts: "Weeks in charts:",
                    charts: (n) => `Based on ${n} charts`,
                },
            },
        },
        create: {
            breadcrumb: "Create",
            placeholder:
                "ID / ISNI / name / real name / translated name / alias / nickname",
            empty: "No Results",
            init: "Please do a search",
            source: "Source",
            name: "Name",
            complement: "Complementary",
            type: "Type",
            gender: "Gender",
            country: "Country",
            real_name: "Real LN-FN",
            alias: "Alias",
            member: "Members",
            band: "Bands",
            site: "Websites",
            social: "Social",
            actions: "Actions",
            create: {
                title: "Create artist",
                alert: {
                    title: "Are you sure ?",
                    text: "To want to create this artist ?",
                },
                success: "Artist created",
                error: (message) =>
                    `Error while create this artist: ${message}`,
            },
        },
        management: {
            breadcrumb: "Modifying artists",
            profile: "Profile",
            editorial: "Editorial",
            biography: "Biography",
            discography: "Discography",
            curation: "Curation",
            getty: "Getty",
            labelPicture: "Label Picture",
            matching: "Matching",
            charts: "Charts",
            tracking: "Tracking",
            action: {
                complete: {
                    message: "Creation of the Artist in progress",
                    button: "Complete the creation",
                    alert: {
                        title: "Are you sure ?",
                        text: "To want to complete the creation of this artist ?",
                    },
                    success: "Creation completed",
                    error: (message) =>
                        `Error when creating the artist: ${message}`,
                },
            },
            profileInformation: {
                head: "Main information",
                type: "Type",
                name: "Name",
                complement: "Complementary",
                realname: "Real first name last name",
                gender: "Gender",
                country: "Country",
                isni: "ISNI",
                isniSearch: {
                    tooltip: "Search ISNI",
                    head: "ISNI Search",
                    placeholder: "Search by name or ISNI",
                    error: (message) => `Error while searching: ${message}`,
                    isni: "ISNI",
                    name: "Name",
                    select: "Select the ISNI",
                    empty: "No results",
                },
                reset: {
                    title: "Reset the form",
                },
                update: {
                    title: "Update main information",
                    success: "Information updated",
                    error: (message) =>
                        `Error while update this information: ${message}`,
                },
            },
            names: {
                head: "Alternate languages",
                empty: "No languages",
                name: "Name",
                language: "Language",
                reset: {
                    title: "Reset the form",
                },
                update: {
                    title: "Update alternate languages",
                    success: "Alternate languages updated",
                    error: (message) =>
                        `Error while update this alternate languages: ${message}`,
                },
            },
            alias: {
                head: "Alias",
                empty: "No alias",
                alias: "Alias",
                type: "Type",
                reset: {
                    title: "Reset the form",
                },
                update: {
                    title: "Update alias",
                    success: "Alias updated",
                    error: (message) =>
                        `Error while update this alias: ${message}`,
                },
            },
            member: {
                head: "Members",
                empty: "No members",
                artist: "Name",
                start_date: "Start",
                end_date: "End",
                role: "Role",
                reset: {
                    title: "Reset the form",
                },
                update: {
                    title: "Update members",
                    success: "Members updated",
                    error: (message) =>
                        `Error while update the members: ${message}`,
                },
            },
            group: {
                head: "Bands",
                empty: "No bands",
                artist: "Name",
                start_date: "Start",
                end_date: "End",
                role: "Role",
                reset: {
                    title: "Reset the form",
                },
                update: {
                    title: "Update bands",
                    success: "Bands updated",
                    error: (message) =>
                        `Error while update the bands: ${message}`,
                },
            },
            genre: {
                head: "Genre",
                empty: "No genre",
                genre: "Name",
                type: "Type",
                reset: {
                    title: "Reset the form",
                },
                update: {
                    title: "Update genres",
                    success: "Genres updated",
                    error: (message) =>
                        `Error while update the genres: ${message}`,
                },
            },
            role: {
                head: "Role",
                empty: "No role",
                role: "Role",
                main: "Main",
                reset: {
                    title: "Reset the form",
                },
                update: {
                    title: "Update roles",
                    success: "Roles updated",
                    error: (message) =>
                        `Error while update the roles: ${message}`,
                },
            },
            social: {
                head: "Social",
                reset: {
                    title: "Reset the form",
                },
                update: {
                    title: "Update socials",
                    success: "Socials updated",
                    error: (message) =>
                        `Error while update the socials: ${message}`,
                },
                duplicates: {
                    tooltip: "There are other artist with the same social URL",
                    head: "Duplicate socials",
                    body: "The following artists have the same social URL:",
                },
            },
            site: {
                head: "Websites",
                empty: "No website",
                url: "Url",
                reset: {
                    title: "Reset the form",
                },
                update: {
                    title: "Update websites",
                    success: "Websites updated",
                    error: (message) =>
                        `Error while update the websites: ${message}`,
                },
            },
            editorialInformation: {
                head: "Main information",
                Personne: {
                    born: "Birth date",
                    birth_country: "Country of birth",
                    birth_area: "Area of birth",
                    birth_city: "City of birth",
                    died: "Death date",
                    death_country: "Country of death",
                    death_area: "Area of death",
                    death_city: "City of death",
                },
                Groupe: {
                    born: "Formation date",
                    debut_date: "Debut date",
                    birth_country: "Country of formation",
                    birth_area: "Area of formation",
                    birth_city: "City of formation",
                    activity_period: "Activity periods",
                    activity_period_start: "Start date",
                    activity_period_end: "End date",
                    died: "Disband date",
                    death_country: "Country of disband",
                    death_area: "Area of disband",
                    death_city: "City of disband",
                },
                reset: {
                    title: "Reset the form",
                },
                update: {
                    title: "Update main information",
                    success: "Information updated",
                    error: (message) =>
                        `Error while update this information: ${message}`,
                },
            },
            highlights: {
                head: "Highlights",
                famous_for: "Famous for",
                awards: "Awards",
                reset: {
                    title: "Reset the form",
                },
                update: {
                    title: "Update highlights",
                    success: "Highlights updated",
                    error: (message) =>
                        `Error while update the highlights: ${message}`,
                },
            },
            collaboration: {
                head: "Collaborations",
                empty: "No collaborations",
                artist: "Name",
                start_date: "Start",
                end_date: "End",
                role: "Role",
                reset: {
                    title: "Reset the form",
                },
                update: {
                    title: "Update collaborations",
                    success: "Collaborations updated",
                    error: (message) =>
                        `Error while update the collaborations: ${message}`,
                },
            },
            contribution: {
                head: "Contributions",
                empty: "No contributions",
                artist: "Name",
                start_date: "Start",
                end_date: "End",
                role: "Role",
                reset: {
                    title: "Reset the form",
                },
                update: {
                    title: "Update contributions",
                    success: "Contributions updated",
                    error: (message) =>
                        `Error while update the contributions: ${message}`,
                },
            },
            related: {
                head: "Related artist",
                empty: "No related artist",
                artist: "Name",
                type: "Type",
                reset: {
                    title: "Reset the form",
                },
                update: {
                    title: "Update related artist",
                    success: "Related artist updated",
                    error: (message) =>
                        `Error while update the related artist: ${message}`,
                },
            },
            bio: {
                head: {
                    FR: "Music Story FR",
                    EN: "Music Story EN",
                    DE: "Music Story DE",
                    PT: "Music Story PT",
                    JA: "Music Story JA",
                    ES: "Music Story ES",
                },
                is_reference: "Reference biography",
                to_translate: () => (
                    <>
                        On-going process.
                        <br />
                        This biography is translated and updated automatically
                        from the corresponding reference biography. No action
                        required.
                    </>
                ),
                author: "Writer",
                archive: "Archive",
                cd_journal: "CD Journal",
                reset: {
                    title: "Reset the biography",
                },
                update: {
                    title: "Update biography",
                    success: "Biography updated",
                    alert: {
                        title: "Are you sure ?",
                        text: "You are about to modify a biography that has been translated and will no longer be translated after modification.",
                    },
                    error: (message) =>
                        `Error while update the biography: ${message}`,
                },
                translation: {
                    placeholder: "Please select a language",
                    alert: {
                        title: "Are you sure ?",
                        text: "You want to replace this biography ?",
                    },
                },
            },
            wikipedia: {
                head: "Wikipedia",
                empty: "No wikipedia",
                url: "Url",
                reset: {
                    title: "Reset the form",
                },
                update: {
                    title: "Update wikpedia",
                    success: "Wikipedia updated",
                    error: (message) =>
                        `Error while update the wikipedia: ${message}`,
                },
                names: {
                    head: 'Add wikipedia names in "Alternate languages"',
                    lang: "Language",
                    name: "Name",
                },
            },
            disco: {
                albums: {
                    head: "Albums",
                    search: "ID / Title",
                    empty: "No Results",
                    id: "Id",
                    title: "Title",
                    type: {
                        head: "Type",
                        live: "Live",
                        remix: "Remix",
                        compilation: "Compilation",
                        karaoke: "Karaoke",
                        ring: "Ring",
                        other: "Other",
                        original: "Original",
                    },
                    format: {
                        head: "For.",
                        title: "Format",
                        album: "Album",
                        ep: "EP",
                        single: "Single",
                        mixtape: "Mixtape",
                        mcd: "Maxi",
                    },
                    genre: "Genre",
                    release_date: {
                        head: "Date",
                        title: "Release date",
                    },
                    cover: {
                        head: "Cov.",
                        title: "Cover art",
                    },
                },
                releases: {
                    head: "Releases",
                    search: "ID / Barcode",
                    empty: "No Results",
                    id: "Id",
                    barcode: {
                        head: "Barcode",
                        title: "Barcode",
                    },
                    commentary: "Commentary",
                    support: "Support",
                    release_date: {
                        head: "Date",
                        title: "Release date",
                    },
                },
                sandbox: {
                    head: "Sandbox",
                    search: {
                        input: "ID / Title / Barcode",
                        title: "Search in all discographies",
                        result: (query) => `Search result for "${query}"`,
                        reset: "Reset search",
                    },
                    empty: "No Results",
                    partner: "Partner",
                    id: "Id",
                    barcode: "Barcode",
                    title: "Title",
                    nb_track: "Tracks nb",
                    actions: "Actions",
                    view_product: "View product",
                    compare_tracklists: "Compare tracklists",
                    view_matchings: "View matchings",
                    popover: {
                        id: "ID:",
                        title: "Title:",
                        artist: "Artist:",
                        barcode: "Barcode:",
                        label: "Label:",
                        release_date: "Release date:",
                        cover_art_error: "Failed to load cover art",
                    },
                    matchings: {
                        head: "Matchings",
                        body: "This product is matched with the following discographies:",
                        artists: "Artist(s)",
                        album: "Album",
                        release: "Release",
                        close: "Close",
                    },
                    tracklistComparison: {
                        head: "Tracklist comparison",
                        tracks: {
                            head: "MusicStory tracklist",
                            empty: "No Results",
                            no_cd: "D",
                            no_piste: "T",
                            isrc: "Isrc",
                            title: "Title",
                            subtitle: "Subtitle",
                            duration: "Dur.",
                            hit: "Hit",
                            discovery: {
                                head: "EP",
                                title: "Editor's Pick",
                            },
                        },
                        partnerTracks: {
                            head: "Partner tracklist",
                            empty: "No Results",
                            no_cd: "D",
                            no_piste: "T",
                            isrc: "Isrc",
                            title: "Title",
                            duration: "Dur.",
                        },
                        replace: {
                            title: "Replace MusicStory tracklist",
                            alert: {
                                title: "Are you sure ?",
                                text: "You want to replace this release's tracklist ?",
                            },
                            success: "The tracklist has been replaced",
                            error: (message) =>
                                `Error while replacing the tracklist: ${message}`,
                            warningIsrc: {
                                title: "Are you sure ?",
                                text: "The attempt to replace this release's tracklist raised the following warnings: recordings with the same ISRC were found",
                            },
                        },
                    },
                    copyProduct: {
                        title: "Add product",
                        head: "Add product",
                        copyAsAlbum: {
                            title: "Add as a new album",
                            created:
                                "The product has been added as a new album",
                            error: (message) =>
                                `Error when adding product as an album: ${message}`,
                            warningBarcode: {
                                title: "Are you sure ?",
                                text: (message) =>
                                    `The attempt to add this album raised the following warnings: ${message}`,
                            },
                            warningIsrc: {
                                title: "Are you sure ?",
                                text: "The attempt to add this album raised the following warnings: recordings with the same ISRC were found",
                            },
                        },
                        copyAsRelease: {
                            title: "Add as a new release of the current album",
                            created:
                                "The product has been added as a new release",
                            matched:
                                "The product has been matched to an existing release",
                            error: (message) =>
                                `Error while adding or matching product as a release: ${message}`,
                            warningBarcode: {
                                title: "Are you sure ?",
                                text: (message) =>
                                    `The attempt to add this release raised the following warnings: ${message}`,
                            },
                            warningIsrc: {
                                title: "Are you sure ?",
                                text: "The attempt to add this release raised the following warnings: recordings with the same ISRC were found",
                            },
                        },
                    },
                    pagination: ({ from, to, size }) => `${size} products`,
                    filters: {
                        partners: "Partners",
                        status: "Status",
                    },
                    status: {
                        MATCHED_IN_CURRENT_DISCOGRAPHY:
                            "Matched in the current discography",
                        MATCHED_IN_OTHER_DISCOGRAPHY:
                            "Matched in another discography",
                        NOT_MATCHED_COMPLETE: "Not matched, complete",
                        NOT_MATCHED_INCOMPLETE: "Not matched, incomplete",
                    },
                },
                tracks: {
                    head: "Tracks",
                    search: "ID / Isrc / Title",
                    empty: "No Results",
                    no_cd: {
                        head: "D",
                        title: "Disc number",
                    },
                    no_piste: {
                        head: "T",
                        title: "Track number",
                    },
                    id: "Id ",
                    isrc: "Isrc",
                    title: "Title",
                    subtitle: {
                        head: "Subtitle",
                        title: "Subtitle",
                    },
                    duration: {
                        head: "Dur.",
                        title: "Duration",
                    },
                },
                matching: {
                    partner: "Affiliate",
                    id: "Id",
                    actions: "Actions",
                    view: "See the track",
                    delete: "Delete",
                },
                objects: {
                    album: {
                        tabTitle: "Album",
                        basic: {
                            head: "Basic data",
                            artist: "Artist",
                            title: "Title",
                            subtitle: "Subtitle",
                            type: "Type",
                            format: "Format",
                            label: "Label",
                            publisher: "Publisher",
                            release_date: "Release date",
                            genre: "Genre",
                            reference: "Rate",
                            typeOptions: {
                                live: "Live",
                                remix: "Remix",
                                compilation: "Compilation",
                                karaoke: "Karaoke",
                                ring: "Ring",
                                other: "Other",
                                original: "Original",
                            },
                            formatOptions: {
                                album: "Album",
                                ep: "EP",
                                single: "Single",
                                mixtape: "Mixtape",
                                mcd: "Maxi",
                            },
                            reset: {
                                title: "Reset the form",
                            },
                            create: {
                                title: "Create album",
                                success: "Album created",
                                error: (message) =>
                                    `Error while create this album: ${message}`,
                            },
                            update: {
                                title: "Update album",
                                success: "Album updated",
                                error: (message) =>
                                    `Error while update this album: ${message}`,
                            },
                            delete: {
                                title: "Delete album",
                                hasReleases:
                                    "Album still has one or more edition(s)",
                                hasReview: "Album still has a review",
                                alert: {
                                    title: "Are you sure ?",
                                    text: "To want to delete this album ?",
                                },
                                warning: {
                                    title: "Are you sure ?",
                                    text: (messages) =>
                                        `Warning while delete this album: \n\n ${messages.map(
                                            (message) => {
                                                return `- ${message} \n`;
                                            }
                                        )}`,
                                },
                                success: "Album deleted",
                                error: (message) =>
                                    `Error while delete this album: ${message}`,
                            },
                            transfer: {
                                title: "Transfer album",
                                success: ({ artist, album }) =>
                                    `Transfer album "${album}" to the artist "${artist}" with success`,
                                error: (message) =>
                                    `Error while transfer this album: ${message}`,
                            },
                        },
                        artist: {
                            head: "Artists",
                            main: "Main",
                            principal: "Principal",
                            reset: {
                                title: "Reset the form",
                            },
                            update: {
                                title: "Update artists",
                                success: "Artists updated",
                                error: (message) =>
                                    `Error while update this artists: ${message}`,
                            },
                        },
                        cover: {
                            head: "Cover",
                            musicstory: "Music-Story",
                            partner: "Partners",
                            url: "Cover url",
                            ean: "Barcode",
                            create: {
                                title: "Create cover",
                                success: "Cover created",
                                error: (message) =>
                                    `Error while creating this cover: ${message}`,
                            },
                            update: {
                                title: "Update cover",
                                success: "Cover updated",
                                error: (message) =>
                                    `Error while update the cover: ${message}`,
                            },
                            automatic: {
                                success:
                                    "Cover art has been added automatically",
                                error: (message) =>
                                    `Error during automatic addition of the cover art : ${message}`,
                            },
                        },
                        review: {
                            head: "Review",
                            author: "Editor",
                            reset: {
                                title: "Reset the review",
                            },
                            update: {
                                title: "Update review",
                                success: "Review updated",
                                error: (message) =>
                                    `Error while update the review: ${message}`,
                            },
                        },
                        translations: {
                            head: "Translations",
                            language: "Language",
                            title: "Title",
                            subtitle: "Subtitle",
                            languagePlaceholder: "Add a language",
                            reset: {
                                title: "Reset the form",
                            },
                            update: {
                                title: "Update the translations",
                                success: "Translations updated",
                                error: (message) =>
                                    `Error when updating the translations: ${message}`,
                            },
                        },
                    },
                    release: {
                        tabTitle: "Release",
                        basic: {
                            head: "Basic data",
                            barcode: "Barcode",
                            release_date: "Release date",
                            editor: "Editor",
                            support: "Support",
                            commentary: "Commentary",
                            reference: "Reference",
                            reset: {
                                title: "Reset the form",
                            },
                            create: {
                                title: "Create release",
                                success: "Release created",
                                error: (message) =>
                                    `Error while creating this release: ${message}`,
                                warning: {
                                    title: "Are you sure ?",
                                    text: (message) =>
                                        `The attempt to create this release raised the following warnings: ${message}`,
                                },
                            },
                            update: {
                                title: "Update release",
                                success: "Release updated",
                                error: (message) =>
                                    `Error while updating this release: ${message}`,
                            },
                            delete: {
                                title: "Delete release",
                                alert: {
                                    title: "Are you sure ?",
                                    text: "To want to delete this release ?",
                                },
                                warning: {
                                    title: "Are you sure ?",
                                    text: (messages) =>
                                        `The attempt to delete this release raised the following warnings: \n\n ${messages.map(
                                            (message) => {
                                                return `- ${message} \n`;
                                            }
                                        )}`,
                                },
                                success: "Release deleted",
                                error: (message) =>
                                    `Error while deleting this release: ${message}`,
                            },
                            transfer: {
                                title: "Transfer release",
                                artist: "Artist",
                                album: "Album",
                                success: ({ artist, album, releaseId }) =>
                                    `Transfer release "${releaseId}" on album "${album}" of artist "${artist}" with success`,
                                error: (message) =>
                                    `Error while transferring this album: ${message}`,
                                same_album_error:
                                    "The album should not be the one currently linked to the release",
                            },
                        },
                        matching: {
                            head: "Partners",
                            empty: "No Results",
                            delete: {
                                title: "Delete matching",
                                alert: {
                                    title: "Are you sure ?",
                                    text: "To want to delete this matching?",
                                },
                                success: "Deleted matching",
                                error: (message) =>
                                    `Error when deleting the matching: ${message}`,
                            },
                        },
                        tracklist: {
                            head: "Tracklist",
                            tracks: {
                                empty: "No Results",
                                no_cd: "D",
                                no_piste: "T",
                                isrc: "Isrc",
                                title: "Title",
                                subtitle: "Subtitle",
                                duration: "Dur.",
                                hit: "Hit",
                                discovery: {
                                    head: "EP",
                                    title: "Editor's Pick",
                                },
                                validation: {
                                    no_cd: "The disc number should be a positive integer",
                                    no_piste:
                                        "The track number should be a positive integer",
                                    title: "The title cannot be empty",
                                    duration:
                                        "The duration should have the format HH:MM:SS",
                                    duplicatePosition:
                                        "There is another track with the same disc and track number",
                                    duplicateIsrc:
                                        "There is another track with the same ISRC",
                                },
                            },
                            reset: {
                                title: "Reset tracks",
                            },
                            update: {
                                title: "Update tracks",
                                success: "The tracks have been modified",
                                error: (message) =>
                                    `Error while modifying the tracks: ${message}`,
                                warningIsrc: {
                                    title: "Are you sure ?",
                                    text: "The attempt to update this tracklist raised the following warnings: recordings with the same ISRC were found",
                                },
                            },
                        },
                    },
                    track: {
                        tabTitle: "Track",
                        basic: {
                            head: "Basic data",
                            title: "Title",
                            subtitle: "Subtitle",
                            isrc: "ISRC",
                            duration: "Duration",
                            no_cd: "Num. CD",
                            no_piste: "Num. track",
                            hit: "Hit",
                            discovery: "Editor's Pick",
                            language: "Sung language",
                            dialect: "Dialect",
                            genre: "Genre",
                            reset: {
                                title: "Reset the form",
                            },
                            create: {
                                title: "Add a track",
                                success: "Track added",
                                error: (message) =>
                                    `Error when adding the track: ${message}`,
                                warningIsrc: {
                                    title: "Are you sure ?",
                                    text: "The attempt to add this track raised the following warnings: recording with the same ISRC were found",
                                },
                            },
                            update: {
                                title: "Updating a track",
                                success: "Track updated",
                                error: (message) =>
                                    `Error when updating the track: ${message}`,
                                warningIsrc: {
                                    title: "Are you sure ?",
                                    text: "The attempt to update this track raised the following warnings: recording with the same ISRC were found",
                                },
                            },
                            delete: {
                                title: "Delete a track",
                                alert: {
                                    title: "Are you sure ?",
                                    text: "To want to remove this track ?",
                                },
                                success: "Deleted track",
                                error: (message) =>
                                    `Error when deleting the track: ${message}`,
                            },
                        },
                        translations: {
                            head: "Translations",
                            language: "Language",
                            title: "Title",
                            subtitle: "Subtitle",
                            languagePlaceholder: "Add a language",
                            reset: {
                                title: "Reset the form",
                            },
                            update: {
                                title: "Update the translations",
                                success: "Translations updated",
                                error: (message) =>
                                    `Error when updating the translations: ${message}`,
                            },
                        },
                        matching: {
                            head: "Partners",
                            empty: "No Results",
                            delete: {
                                title: "Delete matching",
                                alert: {
                                    title: "Are you sure ?",
                                    text: "To want to delete this matching?",
                                },
                                success: "Deleted matching",
                                error: (message) =>
                                    `Error when deleting the matching: ${message}`,
                            },
                        },
                    },
                },
            },
            match: {
                empty: "No matchings",
                id: "ID",
                name: "Name",
                type_match: "Type",
                status: "Status",
                pending: "Matching in progress...",
                success: "Matching completed",
            },
            imageCuration: {
                head: "Gallery",
                empty: "No images in the gallery",
                width: "Width",
                height: "Height",
                created_at: "Creation date",
                copyright: "Copyright",
                status: "Status",
                edit: "Edit",
                edit_image: "Edit image information",
                delete: "Delete",
                delete_image: "Delete image",
            },
            imageGetty: {
                gallery: {
                    head: "Gallery",
                    empty: "No images in the gallery",
                    width: "Width",
                    height: "Height",
                    created_at: "Creation date",
                    copyright: "Copyright",
                    status: "Status",
                    edit: "Edit",
                    edit_image: "Edit image information",
                    delete: "Delete",
                    delete_image: "Delete image",
                },
            },
            imageLabel: {
                head: "Gallery",
                empty: "No images in the gallery",
                width: "Width",
                height: "Height",
                created_at: "Creation date",
                copyright: "Copyright",
                status: "Status",
            },
            chartsList: {
                head: "Charts",
                empty: "No Results",
                source: "Source",
                area: "Area",
                format: "Format",
                country: "Country",
                origin: "Origin",
                date_out: "Date out",
                track_title: "Title",
                title: "Chart name",
                country: "Country",
                date_in: "Date in",
                date_out: "Date out",
                nb_week: "Nb week",
                rank_in: "Rank in",
                max_rank: "Max rank",
                rank_out: "Rank out",
            },
            trackingPlanning: {
                head: "Plannings history",
                empty: "No Results",
                date: "Date",
                title: "Title",
                album: "Album",
                category: "Category",
                reason: "Reason",
                classification: "Classification",
                tasks: "Tasks",
                status: {
                    TODO: "To do",
                    COMPLETED: "Completed",
                    TO_BE_CORRECTED: "To be corrected",
                    VALIDATED_WITHOUT_CONTROL: "Validated without control",
                    VERIFIED_AND_VALIDATED: "Verified and validated",
                    ERROR: "In error",
                },
            },
            trackingPicture: {
                head: "Picture works history",
                empty: "No Results",
                picture: "Picture",
                date: "Date",
                username: "User",
                action: "Action",
                curation_cms_artist_image_add_completed:
                    "Curation picture added",
                curation_cms_artist_image_update_completed:
                    "Curation picture updated",
                curation_cms_artist_image_main_completed:
                    "Curation picture set as main",
                curation_cms_artist_image_delete_completed:
                    "Curation picture deleted",
                label_cms_artist_picture_status_change_completed:
                    "Label picture status changed",
                getty_cms_artist_picture_add_completed: "Getty picture added",
                label_cms_artist_picture_add_completed: "Label picture added",
                getty_cms_artist_picture_delete_completed:
                    "Getty picture deleted",
                label_cms_artist_picture_delete_completed:
                    "Label picture deleted",
                getty_cms_artist_picture_check_completed: "Pictures checked",
                getty_move_picture_completed: "Getty pictures moved",
                label_move_picture_completed: "Label pictures moved",
            },
            trackingLog: {
                head: "Logs",
                empty: "No Results",
                date: "Date",
                username: "User",
                action: "Action",
                cms_artist_alias_create_completed: "Alias created",
                cms_artist_alias_update_completed: "Alias updated",
                cms_artist_alias_delete_completed: "Alias deleted",
                cms_artist_biography_create_completed: "Biography created",
                cms_artist_biography_update_completed: "Biography updated",
                cms_artist_biography_translate_completed:
                    "Biography translated",
                cms_artist_collaboration_create_completed:
                    "Collaboration created",
                cms_artist_collaboration_delete_completed:
                    "Collaboration deleted",
                cms_artist_collaboration_update_completed:
                    "Collaboration updated",
                cms_artist_contribution_create_completed:
                    "Contribution created",
                cms_artist_contribution_delete_completed:
                    "Contribution deleted",
                cms_artist_contribution_update_completed:
                    "Contribution updated",
                cms_artist_create_completed: "Artist created",
                cms_artist_update_completed: "Artist updated",
                cms_artist_delete_completed: "Artist deleted",
                cms_artist_genre_create_completed: "Genre created",
                cms_artist_genre_delete_completed: "Genre deleted",
                cms_artist_genre_update_completed: "Genre updated",
                cms_artist_group_create_completed: "Group created",
                cms_artist_group_delete_completed: "Group deleted",
                cms_artist_group_update_completed: "Group updated",
                cms_artist_member_create_completed: "Member created",
                cms_artist_member_delete_completed: "Member deleted",
                cms_artist_member_update_completed: "Member updated",
                cms_artist_name_create_completed: "Name created",
                cms_artist_name_update_completed: "Name updated",
                cms_artist_name_delete_completed: "Name deleted",
                cms_artist_related_create_completed: "Related artist created",
                cms_artist_related_delete_completed: "Related artist deleted",
                cms_artist_related_update_completed: "Related artist updated",
                cms_artist_role_update_completed: "Role updated",
                cms_artist_site_create_completed: "Site created",
                cms_artist_site_delete_completed: "Site deleted",
                cms_artist_site_update_completed: "Site updated",
                cms_artist_social_create_completed: "Social created",
                cms_artist_social_delete_completed: "Social deleted",
                cms_artist_social_update_completed: "Social updated",
                cms_artist_wikipedia_create_completed: "Wikipedia created",
                cms_artist_wikipedia_update_completed: "Wikipedia updated",
            },
        },
        delete: {
            breadcrumb: "Artist deletion",
            empty: "Empty",
            object: "Object",
            nb: "Valid",
            url: "Link",
            album: "Album",
            alias: "Alias",
            bio: "Music Story",
            collab: "Collaboration and contribution",
            member_group: "Member and group",
            picture_curation: "Photo of curation",
            picture_right: "Photo with right",
            plannings: "Plannings",
            related1: "Has associates",
            related2: "Associated to",
            site: "Websites",
            social: "Social",
            wiki: "Wikipedia",
            delete: {
                title: "Artist deletion",
                alert: {
                    title: "Are you sure ?",
                    text: "Attention, important objects are always related to the artist. The deletion is irreversible, are you sure you want to continue?",
                },
                success: "Artist successfully deleted",
                error: (message) =>
                    `Error while deleting this artist: ${message}`,
            },
        },
    },
    album: {
        view: {
            breadcrumb: "Album visualization",
            id: "Music-Story ID",
            title: "Title",
            subtitle: "Subtitle",
            genre: "Genre",
            profile: "Recording explorer",
            univers: "Album",
            cover: "Cover",
            credits: "Credits",
            review: "Review",
            charts: "Charts",
            similar: "Similar album",
            noData: {
                profile: "No tracklist available for this album",
                credits: "No credits available for this album",
            },
            profileInformation: {
                head: "Detailed Information",
                type: {
                    head: "Type",
                    live: "Live",
                    remix: "Remix",
                    compilation: "Compilation",
                    karaoke: "Karaoke",
                    ring: "Ring",
                    other: "Other",
                    original: "Original",
                },
                format: {
                    head: "Format",
                    album: "Album",
                    ep: "EP",
                    single: "Single",
                    mixtape: "Mixtape",
                    mcd: "Maxi",
                },
                label: "Label",
                release_date: "Original release date",
                performers: "Performers",
                featurings: "Featurings",
                translations: "Translation",
                reference: "Rating",
            },
            profileReleases: {
                head: "Releases",
                subtitle: "Click on a release to display the related ISRCs",
                description:
                    "List of Releases for the Album. Click on a release to display the associated ISRCs. The selected release is highlighted in blue and the associated ISRCs are highlighted in yellow.",
                ean: "Barcode",
                id: "Id",
                reference: "Reference",
                nb_cd: "Nb CD",
                nb_track: "Nb Track",
                release_date: "Release date",
                commentary: "Commentary",
                support: {
                    head: "Support",
                    ep: "EP",
                    num: "Digital",
                    dvd: "DVD",
                    lp: "LP",
                    "2cd": "2CD",
                    k7: "K7",
                    cd: "CD",
                },
                cline: "CLINE",
                pline: "PLINE",
                links: "Links",
                empty: "No Results",
            },
            profileIsrcs: {
                head: "ISRCs",
                subtitle: "Click on an ISRC to display the related releases",
                description:
                    "List of the recordings related to the album. Click on an ISRC to display the releases it is part of. The selected ISRC is highlighted in blue and the associated releases are highlighted in yellow.",
                isrc: "ISRC",
                id: "Id",
                title: "Title",
                duration: "Duration",
                complementary: "Related content",
                has_credits: "Credits",
                has_lyric: "Lyrics",
                has_audio: "Audio description",
                links: "Links",
                empty: "No Results",
            },
            profileTracklist: {
                head: "Tracklist",
                release_head: (id) =>
                    `Tracklist corresponding to the selected "${id}" release`,
                isrc_head: (isrc) =>
                    `Tracks corresponding to the selected "${isrc}" ISRC`,
                id_release: "Release ID",
                ean: "Barcode",
                id_track: "Track ID",
                no_cd: "Disc number",
                no_piste: "Track number",
                id_recording: "Recording ID",
                title: "Title",
                subtitle: "Subtitle",
                isrc: "ISRC",
                duration: "Duration",
                hit: "Hit score",
                editorial_pick: "Editor's pick",
                empty: "No Results",
            },
            universGenres: {
                head: "Genre",
                principal: "Principal",
                secondary: "Secondary",
            },
            universLanguages: {
                head: "Languages",
                principal: "Main language",
                secondary: "Other languages",
            },
            universInstruments: {
                head: "Instruments",
            },
            universAudioDescription: {
                head: "Audio description",
                licence: "Descriptors calculated with Simbals technology",
                moods: {
                    title: "Moods of the album",
                    subtitle: "by order of importance",
                    description: () => (
                        <>
                            Detailed moods of the recording and their
                            corresponding weights. <br />
                            <strong>Full list:</strong> happy, sad, calm,
                            energic, excited, astonished/aroused,
                            tense/alarmed/afraid, angry, distressed, delighted,
                            convinced, impatient, frustrated, discontented,
                            pleased, interested, neutral, distrustful,
                            miserable/disappointed/startled, serene/content,
                            Confident, Worried, uncomfortable,
                            sad/depressed/gloomy, relaxed/satisfied,
                            contemplative, tired/sleepy, droopy, bored/anxious
                        </>
                    ),
                },
                themes: {
                    title: "This album contains tracks with the following themes",
                    description:
                        "Detailed themes of the recording related to the listening contexts.  Includes, for example, the following values: 'Workout', 'Diner', 'Christmas', ‘Party’, ‘Video Games’, 'Summer’, etc…",
                },
                timbres: {
                    title: "Contains titles with the following timbres",
                    description:
                        "Tags corresponding to the timbre of the recording.",
                },
                arousal: {
                    title: "Arousal",
                    description: () => (
                        <>
                            Arousal represents a perceptual measure of a
                            recording related to its intensity, energy and
                            activity.
                            <ul>
                                <li>
                                    <strong>High value</strong> usually
                                    corresponds to high energy tracks (hard
                                    rock, metal)
                                </li>
                                <li>
                                    <strong>Low value</strong> is given to calm
                                    tracks (slow, ballad).
                                </li>
                            </ul>
                        </>
                    ),
                },
                valence: {
                    title: "Valence",
                    description: () => (
                        <>
                            Valence represents a perceptual measure of mood,
                            related to positive or negative feelings, for
                            example joy/sadness.
                            <ul>
                                <li>
                                    <strong>High value</strong> corresponds to
                                    tracks with positive feelings
                                </li>
                                <li>
                                    <strong>Low value</strong> refer to sad
                                    tracks.
                                </li>
                            </ul>
                        </>
                    ),
                },
                vocal_instrumental: {
                    title: "Instrumentalness",
                    description: () => (
                        <>
                            This descriptor indicates whether a track contains
                            or not vocals.
                            <ul>
                                <li>
                                    <strong>High value</strong> indicates that
                                    the track probably contains no vocal sounds
                                    (instrumental)
                                </li>
                                <li>
                                    <strong>Low value</strong> probably
                                    corresponds to track with vocal content
                                    (singing words, rap, slam or speech for
                                    example).
                                </li>
                            </ul>
                        </>
                    ),
                },
                music_speech: {
                    title: "Speechiness",
                    description: () => (
                        <>
                            This descriptor indicates speech content in a track.
                            <ul>
                                <li>
                                    <strong>High value</strong> is related to
                                    the presence of spoken words (interview,
                                    audio book, poetry, commentary).
                                </li>
                                <li>
                                    <strong>Low value</strong> probably
                                    corresponds to music, eventually with vocals
                                    (singing tracks), but without speech parts.
                                </li>
                            </ul>
                        </>
                    ),
                },
                electric_acoustic: {
                    title: "Acousticness indicator",
                    description: () => (
                        <>
                            This descriptor indicates whether the track contains
                            acoustic content.
                            <ul>
                                <li>
                                    <strong>High value</strong> corresponds to
                                    acoustic tracks (acoustic guitar/piano,
                                    voice, classical music)
                                </li>
                                <li>
                                    <strong>Low value</strong> indicates tracks
                                    with electric content (electronic,
                                    distortion, audio effects).
                                </li>
                            </ul>
                        </>
                    ),
                },
                danceability: {
                    title: "Danceability",
                    description: () => (
                        <>
                            Danceability indicates if a track is likely to be
                            considered for dancing (perception of tempo and
                            rhythm, stability and regularity).
                            <ul>
                                <li>
                                    <strong>High value</strong> corresponds to
                                    more danceable tracks
                                </li>
                                <li>
                                    <strong>Low value</strong> is related to
                                    tracks without any perceptual rhythm.
                                </li>
                            </ul>
                        </>
                    ),
                },
                studio_live: {
                    title: "Liveness",
                    description: () => (
                        <>
                            This descriptor indicates the probability that the
                            track has been performed live.
                            <ul>
                                <li>
                                    <strong>High value</strong> corresponds to
                                    live tracks
                                </li>
                                <li>
                                    <strong>Low value</strong> is more probably
                                    related to studio recordings
                                </li>
                            </ul>
                        </>
                    ),
                },
                melodicity: {
                    title: "Melodicity",
                    description: () => (
                        <>
                            This descriptor indicates whether a track contains
                            or not a clearly audible melody.
                            <ul>
                                <li>
                                    <strong>High value</strong> correspond to
                                    pleasant, harmonic tracks, to which you feel
                                    like singing along
                                </li>
                                <li>
                                    <strong>Low value</strong> are more related
                                    to noises, soundscapes, or tracks with
                                    complex harmonies or melodies (classical,
                                    jazz, etc)
                                </li>
                            </ul>
                        </>
                    ),
                },
                dissonance: {
                    title: "Dissonance",
                    description: () => (
                        <>
                            This descriptor indicates how dissonant is a track.
                            <ul>
                                <li>
                                    <strong>High value</strong> correspond to
                                    tracks without clear harmony, noisy timbre
                                    for example, or with very complex harmonies
                                    (dissonant intervals such as tritones,
                                    seconds, etc)
                                </li>
                                <li>
                                    <strong>Low value</strong> correspond to
                                    tracks with simple harmony, clear melody,
                                    consonant harmonies (simple piano melodies
                                    for example)
                                </li>
                            </ul>
                        </>
                    ),
                },
            },
            creditsCreditsData: {
                licence: "Courtesy of Muso.Ai",
                musician: "Musician",
                songwriter: "Songwriter",
                management: "Management",
                engineer: "Engineer",
                producers: "Producers",
                organization: "Organization",
                other: "Other",
                visual: "Visual",
            },
        },
    },
    recording: {
        view: {
            breadcrumb: "Recording visualization",
            id: "Music-Story ID",
            title: "Title",
            subtitle: "Subtitle",
            isrc: "ISRC",
            genre: "Genre",
            profile: "Profile",
            credits: "Credits",
            popularity: "Popularity",
            charts: "Charts",
            lyrics: "Lyrics",
            audio_description: "Audio descriptor",
            similar: "Similar recording",
            work: "Work",
            noData: {
                lyrics: "No lyrics available for this recording",
                credits: "No credits available for this recording",
                charts: "No charts available for this recording",
                audio: "No audio descriptor available for this recording",
                popularity:
                    "No popularity data is available for this recording",
                work: "No work data is available for this recording",
            },
            profileFeaturing: {
                head: "Featurings",
            },
            profileInstrument: {
                head: "Instruments",
            },
            profileDuration: {
                head: "Duration",
            },
            profileRelatedIsrc: {
                head: "Related ISRCs",
                has_credits: "Credits",
                has_lyric: "Lyrics",
                has_audio: "Audio description",
            },
            profileTranslation: {
                head: "Translated title",
            },
            profileAlbum: {
                head: "Albums",
                cover: "Cover",
                title: "Title",
                artists: "Artists",
                type: {
                    head: "Type",
                    live: "Live",
                    remix: "Remix",
                    compilation: "Compilation",
                    karaoke: "Karaoke",
                    ring: "Ring",
                    other: "Other",
                    original: "Original",
                },
                format: {
                    head: "Format",
                    album: "Album",
                    ep: "EP",
                    single: "Single",
                    mixtape: "Mixtape",
                    mcd: "Maxi",
                },
                release_date: "Release date",
                links: "Links",
                empty: "No Results",
            },
            chartsRecordingCharts: {
                charts: {
                    head: "Charts",
                    title: "Title",
                    empty: "No Results",
                    search: "Search...",
                },
                filter: {
                    format: {
                        label: "Type",
                    },
                    reference: {
                        label: "Official",
                        description: "The country's official sales ranking",
                    },
                },
                trend: {
                    head: "Trend",
                    x: "Date",
                    y: "Rank",
                    dynamicRank: "Remove empty rank",
                    dynamicDate: "Remove empty date",
                },
            },
            lyricsInformation: {
                head: "Information",
                licence: {
                    title: "Courtesy of LyricFind",
                    subtitle:
                        "This content may not be used without a specific license delivered by LyricFind",
                },
                lfid: "Lyricfind ID",
                hfa_code: "HFA Code",
                publisher_credit: "Publisher credit",
                territories: "Territories",
                lyric: "Original Lyric",
            },
            audioAudioDescription: {
                head: "Audio description",
                licence: "Descriptors calculated with Simbals technology",
                moods: {
                    title: "Moods of the recording",
                    subtitle: "by order of importance",
                    description: () => (
                        <>
                            Detailed moods of the recording and their
                            corresponding weights. <br />
                            <strong>Full list:</strong> happy, sad, calm,
                            energic, excited, astonished/aroused,
                            tense/alarmed/afraid, angry, distressed, delighted,
                            convinced, impatient, frustrated, discontented,
                            pleased, interested, neutral, distrustful,
                            miserable/disappointed/startled, serene/content,
                            Confident, Worried, uncomfortable,
                            sad/depressed/gloomy, relaxed/satisfied,
                            contemplative, tired/sleepy, droopy, bored/anxious
                        </>
                    ),
                },
                themes: {
                    title: "Themes",
                    description:
                        "Detailed themes of the recording related to the listening contexts.  Includes, for example, the following values: 'Workout', 'Diner', 'Christmas', ‘Party’, ‘Video Games’, 'Summer’, etc…",
                },
                timbres: {
                    title: "Timbres",
                    description:
                        "Tags corresponding to the timbre of the recording.",
                },
                arousal: {
                    title: "Arousal",
                    description: () => (
                        <>
                            Arousal represents a perceptual measure of a
                            recording related to its intensity, energy and
                            activity.
                            <ul>
                                <li>
                                    <strong>High value</strong> usually
                                    corresponds to high energy tracks (hard
                                    rock, metal)
                                </li>
                                <li>
                                    <strong>Low value</strong> is given to calm
                                    tracks (slow, ballad).
                                </li>
                            </ul>
                        </>
                    ),
                },
                valence: {
                    title: "Valence",
                    description: () => (
                        <>
                            Valence represents a perceptual measure of mood,
                            related to positive or negative feelings, for
                            example joy/sadness.
                            <ul>
                                <li>
                                    <strong>High value</strong> corresponds to
                                    tracks with positive feelings
                                </li>
                                <li>
                                    <strong>Low value</strong> refer to sad
                                    tracks.
                                </li>
                            </ul>
                        </>
                    ),
                },
                vocal_instrumental: {
                    title: "Instrumentalness",
                    description: () => (
                        <>
                            This descriptor indicates whether a track contains
                            or not vocals.
                            <ul>
                                <li>
                                    <strong>High value</strong> indicates that
                                    the track probably contains no vocal sounds
                                    (instrumental)
                                </li>
                                <li>
                                    <strong>Low value</strong> probably
                                    corresponds to track with vocal content
                                    (singing words, rap, slam or speech for
                                    example).
                                </li>
                            </ul>
                        </>
                    ),
                },
                music_speech: {
                    title: "Speechiness",
                    description: () => (
                        <>
                            This descriptor indicates speech content in a track.
                            <ul>
                                <li>
                                    <strong>High value</strong> is related to
                                    the presence of spoken words (interview,
                                    audio book, poetry, commentary).
                                </li>
                                <li>
                                    <strong>Low value</strong> probably
                                    corresponds to music, eventually with vocals
                                    (singing tracks), but without speech parts.
                                </li>
                            </ul>
                        </>
                    ),
                },
                electric_acoustic: {
                    title: "Acousticness indicator",
                    description: () => (
                        <>
                            This descriptor indicates whether the track contains
                            acoustic content.
                            <ul>
                                <li>
                                    <strong>High value</strong> corresponds to
                                    acoustic tracks (acoustic guitar/piano,
                                    voice, classical music)
                                </li>
                                <li>
                                    <strong>Low value</strong> indicates tracks
                                    with electric content (electronic,
                                    distortion, audio effects).
                                </li>
                            </ul>
                        </>
                    ),
                },
                danceability: {
                    title: "Danceability",
                    description: () => (
                        <>
                            Danceability indicates if a track is likely to be
                            considered for dancing (perception of tempo and
                            rhythm, stability and regularity).
                            <ul>
                                <li>
                                    <strong>High value</strong> corresponds to
                                    more danceable tracks
                                </li>
                                <li>
                                    <strong>Low value</strong> is related to
                                    tracks without any perceptual rhythm.
                                </li>
                            </ul>
                        </>
                    ),
                },
                studio_live: {
                    title: "Liveness",
                    description: () => (
                        <>
                            This descriptor indicates the probability that the
                            track has been performed live.
                            <ul>
                                <li>
                                    <strong>High value</strong> corresponds to
                                    live tracks
                                </li>
                                <li>
                                    <strong>Low value</strong> is more probably
                                    related to studio recordings
                                </li>
                            </ul>
                        </>
                    ),
                },
                melodicity: {
                    title: "Melodicity",
                    description: () => (
                        <>
                            This descriptor indicates whether a track contains
                            or not a clearly audible melody.
                            <ul>
                                <li>
                                    <strong>High value</strong> correspond to
                                    pleasant, harmonic tracks, to which you feel
                                    like singing along
                                </li>
                                <li>
                                    <strong>Low value</strong> are more related
                                    to noises, soundscapes, or tracks with
                                    complex harmonies or melodies (classical,
                                    jazz, etc)
                                </li>
                            </ul>
                        </>
                    ),
                },
                dissonance: {
                    title: "Dissonance",
                    description: () => (
                        <>
                            This descriptor indicates how dissonant is a track.
                            <ul>
                                <li>
                                    <strong>High value</strong> correspond to
                                    tracks without clear harmony, noisy timbre
                                    for example, or with very complex harmonies
                                    (dissonant intervals such as tritones,
                                    seconds, etc)
                                </li>
                                <li>
                                    <strong>Low value</strong> correspond to
                                    tracks with simple harmony, clear melody,
                                    consonant harmonies (simple piano melodies
                                    for example)
                                </li>
                            </ul>
                        </>
                    ),
                },
                extra: {
                    rythm: "Rhythm",
                    timbre: "Timbre",
                    harmony: "Harmony",
                    energy: "Energy",
                },
                articulation: {
                    title: "Articulation",
                    description: "Articulation",
                },
                rhythmic_stability: {
                    title: "Rhythmic stability",
                    description: "Rhythmic stability",
                },
                event_density: {
                    title: "Event density",
                    description: "Event density",
                },
                pulse_clarity: {
                    title: "Pulse clarity",
                    description: "Pulse clarity",
                },
                bpm: {
                    title: "BPM",
                    description: "BPM",
                },
                complexity: {
                    title: "Complexity",
                    description: "Complexity",
                },
                binary: {
                    title: "Binary",
                    description: "Binary",
                },
                roll_off: {
                    title: "Roll off",
                    description: "Roll off",
                },
                brightness: {
                    title: "Brightness",
                    description: "Brightness",
                },
                zero_cross_rate: {
                    title: "Zero cross rate",
                    description: "Zero cross rate",
                },
                mfcc: {
                    title: "MFCC",
                    description: "MFCC",
                },
                mfcc01: {
                    title: "Mfcc01",
                },
                mfcc02: {
                    title: "Mfcc02",
                },
                mfcc03: {
                    title: "Mfcc03",
                },
                mfcc04: {
                    title: "Mfcc04",
                },
                mfcc05: {
                    title: "Mfcc05",
                },
                mfcc06: {
                    title: "Mfcc06",
                },
                mfcc07: {
                    title: "Mfcc07",
                },
                mfcc08: {
                    title: "Mfcc08",
                },
                mfcc09: {
                    title: "Mfcc09",
                },
                mfcc10: {
                    title: "Mfcc10",
                },
                mfcc11: {
                    title: "Mfcc11",
                },
                mfcc12: {
                    title: "Mfcc12",
                },
                mfcc13: {
                    title: "Mfcc13",
                },
                chroma: {
                    title: "Chroma (Harmony, key)",
                    description: "Chroma (Harmony, key)",
                },
                chroma01: {
                    title: "Chroma01",
                },
                chroma02: {
                    title: "Chroma02",
                },
                chroma03: {
                    title: "Chroma03",
                },
                chroma04: {
                    title: "Chroma04",
                },
                chroma05: {
                    title: "Chroma05",
                },
                chroma06: {
                    title: "Chroma06",
                },
                chroma07: {
                    title: "Chroma07",
                },
                chroma08: {
                    title: "Chroma08",
                },
                chroma09: {
                    title: "Chroma09",
                },
                chroma10: {
                    title: "Chroma10",
                },
                chroma11: {
                    title: "Chroma11",
                },
                chroma12: {
                    title: "Chroma12",
                },
                complexity_chroma: {
                    title: "Complexity chroma",
                    description: "Complexity chroma",
                },
                intensity: {
                    title: "Intensity",
                    description: "Intensity",
                },
                loudness: {
                    title: "Loudness",
                    description: "Loudness",
                },
                absolute_loudness: {
                    title: "Absolute loudness",
                    description: "Absolute loudness",
                },
                loudness_range: {
                    title: "Loudness range",
                    description: "Loudness range",
                },
                centroid: {
                    title: "Centroid",
                    description: "Centroid",
                },
                spread: {
                    title: "Spread",
                    description: "Spread",
                },
                flatness: {
                    title: "Flatness",
                    description: "Flatness",
                },
            },
            creditsCreditsData: {
                licence: "Courtesy of Muso.Ai",
                musician: "Musician",
                songwriter: "Songwriter",
                management: "Management",
                engineer: "Engineer",
                producers: "Producers",
                organization: "Organization",
                other: "Other",
                visual: "Visual",
            },
            popularityOverview: {
                head: "Overview",
                global: {
                    title: "Global",
                    weeks: "Weeks in charts:",
                    description:
                        "A track's popularity is calculated on the basis of its presence and position in the charts we track. It takes two forms: rank (the title's position in our ranking of popular titles), and popularity level (in %, relative to the highest popularity score in our ranking).",
                },
                gold: {
                    title: "Catalog",
                    weeks: "Weeks in catalog charts:",
                    description:
                        "The catalog popularity of a recording is based on its presence in official charts dating from more than 18 months. Catalog popularity only takes into account data from the official American, German, and British charts.",
                },
                current: {
                    title: "Current",
                    weeks: "Weeks in current charts:",
                    description:
                        "The current popularity of a title is based on its presence in charts from the past 18 months.",
                },
                type: {
                    label: "Type:",
                    gold: "Catalog",
                    current: "Current",
                },
                peak: "Peak:",
            },
            popularityHistogram: {
                head: "Popularity over time",
                tabs: {
                    gold: {
                        title: "Catalog",
                        description:
                            "The Catalog popularity of an artist is based on his presence in the charts more than 18 months ago.\nThe Catalog popularity only uses Billboard Hot 100, UK Official Singles Chart & Offizielle Deutsche Charts data at the moment.",
                        header: "Catalog popularity is solely based on the US, UK and DE official charts data, more countries are being integrated.",
                    },
                    current: {
                        title: "Current",
                        description:
                            "The current popularity of an artist is based on his presence in the charts during the last 18 months.",
                    },
                },
                gold: "Catalog popularity",
                current: "Current popularity",
                popover: {
                    noData: "No data",
                    popularity: "Recording's popularity:",
                    bestRank: "Best rank:",
                    bestCountry: "Best country:",
                    charts: (n) => `Based on ${n} charts`,
                },
            },
            popularityMap: {
                head: "Popularity per country",
                tabs: {
                    gold: {
                        title: "Catalog",
                        description:
                            "The Catalog popularity of an artist is based on his presence in the charts more than 18 months ago.\nThe Catalog popularity only uses the Billboard Hot 100, UK Official Singles Chart & Offizielle Deutsche Charts data at the moment.",
                    },
                    current: {
                        title: "Current",
                        description:
                            "The current popularity of an artist is based on his presence in the charts during the last 18 months.",
                    },
                },
                popover: {
                    noData: "No data",
                    popularity: "Recording's popularity:",
                    bestRank: "Best rank:",
                    bestMonth: "Month of best rank:",
                    weeksInCharts: "Weeks in charts:",
                    charts: (n) => `Based on ${n} charts`,
                },
            },
            workData: {
                iswc: "ISWC",
                hfa: "HFA code",
                publisher_credit: "Publisher credit (Lyricfind)",
                share: {
                    head: "Shares US (MLC)",
                    party: "Party",
                    ipi: "IPI",
                    role: "Role",
                    parent: "Parent party",
                    parent_ipi: "Parent IPI",
                    share: "Share",
                },
            },
        },
    },
    genre: {
        breadcrumb: "Genres",
        genre: {
            head: "Genres",
            parent: "Parent name",
            name: "Name",
        },
        related: {
            head: "Related",
            name: "Name",
            weight: "Type",
        },
        artist: {
            head: "Top Artists",
            position: "N°",
            name: "Name",
            complement: "Complementary",
            actions: "Actions",
            country: "Country",
            type: "Type",
            role: "Role",
        },
        button: "Genre management",
        search: "Search...",
        empty: "No results",
        manage: {
            breadcrumb: "Management",
            genre: {
                head: "Genre",
                empty: "No Results",
                id: "Id",
                name: "Name",
                description: "Description",
                related: "Related",
                genre: "Genre",
                type: "Type",
                genre_fr: "Genre FR",
                genre_en: "Genre EN",
                other_translations: "Other translations",
                parent_genre: "Parent genre",
                parent_genre_placeholder:
                    "Choose nothing to create a parent genre",
                created_at: "Created At",
                updated_at: "Updated At",
                action: "Action",
                search: "Search...",
            },
            create: {
                title: "Create genre",
                success: "Genre created",
                error: (message) =>
                    `Error while creating this genre: ${message}`,
            },
            update: {
                title: "Update genre",
                success: "Genre updated",
                error: (message) =>
                    `Error while updating this genre: ${message}`,
            },
            delete: {
                title: "Delete genre",
                alert: {
                    title: "Are you sure ?",
                    text: "To want to delete this genre ?",
                },
                success: "Genre deleted",
                error: (message) =>
                    `Error while deleting this genre: ${message}`,
            },
        },
    },
    country: {
        breadcrumb: "Country",
        head: "Country",
        button: "Country management",
        code: "Code",
        country_fr: "Country FR",
        country_en: "Country EN",
        country_ja: "Country JA",
        country_de: "Country DE",
        country_ko: "Country KO",
        search: "Search...",
        empty: "No country to display",
        manage: {
            breadcrumb: "Management",
            country: {
                head: "Country",
                empty: "No Results",
                id: "Id",
                code: "Code",
                country_fr: "Country FR",
                country_en: "Country EN",
                other_translations: "Other translations",
                created_at: "Created At",
                updated_at: "Updated At",
                action: "Action",
                search: "Search...",
            },
            create: {
                title: "Create country",
                success: "Country created",
                error: (message) =>
                    `Error while creating this country: ${message}`,
            },
            update: {
                title: "Update country",
                success: "Country updated",
                error: (message) =>
                    `Error while updating this country: ${message}`,
            },
            delete: {
                title: "Delete country",
                alert: {
                    title: "Are you sure ?",
                    text: "To want to delete this country ?",
                },
                success: "Country deleted",
                error: (message) =>
                    `Error while deleting this country: ${message}`,
            },
        },
    },
    misc_entity: {
        breadcrumb: "Shows",
        head: "Shows",
        search: "Search...",
        empty: "No show to display",
        id: "id",
        name: "name",
        type: "type",
        description: "description",
        date: "date",
        management: {
            breadcrumb: "Management",
            profile: "Profile",
            curation: "Curation",
            getty: "Getty",
            main: "Main information",
            misc_entity: {
                id: "ID",
                name: "Name",
                type: "Type",
                description: "Description",
                date: "Date",
                created_at: "Created At",
                updated_at: "Updated At",
                action: "Action",
                search: "Search...",
            },
            imageCuration: {
                head: "Gallery",
                empty: "No images in the gallery",
                width: "Width",
                height: "Height",
                created_at: "Creation date",
                copyright: "Copyright",
                status: "Status",
                edit: "Edit",
                edit_image: "Edit image information",
                delete: "Delete",
                delete_image: "Delete image",
            },
            imageGetty: {
                gallery: {
                    head: "Gallery",
                    empty: "No images in the gallery",
                    width: "Width",
                    height: "Height",
                    created_at: "Creation date",
                    copyright: "Copyright",
                    status: "Status",
                    edit: "Edit",
                    edit_image: "Edit image information",
                    delete: "Delete",
                    delete_image: "Delete image",
                },
            },
            create: {
                title: "Create misc",
                success: "Misc created",
                error: (message) =>
                    `Error while creating this misc: ${message}`,
            },
            update: {
                title: "Update misc",
            },
            delete: {
                title: "Delete misc",
                alert: {
                    title: "Are you sure ?",
                    text: "To want to delete this misc ?",
                },
                success: "Misc deleted",
                error: (message) =>
                    `Error while deleting this misc: ${message}`,
            },
        },
    },
    role: {
        breadcrumb: "Role",
        head: "Role",
        button: "Role management",
        role_fr: "Role FR",
        role_en: "Role EN",
        role_ja: "Role JA",
        role_de: "Role DE",
        role_ko: "Role KO",
        search: "Search...",
        empty: "No role to display",
        manage: {
            breadcrumb: "Management",
            role: {
                head: "Role",
                empty: "No Results",
                id: "Id",
                role_fr: "Role FR",
                role_en: "Role EN",
                other_translations: "Other translations",
                created_at: "Created At",
                updated_at: "Updated At",
                action: "Action",
                search: "Search...",
            },
            create: {
                title: "Create role",
                success: "Role created",
                error: (message) =>
                    `Error while creating this role: ${message}`,
            },
            update: {
                title: "Update role",
                success: "Role updated",
                error: (message) =>
                    `Error while updating this role: ${message}`,
            },
            delete: {
                title: "Delete role",
                alert: {
                    title: "Are you sure ?",
                    text: "To want to delete this role ?",
                },
                success: "Role deleted",
                error: (message) =>
                    `Error while deleting this role: ${message}`,
            },
        },
    },
    plannings: {
        breadcrumb: "Plannings",
        manage: {
            breadcrumb: "Plannings Management",
            planning: {
                empty: "No Results",
                building: "Building",
                progress: "In progress",
                done: "Archived",
                title: "Title",
                startDate: "Start date",
                endDate: "End date",
                stats: "Progression",
                itemsCount: "Result(s)",
            },
            create: {
                title: "Creating a planning",
            },
            update: {
                title: "Editing a planning",
            },
        },
        view: {
            breadcrumb: "View a planning",
            partner: {
                all: "Overview",
            },
            action: {
                validate: {
                    title: "Validate planning",
                    swal: {
                        title: "Are you sure ?",
                        notCompleted: "The planning is not fully completed",
                        text: "To want to validate this planning ?",
                    },
                },
                deleted: {
                    title: "Delete planning",
                    swal: {
                        title: "Are you sure ?",
                        tasksExist: "The planning is not empty",
                        text: "To want to delete this planning ?",
                    },
                },
            },
            tasks: {
                title: "Tasks",
                empty: "No Results",
                manager: "Manager",
                status: "Status",
                artist: "Artist",
                album: "Album",
                track: "Track",
                category: "Category",
                assignement: "Assignement",
                classification: "Class.",
                tasks: "Tasks",
                reason: "Reason",
                information: "Information",
                nbRelated: "Number of related",
                albumsWithoutGenre: "Albums without genre",
                hasBioFR: "Has Bio FR",
                hasBioEN: "Has Bio EN",
                hasBioDE: "Has Bio DE",
                hasBioJA: "Has Bio JA",
                hasBioES: "Has Bio ES",
                hasBioPT: "Has Bio PT",
                hasReview: "Has Review",
                hasPicture: "Has Picture",
                hasCuration: "Has Curation",
                lastUpdated: "Last planning entry",
                genre: "Genre",
                language: "Language",
                country: "Country",
                action: {
                    topLink: "Related Top",
                    lyricsSampleLink: "Lyric assessment",
                    discoLink: "Discography",
                    create: {
                        title: "Create task",
                    },
                    update: {
                        title: "Update task",
                    },
                    delete: {
                        title: "Delete task",
                        alert: {
                            title: "Are you sure ?",
                            text: "To want to delete this task ?",
                        },
                    },
                },
                subtasks: {
                    change_status: "Change status :",
                    confirm_change_status: {
                        TODO: {
                            title: "Are you sure ?",
                            text: "You want to change the status of this task to To Do ?",
                        },
                        COMPLETED: {
                            title: "Are you sure ?",
                            text: "You want to change the status of this task to Completed ?",
                        },
                        TO_BE_CORRECTED: {
                            title: "Are you sure ?",
                            text: "You want to change the status of this task to To be corrected ?",
                            comment: {
                                title: "Add a comment",
                                error: "A comment is required",
                            },
                        },
                        VERIFIED_AND_VALIDATED: {
                            title: "Are you sure ?",
                            text: "You want to change the status of this task to Verified and validated ?",
                        },
                        VALIDATED_WITHOUT_CONTROL: {
                            title: "Are you sure ?",
                            text: "You want to change the status of this task to Validated without control ?",
                        },
                        ERROR: {
                            title: "Are you sure ?",
                            text: "You want to change the status of this task to In Error ?",
                            comment: {
                                title: "Add a comment",
                            },
                        },
                    },
                    status: {
                        UNASSIGNED: "Unassigned",
                        TODO: "To do",
                        COMPLETED: "Completed",
                        TO_BE_CORRECTED: "To be corrected",
                        VALIDATED_WITHOUT_CONTROL: "Validated without control",
                        VERIFIED_AND_VALIDATED: "Verified and validated",
                        ERROR: "In error",
                    },
                },
            },
            stats: {
                title: "Stats",
                manager: "Manager",
            },
            progress: {
                title: "Progress",
                username: "Username",
            },
            budget: {
                title: "Budget",
                username: "Username",
                task_type: "Task type",
            },
            history: {
                title: "Show history",
                head: "History",
                empty: "No results",
                search: "Search an artist",
                action: {
                    created_by: "Created by",
                    deleted_by: "Deleted by",
                    modified_by: "Modified by",
                    release_matched_by: "Album matched by",
                    release_unmatched_by: "Album unmatched by",
                    track_matched_by: "Track matched by",
                    track_unmatched_by: "Track unmatched by",
                    status_changed_by: (new_status) =>
                        `Marked as ${new_status} by`,
                    status_comment: (comment) => `Comment : ${comment}`,
                },
            },
        },
        tasks: {
            planning: "Planning",
            artist: "Artist",
            nbRelated: "Number of related",
            hasBioFR: "Has Bio FR",
            hasBioEN: "Has Bio EN",
            hasBioDE: "Has Bio DE",
            hasBioJA: "Has Bio JA",
            hasBioES: "Has Bio ES",
            hasBioPT: "Has Bio PT",
            hasPicture: "Has Picture",
            hasCuration: "Has Curation",
            album: "Album",
            track: "Track",
            category: "Category",
            classification: "Classification",
            assignement: "Assignement",
            reason: "Reason",
            error: {
                required: "This field is required",
                albumTrackRequired: "The album or track field is required",
            },
            subtasks: {
                title: "Task(s)",
                typePlaceholder: "Task...",
                assignementPlaceholder: "Affected to...",
                new: "Add a task",
                remove: "Remove the task",
                typeGroups: {
                    ART: "Artist",
                    ART_C: "Artist correction",
                    BIO_FR: "French Biography",
                    BIO_EN: "English Biography",
                    BIO_DE: "German Biography",
                    BIO_PT: "Portuguese Biography",
                    BIO_JP: "Japanese Biography",
                    BIO_FR_C: "French Biography Correction",
                    BIO_EN_C: "English Biography Correction",
                    BIO_DE_C: "German Biography Correction",
                    BIO_JP_C: "Japanese Biography Correction",
                    RVW: "Review",
                    RVW_C: "Review correction",
                    APL_AS: "Lyrics assessment",
                    APL_CAS: "Lyrics counter-assessment",
                },
                error: {
                    oneTypePerGroup: (category) =>
                        `There should be at most one task in the ${category} category`,
                },
            },
        },
        production: {
            breadcrumb: "Landing page",
            industry: "Market statistics",
            ms: "Database statistics",
            my_planning: "My tasks",
            tasks: {
                artist: "Artist",
                album: "Album",
                track: "Track",
                genre: "Genre",
                country: "Country",
                category: "Category-Reason",
                classification: "Classification",
                tasks: "Tasks",
                related_tasks: "Related Tasks",
                subtasks: {
                    change_status: "Change status :",
                    match_required:
                        "A matching is required on the album or track",
                    confirm_change_status: {
                        COMPLETED: {
                            title: "Are you sure ?",
                            text: "You want to change the status of this task to Completed ?",
                        },
                        ERROR: {
                            title: "Are you sure ?",
                            text: "You want to change the status of this task to In error ?",
                            comment: {
                                title: "Add a comment",
                            },
                        },
                    },
                    status: {
                        TODO: "To do",
                        COMPLETED: "Completed",
                        TO_BE_CORRECTED: "To be corrected",
                        VALIDATED_WITHOUT_CONTROL: "Validated without control",
                        VERIFIED_AND_VALIDATED: "Verified and validated",
                        ERROR: "In error",
                    },
                },
            },
        },
        client: {
            title: "Music Story production planning",
            sub_title: ({ title, start_date, end_date }) =>
                `from ${start_date} to ${end_date} ${
                    title ? `(${title})` : ""
                }`,
            status: ({ status }) => {
                const translatedStatus = {
                    BUILDING: "Status : Building",
                    PROGRESS: "Status : In progress",
                    DONE: "Status : Archived",
                };
                return translatedStatus[status];
            },
            tasks: {
                artist: "Artist",
                album: "Album",
                track: "Track",
                category: "Category",
                status_artist: "Artist Profile",
                status_bio_fr: "Bio FR",
                status_bio_en: "Bio EN",
                status_bio_de: "Bio DE",
                status_bio_ja: "Bio JA",
                status_review: "Review",
                status_picture: "Picture",
                status_curation: "Curation",
                status: {
                    creation: "Creation",
                    update: "Update",
                    new: "New",
                    progress: "Is being created",
                    exist: "Already exists",
                    missing: "Missing",
                },
            },
        },
        picture: {
            breadcrumb: "Picture Works",
            planning: {
                head: "Plannings",
                title: "Title",
                empty: "No Results",
                status: "Status",
                building: "Building",
                progress: "In progress",
                done: "Archived",
                startDate: "Start date",
                endDate: "End date",
            },
            tasks: {
                title: "Tasks",
                empty: "No Results",
                artist: "Artist",
                album: "Album",
                track: "Track",
                latest_added_date: "Latest added date",
                status: "Status",
                information: "Informations",
                label: "Label",
                getty: "Getty",
                not_found: "Not found",
                comment: "Comment",
                last_label_created_at: "Last Label picture added date",
                last_getty_created_at: "Last Getty picture added date",
                max_getty_date: "Most recent date of Getty pictures",
                has_disco: "Disco",
                last_check_date: "Date of last verification",
                itemsCount: (count) => {
                    return `${count} Result${count > 1 ? "s" : ""}`;
                },
            },
        },
        billing: {
            breadcrumb: "Billing",
            empty: "No billing available",
            month: {
                period: ({ start_date, end_date }) =>
                    `Period from ${start_date} to ${end_date}`,
            },
            subtasks: {
                artist: "Artist",
                album: "Album",
                track: "Track",
                validated_at: "Validation date",
            },
        },
        statistic: {
            breadcrumb: "Statistics",
            tracking_period: "Tracking per period",
            chart_tracking: "Chart tracking",
            ressources_tracking: "Ressources tracking",
            planning_annual: "Planning annual",
            charts_annual_tracking: "Charts annual tracking",
            resources_annual_tracking: "Resources annual tracking",
        },
    },
    apple: {
        sample: {
            assessment: {
                informations: {
                    head: "Informations",
                    catalogType: "Catalog type",
                    month: "Month",
                    artist: "Artist",
                    album: "Album",
                    title: "Title",
                    duration: "Duration",
                    isrc: "Isrc",
                    genre: "Genre",
                    country: "Country",
                    language: "Language",
                    status: {
                        TODO: "To do",
                        COMPLETED: "Completed",
                        TO_BE_CORRECTED: "To be corrected",
                        VALIDATED_WITHOUT_CONTROL: "Validated without control",
                        VERIFIED_AND_VALIDATED: "Verified and validated",
                        ERROR: "In error",
                    },
                    planning: "Planning",
                    manager: "Manager",
                },
                lyric: {
                    head: "Lyric",
                    empty: "No results",
                    nbLine: "Line",
                    timing: "Timing",
                    text: "Text",
                    rating: {
                        filter: "See ratings for this line",
                        criteria: "Criteria assessed",
                        errorType: "Error type",
                        comment: "Comment",
                        create: {
                            title: "Add rating",
                            success: "Rating Added",
                            error: (message) =>
                                `Error while add this rating: ${message}`,
                        },
                    },
                },
                rating: {
                    reset: "View all assessments",
                    line: (line_number) => `Line : ${line_number}`,
                    criteria: "Criteria assessed :",
                    errorType: "Error type :",
                    comment: "Comment :",
                    delete: {
                        tooltip: "Delete the rating",
                        alert: {
                            title: "Are you sure ?",
                            text: "You want to delete this rating ?",
                        },
                        success: "The rating has been removed",
                    },
                },
                player: {
                    action: {
                        previous: "Back to the beginning (X)",
                        back10: "Back 10 seconds (C)",
                        pause: "Pause music (SPACE)",
                        play: "Start music (SPACE)",
                    },
                    login: "Login",
                    logout: "Logout",
                    playerError: {
                        unavailable: "No player is available",
                        restricted: "Content restricted",
                    },
                },
                ratingType: {
                    assessment: "Assessment",
                    counterAssessment: "Counter Assessment",
                },
            },
            management: {
                sample: {
                    head: "Sample",
                    empty: "No results",
                    date: "Date",
                },
                catalog: "Catalog",
                stats: "Stats",
                progress: "Progress",
                lyrics: {
                    head: "Lyrics",
                    empty: "No results",
                    catalog_type: "Catalog type",
                    provider: "Provider",
                    user: "User",
                    artist: "Artist",
                    album: "Album",
                    title: "Title",
                    isrc: "Isrc",
                    genre: "Genre",
                    language: "Language",
                    country: "Country",
                    assessment: "Assessment",
                    counter_assessment: "Counter-Assessment",
                    filters: {
                        planning: "Planning",
                        assignement: "Affected to",
                        nb_line: "Number of rows",
                        assessment: {
                            yes: "With assessment",
                            no: "Without assessment",
                        },
                        counter_assessment: {
                            yes: "With counter-assessment",
                            no: "Without counter-assessment",
                        },
                    },
                    action: {
                        addToPlanning: {
                            title: "Add to planning",
                            exist: "Already present in a planning",
                        },
                    },
                },
            },
            report: {
                sample: {
                    head: "Sample",
                    empty: "No results",
                    date: "Date",
                },
                scorecard_provider: "Scorecard Provider",
                scorecard_apple_user: "Scorecard Apple User",
                ranking_language_genre: "Ranking Language/Genre",
                explorer: "Explorer",
                error: "Error details",
            },
        },
    },
    cnm: {
        repositories: {
            repositories: {
                title: "Repositories",
                empty: "No results",
            },
            years: {
                title: "Years",
                empty: "No results",
            },
            upload: {
                button: {
                    title: "Upload",
                },
                modal: {
                    title: "Upload a repository",
                    file: "File",
                    file_placeholder: {
                        csv: "Select a CSV file",
                        json: "Select a JSON file",
                    },
                    browse: "Browse",
                    type: "Type",
                    year: "Year",
                    submit: "Upload",
                    submitting: "Uploading",
                },
                conflict: {
                    usedIn: ({ name }) => `${name} is used in `,
                    titleLink: ({ artist, title }) => `${title} by ${artist}`,
                },
                success: "The repository has been uploaded",
            },
            csa_artists: {
                title: "CSA Artists",
                upload_help:
                    "The CSV file should have a single column containing the artists' names, and no header row.",
                empty: "No results",
                search: "Search...",
                name: "Name",
                conflict:
                    "The CSA artists below are used in the consolidated top, but are not in present the uploaded file:",
            },
            cnm_artists_titles: {
                title: "CNM Artists/Titles",
                artists: {
                    head: "Artists",
                    search: "Search...",
                    id: "ID",
                    name: "Name",
                    msArtists: "MS Artists",
                    noArtist: "Titles without artist",
                    edit: {
                        tooltip: "Edit the artist",
                        title: "Edit an artist",
                        msArtistsPlaceholder: "Select an artist",
                        submit: "Edit",
                        success: "The artist has been modified",
                    },
                    delete: {
                        tooltip: "Delete the artist",
                        title: "Delete the artist",
                        text: "Are you sure you want to delete this artist ?",
                        submit: "Delete",
                        success: "The artist has been deleted",
                    },
                },
                titles: {
                    head: ({ noArtist, artistName }) =>
                        noArtist
                            ? "Titles without artist"
                            : artistName
                            ? `Titles by ${artistName}`
                            : "Titles",
                    search: "Search...",
                    id: "ID",
                    artist: "Artist",
                    title: "Title",
                    msRecordings: "MS Recordings",
                    artistPlaceholder: "Select an artist",
                    msRecordingsPlaceholder: "Select a recording",
                    edit: {
                        tooltip: "Edit the title",
                        title: "Edit a title",
                        submit: "Edit",
                        success: "The title has been modified",
                    },
                    delete: {
                        tooltip: "Delete the title",
                        title: "Delete the title",
                        text: "Are you sure you want to delete this title ?",
                        matchings:
                            "The title is matched in the following platform charts and cannot be deleted.",
                        position: (positions) => `rank(s) ${positions}`,
                        submit: "Delete",
                        success: "The title has been deleted",
                    },
                },
            },
            cnm_genres: {
                title: "CNM Genres",
                upload_help:
                    "The CSV file should have a single column containing the genres' names, and no header row.",
                genres: {
                    title: "CNM Genres",
                    empty: "No results",
                    search: "Search...",
                    cnmGenre: "CNM Genre",
                    msGenres: "MS Genres",
                },
                matchings: {
                    title: "Matchings",
                    search: "Search...",
                    empty: "No results",
                    filter: {
                        msMainGenre: "Main MS Genre",
                        cnmGenre: "CNM Genre",
                        notMatched: "Not matched",
                    },
                    msGenre: "MS Genre",
                    cnmGenre: "CNM Genre",
                    cnmGenrePlaceholder: "Select a CNM genre",
                    matchSelection: {
                        button: "Match selection",
                        title: "Match selected MS genres",
                        genrePlaceholder: "Select a CNM genre",
                        modifiedMatchings:
                            "Warning, the following matchings will be replaced:",
                        submit: "Match",
                    },
                    copyMatchings: {
                        button: "Copy Matchings",
                        title: "Copy matchings from another year's repository",
                        genrePlaceholder: "Select a year",
                        deletedGenres: (yearSrc) =>
                            `Deleted genres from ${yearSrc}:`,
                        newGenres: (yearDst) => `New genres in ${yearDst}:`,
                        modifiedMatchings: {
                            title: ({ yearSrc, yearDst }) =>
                                `Warning, the following MS genres have different matchings in repositories ${yearSrc} and ${yearDst}. The matchings from ${yearDst} will be kept:`,
                            item: ({
                                yearSrc,
                                yearDst,
                                msName,
                                dstCNMName,
                                srcCNMName,
                            }) =>
                                `${msName} (MS) – ${srcCNMName} in ${yearSrc} and ${dstCNMName} in ${yearDst} (CNM)`,
                        },
                        submit: "Copy",
                        success: "The matchings have been copied",
                    },
                },
                matchingSuccess: "The matching(s) have been modified",
            },
            cnm_labels: {
                title: "CNM Labels",
                upload_help:
                    "The CSV file should have two columns containing the labels' name and type, and no header row.",
                empty: "No results",
                search: "Search...",
                name: "Name",
                type: "Type",
                add: {
                    button: "Add",
                    tooltip: "Add a label",
                    success: "The label has been added",
                },
                edit: {
                    tooltip: "Edit the label",
                    success: "The label has been modified",
                },
                delete: {
                    tooltip: "Delete the label",
                    title: "Delete the label",
                    text: "Are you sure you want to delete this label ?",
                    matchings:
                        "The label is used in the following titles of the consolidated top:",
                    titleLink: ({ artist, title }) => `${title} by ${artist}`,
                    submit: "Delete",
                    success: "The label has been removed",
                },
                createOrUpdate: {
                    create: {
                        title: "Add a label",
                        submit: "Add",
                    },
                    update: {
                        title: "Edit a label",
                        submit: "Edit",
                    },
                    cnmName: "Original CNM name",
                    name: "Name",
                    type: {
                        label: "Type",
                        placeholder: "Select a type",
                        major: "Major",
                        indie: "Indie",
                    },
                },
                conflict:
                    "The CNM labels below are used in the consolidated top, but are not in present the uploaded file:",
            },
            cnm_distributors: {
                title: "CNM Distributors",
                upload_help:
                    "The CSV file should have two columns containing the distributors' name and type, and no header row.",
                empty: "No results",
                search: "Search...",
                name: "Name",
                type: "Type",
                add: {
                    button: "Add",
                    tooltip: "Add a distributor",
                    success: "The distributor has been added",
                },
                edit: {
                    tooltip: "Edit the distributor",
                    success: "The distributor has been modified",
                },
                delete: {
                    tooltip: "Delete the distributor",
                    title: "Delete the distributor",
                    text: "Are you sure you want to delete this distributor ?",
                    matchings:
                        "The distributor is used in the following titles of the consolidated top:",
                    titleLink: ({ artist, title }) => `${title} by ${artist}`,
                    submit: "Delete",
                    success: "The distributor has been removed",
                },
                createOrUpdate: {
                    create: {
                        title: "Add a distributor",
                        submit: "Add",
                    },
                    update: {
                        title: "Edit a distributor",
                        submit: "Edit",
                    },
                    cnmName: "Original CNM name",
                    name: "Name",
                    type: {
                        label: "Type",
                        typePlaceholder: "Select a type",
                        distributor: "Distributor",
                        topDistributor: "Top Distributor",
                    },
                },
                conflict:
                    "The CNM distributors below are used in the consolidated top, but are not in present the uploaded file:",
            },
            cypok_languages: {
                title: "Cypok Languages",
                upload_help:
                    "The file should use the Newline-Delimited JSON format",
                empty: "No results",
                artist: "Artist",
                title_column: "Title",
                languages: "Languages",
                search: "Search...",
            },
            platforms: {
                title: "Platforms",
                empty: "No platforms",
                name: "Name",
                add: {
                    button: "Add",
                    tooltip: "Add a platform",
                    success: "The platform has been added",
                },
                edit: {
                    tooltip: "Edit the platform",
                    success: "The platform has been modified",
                },
                enable: {
                    tooltip: "Enable the platform",
                    success: "The platform has been enabled",
                },
                disable: {
                    tooltip: "Disable the platform",
                    success: "The platform has been disabled",
                },
                createOrUpdate: {
                    create: {
                        title: "Add a platform",
                        submit: "Add",
                    },
                    update: {
                        title: "Edit a platform",
                        submit: "Edit",
                    },
                    name: "Name",
                },
            },
        },
        aggregation: {
            yearSelect: {
                type: {
                    TEST: "Test",
                    REAL: "Real",
                },
            },
            cnmTitleList: {
                head: "CNM Titles",
                position: "Rank",
                artist: "Artist",
                title: "Title",
                platforms: "Platforms",
                isrcs: "ISRC",
                duplicate: "Dup.",
                mixed: "Mix.",
                streams: "Streams",
                check: "Mark the title as checked",
                uncheck: "Mark the title as not checked",
                empty: "No Results",
                search: "Search...",
                filters: {
                    missingPlatforms: "Missing platform",
                    numberOfMatchedPlatforms: "Number of platforms",
                    status: {
                        placeholder: "Status",
                        none: "None",
                        checked: "Checked",
                        discarded: "Discarded",
                    },
                },
                discardButton: {
                    discard: "Discard the title",
                    recover: "Recover the title",
                    commentTitle: "Reason: ",
                    head: "Discard the title",
                    comment: "Reason",
                    submit: "Discard",
                },
            },
            search: {
                head: "Similar CNM Titles",
                sameIsrc: "The titles have a common ISRC",
                artist: "Artist",
                title: "Title",
                platforms: "Platforms",
                streams: "Streams",
                empty: "No Results",
                form: {
                    search: "Search...",
                },
            },
            platformTitleList: {
                head: "Platform Titles",
                position: "Rank",
                artist: "Artist",
                title: "Title",
                subtitle: "Subtitle",
                isrcs: "ISRC",
                duration: "Dur.",
                streams: "Streams",
                empty: "No Results",
                move: "Move the title",
            },
            createButton: {
                head: "Create CNM title from selection",
                artist: "Artist",
                title: "Title",
                success: "Created title",
            },
        },
        charts: {
            platform: {
                nav: "Charts - Platform",
                year: {
                    head: "Year",
                    empty: "No Results",
                    name: "Name",
                    type: {
                        TEST: "test",
                        REAL: "real",
                    },
                },
                pf: {
                    head: "Platform",
                    empty: "No Results",
                    name: "Name",
                    show_disabled: "Show disabled platforms",
                },
                upload: {
                    title: "Upload a chart",
                    file: "File",
                    file_placeholder: "Select a CSV file",
                    browse: "Browse",
                    year: "Year",
                    type: {
                        label: "Type",
                        TEST: "Test",
                        REAL: "Real",
                    },
                    submit: "Upload",
                    submitting: "Uploading",
                    success: "The chart has been uploaded",
                    confirm: {
                        head: "Are you sure you want to upload this top? The following fields will be modified:",
                        difference: ({ track, modifiedFields }) =>
                            `${track.artist_display} – ${track.title} (ID ${
                                track.track_id
                            }, rank ${track.position}): ${modifiedFields.join(
                                ", "
                            )}`,
                    },
                },
                matching: {
                    head: "Matching",
                    empty: "No Results",
                    search: "Search...",
                    position: "N°",
                    artist: "Artist",
                    title: "Title",
                    isrc: "Isrc",
                    cnm_artist: "CNM Artist",
                    cnm_title: "CNM Title",
                    streams: "Streams",
                    filters: {
                        charts: {
                            placeholder: "Charts",
                            initial: "Initial",
                            complement: "Complement",
                        },
                    },
                },
            },
            deduplicated: {
                nav: "Charts - Deduplicated",
                year: {
                    head: "Year",
                    empty: "No Results",
                    name: "Name",
                    type: {
                        TEST: "test",
                        REAL: "real",
                    },
                },
                pf: {
                    head: "Platform",
                    empty: "No Results",
                    name: "Name",
                    show_disabled: "Show disabled platforms",
                },
                chart: {
                    head: "Chart",
                    empty: "No Results",
                    search: "Search...",
                    position: "N°",
                    artist: "Artist",
                    title: "Title",
                    isrc: "Isrc",
                    streams: "Streams",
                },
            },
            aggregate: {
                nav: "Charts - Aggregate",
                year: {
                    head: "Year",
                    empty: "No Results",
                    name: "Name",
                    type: {
                        TEST: "test",
                        REAL: "real",
                    },
                },
                chart: {
                    head: "Chart",
                    empty: "No Results",
                    search: "Search...",
                    rank: "Rank",
                    artist: "Artist",
                    title: "Title",
                    isrcs: "Isrc(s)",
                    platforms: "Platforms",
                    streams: "Streams",
                    export: {
                        head: "Export",
                        requestForComplement: "Request for complement",
                        requestForConsolidationData:
                            "Request for consolidation data",
                        cypok: "Cypok",
                    },
                },
            },
        },
        report: {
            types: {
                title: "Types",
                empty: "No types",
            },
            years: {
                title: "Years",
                empty: "No years",
                type: {
                    TEST: "test",
                    REAL: "real",
                },
            },
            progress: {
                title: "Progress",
                aggregate: "Aggregate Top",
                upload: "Upload",
                matching: "Matching",
                building: "Building of Top 1000",
                coverage: "Coverage of Aggregate Top",
                consolidation: "Consolidation of Top 1000",
                logs: {
                    title: "Logs",
                    uploadChart: "Uploaded top",
                    uploadCypok: "Uploaded Cypok data",
                    requestForComplement: "Exported request for complement",
                    requestForCypok: "Exported request for Cypok data",
                },
                lock: {
                    title: "Lock indicators",
                    disabled:
                        "The indicators cannot be locked from the test batch.",
                    success: "The indicators have been locked",
                },
                unlock: {
                    title: "Unlock indicators",
                    disabled:
                        "The indicators cannot be unlocked from the test batch.",
                    success: "The indicators have been unlocked",
                },
            },
            consolidate: {
                yearSelect: {
                    type: {
                        TEST: "Test",
                        REAL: "Real",
                    },
                },
                chart: {
                    head: "Chart",
                    search: "Search...",
                    empty: "No results",
                    rank: "N°",
                    artist: "Artist",
                    title: "Title",
                    platformSelect: {
                        aggregate: "Aggregate",
                        global: "Global",
                    },
                    filter: {
                        firstLetter: "1st L.",
                        status: {
                            placeholder: "Status",
                            none: "None",
                            validated: "Validated",
                            discarded: "Discarded",
                            reported: "Reported",
                        },
                    },
                },
                edit: {
                    head: "Title",
                    header: {
                        platforms: "Platforms",
                        openInAggregation:
                            "Open this title in the aggregation interface",
                        artist: "Artist",
                        artist_warning:
                            "These informations will be modified on all the artist's titles.",
                        title: "Title",
                        vocals: "Vocals",
                        problems: "Problems",
                    },
                    title: {
                        label: "Title",
                    },
                    msArtists: {
                        label: "MS Artists",
                    },
                    isrcs: {
                        label: "ISRCs",
                        placeholder: "Select ISRCs",
                        reference: "ISRCs (MS / Platforms)",
                        select: "Select the ISRC",
                        unselect: "Unselect the ISRC",
                        selectAll: "Select all ISRCs",
                    },
                    genre: {
                        label: "Genre",
                        placeholder: "Select a Genre",
                        reference: "Genres (MS / Platforms)",
                        select: "Select Genre",
                        unselect: "Unselect Genre",
                    },
                    leadGender: {
                        label: "Lead's gender (artistic entity)",
                        placeholder: "Select a gender",
                        reference: "Lead's gender (MS)",
                        select: "Select the gender",
                        unselect: "Unselect the gender",
                        F: "Woman",
                        M: "Man",
                        MIXED: "Mixed",
                    },
                    countries: {
                        label: "Nationality",
                        placeholder: "Select the artists's countries",
                    },
                    voice: {
                        label: "Lead's gender (voice)",
                        placeholder: "Select a voice",
                        F: "Woman",
                        M: "Man",
                        MIXED: "Mixed",
                        INSTRUMENTAL: "Instrumental",
                    },
                    publicationDate: {
                        label: "Publication date",
                        reference: "Publication date (MS / Platforms)",
                        select: "Select the date",
                        unselect: "Unselect the date",
                    },
                    ageType: {
                        label: "Type",
                        new: "New",
                        recurring: "Recurring",
                        gold: "Gold",
                        warning: ({ from, to, date }) =>
                            `The type has changed from ${from} to ${to} on ${date}`,
                    },
                    label: {
                        label: "Label",
                        placeholder: "Select a label",
                        create: "Create a label",
                        reference: "Labels (MS / Platforms)",
                        copy: "Copy the label",
                    },
                    distributor: {
                        label: "Distributor",
                        placeholder: "Select a distributor",
                        create: "Create a distributor",
                        reference: "Distributors (Platforms)",
                        copy: "Copy the distributor",
                    },
                    cnmArtist: {
                        label: "CNM Artist",
                        placeholder: "Select a CNM Artist",
                        name: "Name",
                        create: {
                            title: "Create an artist",
                            success: "The artist has been created",
                        },
                        update: {
                            title: "Edit the artist",
                            success: "The artist has been modified",
                        },
                    },
                    csaArtist: {
                        label: "CSA Artist",
                        placeholder: "Select a CSA Artist",
                    },
                    levelOfDevelopment: {
                        label: "Level of Development",
                        confirmed: "Confirmed",
                        new: "New talent",
                    },
                    language: {
                        label: "Language",
                        placeholder: "Select a language",
                        lyricfindReference: "Language (Lyricfind)",
                        select: "Select the language",
                        unselect: "Unselect the language",
                        FRENCH: "French",
                        INTERNATIONAL: "International",
                        INSTRUMENTAL: "Instrumental",
                    },
                    platformTitles: {
                        platform: "Platform",
                        artist: "Artist",
                        title: "Title",
                        isrcs: "ISRC",
                        duration: "Dur.",
                        streams: "Streams",
                        empty: "No Results",
                    },
                    validate: "Validate the title",
                    discard: {
                        head: "Discard the title",
                        shouldBeFalse: "A discarded title cannot be validated",
                    },
                    report: {
                        head: "Report the title",
                        shouldBeFalse: "A reported title cannot be validated",
                    },
                    discardReason: {
                        head: "Reason",
                        placeholder: "Select a reason",
                        required:
                            "The title cannot be discarded without reason",
                    },
                    reportReason: "Reason",
                    success: "The title has been modified",
                    error: "An error occured while saving the informations",
                },
            },
            indicators: {
                title: "Indicators",
                top_titles: "Top Titles",
                top_artists: "Top Artists",
                indicators: "Indicators",
            },
        },
    },
    charts: {
        breadcrumb: "Charts",
        display: {
            breadcrumb: "Display",
        },
        translation: {
            breadcrumb: "Translation",
        },
    },
    partner_tops: {
        partners: {
            title: "Partners",
            empty: "No partners",
        },
        dates: {
            title: "Dates",
            empty: "No dates",
        },
        tops: {
            title: "Tops",
            empty: "No tops",
        },
        artists: {
            title: "Artists",
            empty: "No artists",
            rank: "Rank",
            name: "Name",
            matchings: "Matching",
            editMatchings: "Edit matchings",
            status: "Status",
            biographies: "Biographies",
            biographies_fr: "French",
            biographies_en: "English",
            pictures: "Pictures",
            discarded: ({ date, user, comment }) =>
                `Discarded on ${date} by ${user} - "${comment}"`,
            filters: {
                max_rank: {
                    title: "Rank",
                    top: (n) => `Top ${n}`,
                },
                matched: {
                    title: "Match",
                    yes: "Matched",
                    no: "Not matched",
                },
                discarded: {
                    title: "Discarded",
                    yes: "Discarded",
                    no: "Not discarded",
                },
                bio_en: {
                    title: "EN biography",
                    yes: "With EN biography",
                    no: "Without EN biography",
                },
                bio_fr: {
                    title: "FR biography",
                    yes: "With FR biography",
                    no: "Without FR biography",
                },
                pictures: {
                    title: "Pictures",
                    valid: "Valid pictures",
                    improve: "Pictures to improve",
                    missing: "Missing pictures",
                },
            },
            matching: {
                create: {
                    success: "Matching created",
                    duplicate: "The matching already exists",
                    error: (message) =>
                        `Error while creating matching: ${message}`,
                },
                update: {
                    success: "Matching edited",
                    not_found:
                        "Error while editing matching: the matching does not exist anymore",
                    duplicate:
                        "Error while editing matching: the matching already exists",
                    error: (message) =>
                        `Error while editing matching: ${message}`,
                },
                delete: {
                    success: "Matching deleted",
                    not_found:
                        "Error while deleting matching: the matching does not exist anymore",
                    error: (message) =>
                        `Error while deleting matching: ${message}`,
                },
            },
            discard: {
                button: {
                    title: "Discard",
                },
                modal: {
                    title: "Are you sure ?",
                    text: "You want to discard this artist ?",
                    placeholder: "Comment",
                },
                success: "Artist discarded",
                duplicate:
                    "Error while discarding artist: the artist was already discarded",
                error: (message) =>
                    `Error while discarding matching: ${message}`,
            },
            recover: {
                button: {
                    title: "Recover",
                },
                modal: {
                    title: "Are you sure ?",
                    text: "You want to recover this artist ?",
                },
                success: "Artist recovered",
                not_found:
                    "Error while recovering artist: the artist was not discarded",
                error: (message) =>
                    `Error while recovering matching: ${message}`,
            },
        },
        artistsV3: {
            title: "Artists",
            empty: "No artists",
            rank: "Rank",
            artist: "Artist",
            ms_artist: "MS Artist",
            discography_score: "Matched albums",
            biography_en: "EN Bio",
            biography_fr: "FR Bio",
            curation: "Curation",
            pictures: "Pictures",
            discarded_on: (date) => `Discarded on ${date}`,
            filters: {
                max_rank: {
                    title: "Rank",
                    top: (n) => `Top ${n}`,
                },
                matched: {
                    title: "Match",
                    yes: "With match",
                    no: "Without match",
                },
                discarded: {
                    title: "Discarded",
                    yes: "Discarded",
                    no: "Not discarded",
                },
                bio_en: {
                    title: "EN biography",
                    yes: "With EN biography",
                    no: "Without EN biography",
                },
                bio_fr: {
                    title: "FR biography",
                    yes: "With FR biography",
                    no: "Without FR biography",
                },
                curation: {
                    title: "Curation",
                    yes: "With curation",
                    no: "Without curation",
                },
                pictures: {
                    title: "Pictures",
                    yes: "With pictures",
                    no: "Without pictures",
                },
            },
            deezer: {
                market: "Market",
                musicbrainz: "MusicBrainz",
                facebook: "Facebook",
                filters: {
                    market: {
                        title: "Market",
                    },
                },
                pictures: {
                    head: "Deezer",
                    backstage: "Backstage",
                    other: "Other",
                    none: "None",
                },
            },
            napster: {
                country: "Country",
                filters: {
                    country: {
                        title: "Country",
                    },
                },
            },
            matching: {
                create: {
                    success: "Matching created",
                    duplicate: "The matching already exists",
                    error: (message) =>
                        `Error while creating matching: ${message}`,
                },
                update: {
                    success: "Matching edited",
                    not_found:
                        "Error while editing matching: the matching does not exist anymore",
                    duplicate:
                        "Error while editing matching: the matching already exists",
                    error: (message) =>
                        `Error while editing matching: ${message}`,
                },
                delete: {
                    success: "Matching deleted",
                    not_found:
                        "Error while deleting matching: the matching does not exist anymore",
                    error: (message) =>
                        `Error while deleting matching: ${message}`,
                },
            },
            discard: {
                button: {
                    title: "Discard",
                },
                modal: {
                    title: "Are you sure ?",
                    text: "You want to discard this artist ?",
                    placeholder: "Comment",
                },
                success: "Artist discarded",
                duplicate:
                    "Error while discarding artist: the artist was already discarded",
                error: (message) =>
                    `Error while discarding matching: ${message}`,
            },
            recover: {
                button: {
                    title: "Recover",
                },
                modal: {
                    title: "Are you sure ?",
                    text: "You want to recover this artist ?",
                },
                success: "Artist recovered",
                not_found:
                    "Error while recovering artist: the artist was not discarded",
                error: (message) =>
                    `Error while recovering matching: ${message}`,
            },
        },
        pictures: {
            none: "None",
            getty: "Getty",
            curation: "Curation",
            label: "Label",
            backstage: "Deezer - Backstage",
            other: "Deezer - Other",
            last_label_creation: "Date the last Label picture was added",
            last_getty_creation: "Date the last Getty picture was added",
            last_getty_date: "Date the most recent Getty picture was taken",
            last_check_date: "Date of the last check",
        },
    },
    pictures: {
        breadcrumb: "Pictures",
        label: {
            gallery: {
                breadcrumb: "Available for Clients",
                empty: "No Results",
                head: (label) => `Label pictures: ${label}`,
                search: "Search an artist",
                artist: "Artist",
                action: "Action",
                expired: "Expired picture",
                takedownLabel: "Takedown date",
                add: "Add an image",
            },
            status: {
                breadcrumb: "Pending / Tracking",
                empty: "No Results",
                head: (label) => `Label pictures: ${label}`,
                search: "Search an artist",
                picture: "Picture",
                artist: "Artist",
                status: "Status",
                filterStatusLabel: "Show tracking:",
                historic: "Historic",
                statusChange: "Status change",
                createdAt: "Submission date",
                formatedStatus: (status) => status.replaceAll("_", " "),
                definition: {
                    AWAITING:
                        "The Picture has been taken into account and will be processed soon",
                    AWAITING_FOR_PROCESSING:
                        "The picture has been accepted and is pending for documentation",
                    DOCUMENTED:
                        "The picture has passed the validation process and is now available",
                    REJECTED:
                        "The picture does not match the Music Story criteria and therefore cannot be validated",
                },
            },
            validation: {
                breadcrumb: "Images to be processed",
                empty: "No Results",
                validationHead: "Validation",
                processingHead: "Processing",
                search: "Search an artist or label",
                picture: "Picture",
                artist: "Artist",
                label: "Label",
                title: "Title",
                copyright: "Copyright",
                date_takedown: "Takedown date",
                action: "Action",
                validate: {
                    title: "Validate",
                    alert: {
                        title: "Are you sure ?",
                        text: "To want to validate this picture ?",
                    },
                    success: "Picture validated",
                    error: (message) =>
                        `Error while validated this picture: ${message}`,
                },
                reject: {
                    title: "Reject",
                    alert: {
                        title: "Are you sure ?",
                        text: "To want to reject this picture ?",
                        commentTitle: "Add a comment",
                    },
                    success: "Picture rejected",
                    error: (message) =>
                        `Error while rejected this picture: ${message}`,
                },
            },
            rejected: {
                breadcrumb: "Images rejected",
                empty: "No Results",
                head: "Images rejected",
                search: "Search an artist or label",
                picture: "Picture",
                artist: "Artist",
                label: "Label",
                title: "Title",
                copyright: "Copyright",
                date_takedown: "Takedown date",
                comment: "Comment",
                action: "Action",
                retrieve: {
                    title: "Retrieve",
                    alert: {
                        title: "Are you sure ?",
                        text: "To want to retrieve this picture ?",
                    },
                    success: "Picture retrieved",
                    error: (message) =>
                        `Error while retrieved this picture: ${message}`,
                },
                edit: {
                    title: "Update comment",
                    success: "Comment updated",
                    error: (message) =>
                        `Error while updating this comment: ${message}`,
                },
            },
        },
    },
    labelPromo: {
        breadcrumb: "Labels",
        manage: {
            breadcrumb: "Management",
            label: {
                head: "Label",
                empty: "No Results",
                id: "Id",
                name: "Name",
                created_at: "Created At",
                updated_at: "Updated At",
                status: "Status",
                action: "Action",
                search: "Search...",
            },
            show: {
                title: "Visualization of users",
                empty: "No associated users",
            },
            create: {
                title: "Create label",
                success: "Label created",
                error: (message) => `Error while create this label: ${message}`,
            },
            update: {
                title: "Update label",
                success: "Label updated",
                error: (message) => `Error while update this label: ${message}`,
            },
            delete: {
                title: "Delete label",
                alert: {
                    title: "Are you sure ?",
                    text: "To want to delete this label ?",
                },
                success: "Label deleted",
                error: (message) => `Error while delete this label: ${message}`,
            },
        },
    },
    artistTitle: {
        breadcrumb: "Artist-Title",
        searchForm: {
            artist: "Artist",
            title: "Title",
            search: "Search",
        },
        resultsList: {
            count: (count) => `${count} Results`,
            isrc: (isrc) => (isrc ? `ISRC : ${isrc}` : "no ISRC"),
            recordingId: (id) => `Recording id : ${id}`,
            artist: {
                head: "Artist",
                picture: "Artist picture",
                id: (id) => `Artist id : ${id}`,
            },
            album: {
                head: "Album",
                picture: "Album picture",
                id: (id) => `Album id : ${id}`,
            },
        },
    },
    recommendation: {
        form: {
            positive: "The field must be positive",
            min: "The field must not be less than 0",
            max: "The field must not be greater than 100",
            select_options: {
                low: "Low (0-0.3333)",
                medium: "Medium (0.3333-0.6666)",
                high: "High (0.6666-1)",
            },
            select_options_bpm: {
                low: "Low (0-76)",
                medium: "Medium (76-160)",
                high: "High (160+)",
            },
        },
        breadcrumb: "Extended recommendation",
        results: "Results",
        empty: "No Results",
        search: "Search...",
        ms_track_ids: "MS Track Ids",
        ms_track_id: "MS Track Id",
        ms_artist_ids: "MS Artist Ids",
        min_accuracy: "Minimum Accuracy",
        fa_bpm: "Bpm",
        fa_arousal: "Arousal",
        fa_moods: "Moods",
        fa_valence: "Valence",
        fa_intensity: "Intensity",
        year_range: "Year range",
        fa_descriptor: "Descriptors",
        name: "Name",
        title: "Title",
        subtitle: "Subtitle",
        genre: "Genre",
        original_album: "Album",
        original_release_date: "Release Date",
        artists: "Artists",
        mbid: "Mbid",
        match: "Match",
        popularity: "Popularity",
    },
    data_external: {
        breadcrumb: "Data External",
        internationals_charts: {
            breadcrumb: "Internationals charts",
            charts: {
                nav: "Charts",
                empty: "No Results",
                id: "Id",
                name: "Name",
                positions: "Number of positions",
                treatments: "Range of treatments",
                search: "Search...",
            },
            stats: {
                nav: "Stats",
                empty: "No Results",
                search: "Search...",
                title: "Title",
                country: "Country",
                start_date: "Date",
                treatment: "Matched",
                matched_simple: "Simple",
                creation: "Creation",
                nb_discarded: "Discarded",
                nb_forgot: "Forgot",
            },
            coverage: {
                nav: "Coverage informations",
                empty: "No Results",
                search: "Search...",
                simple: "Simple matches",
                creation: "Creation overview",
                discarded: "Discarded - Checked but not processed",
                forgot: "Discarded - Forgotten",
                title: "Title",
                country: "Country",
                area: "Area",
                date: "Date",
                rank: "Rank",
                artist: "Artist",
                album_track: "Album/Track",
                ms_artist: "MS Artist",
                comment: "Comment",
                user: "User",
            },
        },
        deezer_top_songs: {
            breadcrumb: "Deezer - top songs",
            stats: {
                nav: "Stats",
                empty: "No Results",
                date: "Date",
                country: "Country",
                treatment: "Treatment",
                nb_match_simple_ms: "Simple MS",
                nb_match_creation_ms: "Creation Ms",
                nb_discard_ms: "Discard MS",
                nb_match_lf: "Match LF",
                nb_request_lf: "Request LF",
                nb_forgot_lf: "Forgot LF",
                nb_match_lf_cas1: "Match Cas 1",
                nb_match_lf_cas23_with_request: "Request Cas 2/3",
                nb_match_lf_cas23_without_request: "No Request Cas 2/3",
                nb_forgot_ms_lf: "Forgot MS + LF",
            },
            coverage: {
                nav: "Coverage informations",
                empty: "No Results",
                search: "Search...",
                simple: "Simple matches",
                creation: "Creation overview",
                new_lyrics: "Lyrics matches",
                discarded_full: "Discarded",
                forgot_full: "Forgot full",
                forgot_lf: "Forgot LF",
                forgot_2_3: "Forgot 2/3",
                date: "Date",
                country: "Country",
                id_deezer: "Deezer ID",
                artist: "Artist",
                album: "Album",
                song: "Song",
                ms_artist: "MS Artist",
                ms_album: "MS Album",
                lf_composition: "Composition",
                comment: "Comment",
                user: "User",
            },
        },
        universal_artist: {
            breadcrumb: "Universal",
            stats: {
                nav: "Stats",
                empty: "No Results",
                date: "Date",
                total: "Global",
                matched_total: "Weekly",
                matched_simple: "Simple",
                matched_creation: "Creation",
                discarded: "Discarded",
            },
            coverage: {
                nav: "Coverage informations",
                empty: "No Results",
                search: "Search...",
                simple: "Simple",
                creation: "Creation",
                discarded: "Discarded",
                id: "Id",
                name: "Name",
                ms_artist: "MS Artist",
                comment: "Comment",
                user: "User",
            },
        },
    },
    api: {
        breadcrumb: "API",
        monitoring: {
            breadcrumb: "Monitoring",
            token: "Api Token",
            status: {
                head: "Status",
                remaining: "Remaining",
                limit: "Limit",
                retry: "Retry after",
                retry_description:
                    "The reset date will only be displayed once you reach 0 remaining queries. It indicates the date when you will be able to make new queries.",
                status: "Status",
            },
            historic: {
                head: "Historic",
                date: "Date",
                requests: "Requests",
            },
        },
    },
    moodmedia: {
        breadcrumb: "Moodmedia",
        search: {
            breadcrumb: "Search",
            results: "Results",
            empty: "No Results",
            title: "Title",
            artist: "Artist",
            id: "Id",
            original_release_date: "Release date",
            ms_album_title: "Album title",
            name: "Name",
            subtitle: "Subtitle",
            artists: "Artists",
            mbid: "Mbid",
            match: "Match",
            popularity: "Popularity",
            link: "WS2",
        },
        recommendation: {
            form: {
                error: "MS and MM fields cannot be entered at the same time",
                positive: "The field must be positive",
                min: "The field must not be less than 0",
                max: "The field must not be greater than 100",
            },
            breadcrumb: "Recommendation",
            results: "Results",
            empty: "No Results",
            search: "Search...",
            ms_track_ids: "MS Track Ids",
            ms_track_id: "MS Track Id",
            ms_artist_ids: "MS Artist Ids",
            mm_track_ids: "MM Track Ids",
            mm_track_id: "MM Track Id",
            filter_no: "Filter NO",
            catalog: "Catalog",
            min_accuracy: "Min accuracy",
            name: "Name",
            title: "Title",
            subtitle: "Subtitle",
            genre: "Genre",
            original_album: "Album",
            original_release_date: "Release Date",
            artists: "Artists",
            mbid: "Mbid",
            match: "Match",
            popularity: "Popularity",
        },
    },
    snep: {
        file: {
            breadcrumb: "Management of SNEP files",
            fileList: {
                head: "Files",
                search: "ID / Type / Date",
                empty: "No files",
                id: "ID",
                name: "Type Chart",
                period: "Period",
                date: "Date",
                imported: "Imported",
                exportedSnep: "Exported SNEP",
                exportedYadeck: "Exported Yadeck",
                upload: {
                    button: "Add",
                    head: "Add a SNEP file",
                    file: "Click to choose a file on your computer",
                    success: "The file has been added",
                },
                import: {
                    head: "Import a SNEP file",
                    name: "File",
                    startDate: "Start",
                    endDate: "End",
                    chart: {
                        label: "Chart",
                        placeholder: "Select a chart",
                        album: "Album",
                        track: "Track",
                    },
                    success: "The file has been imported",
                },
                export: {
                    pending: "Exporting file...",
                    success: "The file has been exported",
                },
                clean: {
                    title: "Delete the exports",
                    success: "The exports have been deleted",
                },
                delete: {
                    title: "Delete the file",
                    success: "The file has been deleted",
                },
            },
        },
    },
    matching: {
        tops: {
            breadcrumb: "Matching",
        },
        artist: {
            partner: {
                head: "Partners",
                name: "Name",
            },
            list: {
                head: "Listings",
                empty: "No listings",
                date: "#",
                name: "Name",
                matched: "Matched",
                completed: "Completed",
            },
            artist: {
                head: "Artists",
                empty: "No artists",
                id: "#",
                name: "Name",
                firstname: "Firstname",
                lastname: "Lastname",
                complementary: "Complementary",
                discography: "Discography",
                spotify: "Spotify",
                musicbrainz: "MusicBrainz",
                max_created_at: "Date",
                complementary: "Complementary",
                type: "Type",
                getty_picture: "Getty pictures",
                picture: (nb) => `${nb} picture${nb > 1 ? "s" : ""}`,
                artist: "Artist Music-Story",
                action: "Action",
                checked: "Checked",
                edit_matchings: "Edit matchings",
                match: {
                    success: "Matching created",
                    error: (message) =>
                        `Error while creating matching: ${message}`,
                },
                unmatch: {
                    success: "Matching deleted",
                    error: (message) =>
                        `Error while deleting matching: ${message}`,
                },
                mark: {
                    button: {
                        title: "Mark as 'Skiped'",
                    },
                    modal: {
                        title: "Are you sure ?",
                        text: "You want to skip this artist ?",
                        placeholder: "Comment",
                    },
                    success: "Artist skiped",
                    error: (message) =>
                        `Error while skiping artist: ${message}`,
                },
                unmark: {
                    button: {
                        title: "Mark as 'Not skiped'",
                    },
                    success: "Artist recovered",
                    error: (message) =>
                        `Error while recovering artist: ${message}`,
                },
                check: {
                    button: {
                        title: "Mark as 'Checked'",
                    },
                    success: "Artist checked",
                    error: (message) =>
                        `Error while cheking artist: ${message}`,
                },
                artistCreated: {
                    button: {
                        title: "Mark as 'Artist created'",
                    },
                    success: "Artist marked as created",
                    error: (message) =>
                        `Error while marking the artist : ${message}`,
                },
                unartistCreated: {
                    button: {
                        title: "Mark as 'Artist not created'",
                    },
                    success: "Artist marked as not created",
                    error: (message) =>
                        `Error when unmarking the artist : ${message}`,
                },
                filter: {
                    matched: {
                        placeholder: "Matched",
                        true: "Matched",
                        false: "Not matched",
                    },
                    completed: {
                        placeholder: "Completed",
                        true: "Completed",
                        false: "Not completed",
                    },
                    checked: {
                        placeholder: "Checked",
                        true: "Checked",
                        false: "Not checked",
                    },
                    skiped: {
                        placeholder: "Skiped",
                        true: "Skiped",
                        false: "Not skiped",
                    },
                },
                legend: {
                    title: "Legend",
                    completed: "Completed",
                    matched: "Matched",
                    skiped: "Skiped",
                    notAutoMatched: "Not Matched auto (duplicate)",
                    error: "Error",
                },
            },
        },
        album: {
            partner: {
                head: "Partners",
                name: "Name",
            },
            list: {
                head: "Listings",
                empty: "No listings",
                date: "#",
                name: "Name",
                matched: "Matched",
            },
            album: {
                head: "Albums",
                empty: "No albums",
                rank: "Rank",
                cover: "Cover",
                id: "#",
                title: "Title",
                snep_artist: "Snep Artist",
                artist: "Artist Music-Story",
                album: "Album Music-Story",
                spotify_album: "Spotify Album",
                fnac_album: "Fnac Album",
                match: {
                    success: "Matching created",
                    error: (message) =>
                        `Error while creating matching: ${message}`,
                },
                unmatch: {
                    success: "Matching deleted",
                    error: (message) =>
                        `Error while deleting matching: ${message}`,
                },
                filter: {
                    matched: {
                        placeholder: "Matched",
                        true: "Matched",
                        false: "Not matched",
                    },
                },
                legend: {
                    title: "Legend",
                    matched: "Matched",
                    partially_matched: "Partially matched",
                },
            },
        },
        track: {
            partner: {
                head: "Partners",
                name: "Name",
            },
            list: {
                head: "Listings",
                empty: "No listings",
                date: "#",
                name: "Name",
                matched: "Matched",
                progress: "Progress",
            },
            track: {
                head: "Tracks",
                empty: "No tracks",
                rank: "Rank",
                cover: "Cover",
                id: "#",
                title: "Title",
                snep_artist: "Snep Artist",
                artist: "Artist Music-Story",
                recording: "Recording Music-Story",
                duration: "Duration",
                artist_album: "Artist - Album",
                case: "Case",
                lyricfind: "Lyricfind Track",
                actions: "Actions",
                spotify_track: "Spotify Track",
                fnac_album: "Fnac Album",
                match: {
                    success: "Matching created",
                    error: (message) =>
                        `Error while creating matching: ${message}`,
                },
                unmatch: {
                    success: "Matching deleted",
                    error: (message) =>
                        `Error while deleting matching: ${message}`,
                },
                request: {
                    button: {
                        enabled: "Submit the lyric request to Lyricfind",
                        disabled: "Request submitted to Lyricfind",
                    },
                    success: "Request submitted to Lyricfind",
                    error: (message) =>
                        `Error while submitting request: ${message}`,
                },
                discard: {
                    button: {
                        title: "Discard",
                    },
                    modal: {
                        title: "Are you sure ?",
                        text: "You want to discard this artist ?",
                        placeholder: "Comment",
                    },
                    success: "Title discarded",
                    error: (message) =>
                        `Error while discarding title: ${message}`,
                },
                recover: {
                    button: {
                        title: "Recover",
                    },
                    success: "Title recovered",
                    error: (message) =>
                        `Error while recovering title: ${message}`,
                },
                filter: {
                    matched: {
                        placeholder: "Matched",
                        true: "Matched",
                        false: "Not matched",
                    },
                    discarded: {
                        placeholder: "Discarded",
                        true: "Discarded",
                        false: "Not discarded",
                    },
                },
                legend: {
                    title: "Legend",
                    matched: "Matched",
                    completed: "Completed",
                    partially_matched: "Partially matched",
                    discarded: "Discarded",
                },
            },
        },
    },
    profile: {
        personal_details: {
            title: "Personal details",
            breadcrumb: "Profile",
            head: "Main information",
            account_type: {
                title: "Account type",
                music_service: "Music service",
                cmo: "CMO",
                label_distributor: "Label / Distributor",
                artist_manager: "Artist / Manager",
            },
            firstname: "Firstname",
            lastname: "Lastname",
            email: "Mail",
            company: "Company",
            label: "Label",
            artist: "Artist",
            role: {
                title: "Role",
                music_editor: "Music editor",
                marketing: "Marketing",
                catalog_management: "Catalog Management",
                product_management: "Product Management",
                tech: "Tech",
                executive: "Executive",
                other: "Other",
                promotion: "Promotion",
                business_development: "Business development",
                a_and_r: "A&R",
                supply_chain: "Supply chain",
                analytics: "Analytics",
            },
            describe: "Precise",
            language: "Language",
            expires_at: "Expiration date",
            token: "Api Token",
            success: "The information have been saved",
        },
        change_password: {
            title: "Change password",
            old_password: "Old password",
            new_password: "New password",
            confirm_password: "Confirm password",
            success: "Password has been updated",
        },
    },
    search: {
        breadcrumb: "Search",
        placeholder: "Artist / Album / Recording",
        all: "All",
        artists: "Artists",
        albums: "Albums",
        recordings: "Recordings",
        empty: "No results",
        more: "See more",
        artistsData: {
            head: "Artists",
            cover: "Image",
            id: "ID",
            name: "Name",
            popularity: "Popularity",
            peak: "Peak popularity",
            complementary: "Complementary",
            type: "Type",
            genre: "Genre",
            country: "Country",
            role: "Role",
            actions: "Actions",
        },
        albumsData: {
            head: "Albums",
            cover: "Cover",
            id: "ID",
            title: "Title",
            artist: "Artist",
            type: {
                head: "Type",
                live: "Live",
                remix: "Remix",
                compilation: "Compilation",
                karaoke: "Karaoke",
                ring: "Ring",
                other: "Other",
                original: "Original",
            },
            format: {
                head: "Format",
                album: "Album",
                ep: "EP",
                single: "Single",
                mixtape: "Mixtape",
                mcd: "Maxi",
            },
            genre: "Genre",
            release_date: "Original release date",
            label: "Label",
            actions: "Actions",
        },
        recordingsData: {
            head: "Recordings",
            cover: "Cover",
            id: "ID",
            title: "Title",
            subtitle: "Subtitle",
            popularity: "Popularity",
            artist: "Artist",
            duration: "Duration",
            genre: "Genre",
            release_date: "Original release date",
            complementary: "Related content",
            has_credits: "Credits",
            has_lyric: "Lyrics",
            has_audio: "Audio description",
            actions: "Actions",
        },
    },
    base: {
        user: {
            account: "Profile",
            logout: "Log out",
        },
        nav: {
            home: "Home",
            search: "Search",
            title: {
                partners: "Partners",
                admin: "Administration",
            },
            apple: {
                title: "Apple",
                sample: {
                    title: "Sample",
                    assessment: "Assessment",
                    management: "Management",
                    report: "Report",
                    guideline: "Guidelines",
                },
            },
            documentation: {
                title: "Documentation",
            },
            data: {
                title: "Data",
                artists: {
                    title: "Artists",
                    search: "Advanced search",
                    create: "Creation",
                    followup: "Creation/deletion tracking",
                },
                roles: "Roles",
                country: "Country",
                genres: "Genres",
                charts: {
                    title: "Charts",
                    display: "Display",
                    translation: "Translation",
                },
                labelPromo: "Labels",
                data_external: {
                    title: "Data External",
                    internationals_charts: "Internationals charts",
                    deezer_top_songs: "Deezer - top songs",
                    universal_artist: "Universal",
                },
                misc_entity: "Shows",
                api: {
                    title: "API",
                    getting_started: "Getting started",
                    api_reference: "API Reference",
                    monitoring: "Monitoring",
                    status: "Status",
                },
            },
            tools: {
                title: "Tools",
                artistTitle: "Artist-Title",
            },
            plannings: {
                title: "Plannings",
                overview: "Overview",
                manage: "Plannings Management",
                picture: "Picture Works",
                billing: "Billing",
                statistic: "Statistics",
            },
            pictures: {
                title: "Pictures",

                label: {
                    gallery: "Available for Clients",
                    status: "Pending / Tracking",
                    validation: "Images to be processed",
                    rejected: "Images rejected",
                },
                curation: {
                    followup: "Curation follow-up",
                },
                getty: {
                    followup: "Getty follow-up",
                },
            },
            matching: {
                title: "Matching",
                artist: "Artists",
                album: "Albums",
                track: "Tracks",
                tops: "Charts",
                lyricsSample: "Lyrics sample",
                partner_tops: "Tops Artists",
            },
            snep: {
                title: "SNEP",
                file: "Top management",
                chart: "Charts Management",
            },
            cnm: {
                title: "CNM",
                repositories: "Repositories",
                aggregation: "Aggregation",
                consolidation: "Consolidation",
                charts: "Charts",
                report: "Report",
            },
            moodmedia: {
                title: "Moodmedia",
                search: "Search",
                recommendation: "Recommendation",
            },
            admin: {
                users: {
                    title: "Users",
                    user: "User Management",
                    author: "Author Management",
                },
                rights: {
                    title: "Rights",
                    role: "Role Management",
                    right: "Rights Management",
                    group: "Group Management",
                },
            },
        },
    },
    common: {
        explicit: {
            long: "Explicit",
            short: "E",
        },
        multiSearch: {
            placeholder: "Artist / Album / Recording",
            explanation: () => (
                <>
                    Search for artists, albums, recordings, to explore all the
                    associated metadata available with API or dataflow:
                    <ul className="mb-0">
                        <li>
                            artist: ISNI, biographies, images, popularity,
                            associated artists...
                        </li>
                        <li>
                            albums: UPC metadata, canonical album, genre,
                            original release date...
                        </li>
                        <li>
                            recordings: ISRCs metadata, credits, lyrics, audio
                            description...
                        </li>
                    </ul>
                </>
            ),
            search: "Music-Story search",
            artist: "Artist",
            album: "Album",
            type: {
                live: "Live",
                remix: "Remix",
                compilation: "Compilation",
                karaoke: "Karaoke",
                ring: "Ring",
                other: "Other",
                original: "Original",
            },
            format: {
                album: "Album",
                ep: "EP",
                single: "Single",
                mixtape: "Mixtape",
                mcd: "Maxi",
            },
            recording: "Recording",
            has_credits: "Credits",
            has_lyric: "Lyrics",
            has_audio: "Audio description",
            more: "See more",
            empty: "No results",
        },
        matching: {
            status: {
                not_found: "Not found",
                matched: "Matched",
                mixed: "Mixed discography",
                duplicates: "Duplicates",
                unmatched: "Unmatched",
            },
        },
        partners: {
            "7D": "7Digital",
            AZ: "Amazon",
            DG: "Discogs",
            DZ: "Deezer",
            IT: "iTunes",
            MB: "MusicBrainz",
            NA: "Napster",
            PR: "PriceMinister",
            QB: "Qobuz",
            UN: "Universal",
            SP: "Spotify",
            CJ: "CD Journal",
            LM: "LineMusic",
        },
        apiDownloadButton: {
            pending: "Creating file",
            success: "The file has been created",
        },
        editMatchingsTable: {
            empty: "No candidates",
            id: "ID",
            name: "Name",
            type: {
                title: "Type",
                discarded: "Discarded",
                valid: "Validated manually",
                invalid: "Invalidated manually",
                matchedByName: "Matched by name",
                matchedByDiscography: "Matched by discography",
            },
            representativity: "Repr.",
            actions: {
                validate: "Validate matching",
                cancelValidation: "Cancel manual validation",
                invalidate: "Invalidate matching",
                cancelInvalidation: "Cancel manual invalidation",
            },
        },
        editMatchingsModal: {
            status: "Status:",
            matched: "Matched",
            not_found: "Not Matched",
        },
        pictureCheckButton: {
            title: "Check",
            confirmModal: {
                title: "Are you sure ?",
                text: "You want to validate this artist's pictures ?",
            },
            commentModal: {
                title: "Add a comment",
                success: "Comment added",
                error: (message) => `Error while adding comment: ${message}`,
            },
        },
        pictureTagsModal: {
            title: "Update original image tags",
            titleVersion: "Update square image tags",
            metas: "Metas",
            tags: "Tags",
            description: {
                2: () => (
                    <>
                        <strong>Indoor:</strong> Indoor picture that doesn’t fit
                        in the other categories
                        <br />
                        <strong>Outdoor:</strong> Outdoor picture that doesn’t
                        fit in the other categorie
                        <br />
                        <strong>Stage:</strong> On stage picture taken during a
                        show
                    </>
                ),
                7: () => (
                    <>
                        <strong>1:</strong> Image size greater than or equal to
                        2500px.
                        <br />
                        <strong>2:</strong> Image size between 1500px (included)
                        and 2500px (excluded).
                        <br />
                        <strong>3:</strong> Image size between 800px (included)
                        and 1500px (excluded).
                    </>
                ),
                4: () => (
                    <>
                        When the picture has been taken. As it is hard to know
                        precisely the date, we implemented it as a 5 year
                        period. The goal is to place the picture in time to know
                        at which part of the career of the artist the image has
                        been taken. A <strong>shoot date tag</strong> has also
                        been implemented if you encounter the precise date of
                        the shooting. For Getty pictures, check in the “Meta''
                        of the image if it is mentioned.
                    </>
                ),
                5: () => (
                    <>
                        <strong>Individual:</strong> when the picture represents
                        only one artist
                        <br />
                        <strong>Group:</strong> several people on the picture
                        but not a full band
                        <br />
                        <strong>Complete group:</strong> A full band is
                        displayed on the picture
                    </>
                ),
                6: () => (
                    <>
                        <strong>Portrait:</strong> From the hair to the
                        shoulders
                        <br />
                        <strong>Half-Length Portrait:</strong> From the hair to
                        the waist
                        <br />
                        <strong>Full-length Portrait:</strong> The whole body is
                        represented
                    </>
                ),
            },
            shotDate: "Shot date",
            msArtists: "MS Artists",
            msArtistsPlaceholder: "Select an artist",
            open_tags: {
                title: "Open tags",
                placeholder: "Enter open tags",
            },
            tags_getty: "Tags Getty",
            success: "Tags updated",
            alert: {
                title: "Are you sure?",
                text: "If you close this window, the data you have entered will be lost.",
            },
            upload: "Upload",
            legal: "Legal",
            cropping: "Cropping",
            tagging: "Tagging",
            warning:
                "The predictions generated by the artificial intelligence received a low score; please check them.",
        },
        pictureCropModal: {
            head: "Resizing the image",
            width: "Width",
            height: "Height",
            rotateLeft: "Rotate left",
            rotateRight: "Rotate right",
            success: "Resize with success",
        },
        pictureMoveModal: {
            title: "Move image",
            artist: "Artist",
            success: "Image moved",
        },
        multiRecordingSelect: {
            globalSearch: "Search in all discographies",
        },
        imageUploadModal: {
            head: "Adding an image",
            link: "Add a link here",
            browse: "Browse",
            file: "Click to choose an image on your computer",
            success: "The image has been uploaded",
            alert: {
                title: "Are you sure?",
                text: "If you close this window, the data you have entered will be lost.",
            },
            upload: "Upload",
            legal: "Legal",
            cropping: "Cropping",
            tagging: "Tagging",
            warning_upscale:
                "The image will be upscaled when uploaded. The process may take a few seconds. Please recheck image quality in the next step.",
            warning_downscale:
                "The image will be downsized when uploaded. This may take a few seconds. Please check image quality again in the next step.",
        },
        pictureInformationModal: {
            head: "Image information",
            source: "Source",
            source_url: "URL Source",
            author: "Photographer",
            copyright: "Copyright notice",
            year: "Year",
            copyright_owner: "Copyright owner",
            license: "License",
            complementary: "Complementary",
            artist_site: "Artist's website",
            artist_label: "Artist label",
            other: "Other",
            description: {
                source: "Website source of the image, from which you upload the image.",
                source_url: "URL of the image from the source website.",
                author: "Author of the image.",
                copyright:
                    "Legal text to be displayed by anyone using our image.",
                year: "The year the image was published.",
                copyright_owner:
                    "The owner of the patrimonial rights of the image. Only if different from the photograph.",
                complementary:
                    "Additional informations about the image such as the URLs of other websites where the image is published.",
            },
            success: "The image information has been saved",
            alert: {
                title: "Are you sure?",
                text: "If you close this window, the data you have entered will be lost.",
            },
            upload: "Upload",
            legal: "Legal",
            cropping: "Cropping",
            tagging: "Tagging",
            licenseInformation: {
                head: "License details",
                1: {
                    title: "GNU/FDL",
                    body: "The GNU / FDL (Free Documentation Licence) allows anyone to modify, copy and distribute the document if it is republished under this license. We'll take care of to indicate in any case the source (URL of the page where the document was found) and the type of license. In fact, this license was originally intended for the user manuals of free software distributed under the GNU/GPL license and it was always necessary to include this license with a history of modifications, authors' names etc... Over time, this license has been extended to other documents (images, sounds...) with which we do not always find a copy of the license (which must be the English version, that's what is specified in this same license!). As far as we are concerned, if we use a licensed image GNU/FDL by adding the license type, author and source, we will have already done our best. work.",
                },
                2: {
                    title: "CC-BY",
                    body: "Creative Commons: Paternity (BY): The rights holder authorizes any exploitation of the work, including for commercial purposes, as well as the creation of derivative works, including distribution is also allowed without restriction, provided that it is attributed to its author by citing his name. This license is recommended for the distribution and maximum use of works.",
                },
                3: {
                    title: "CC-BY-SA",
                    body: "Creative Commons: Paternity + Sharing under the same conditions (BY SA): The rights holder authorizes any use of the original work (including for commercial purposes) as well as the creation of derivative works, provided they are distributed under a license identical to the one that governs the original work. This license is often compared to copyleft licenses free software. This is the license used by Wikipedia.",
                },
                4: {
                    title: "CC-BY-ND",
                    body: "Creative Commons: Attribution + No Modification (BY ND): The rights holder authorizes any use of the original work (including for commercial purposes), but does not authorize the creation of derivative works.",
                },
                5: {
                    title: "CC-BY-NC",
                    body: "Creative Commons: Attribution + No Commercial Use (BY NC): the rights holder authorizes the exploitation of the work, as well as the creation of derivative works, provided that it is not a commercial use (commercial uses are still subject to its authorization).",
                },
                6: {
                    title: "CC-BY-NC-SA",
                    body: "Creative Commons: Attribution + No Commercial Use + Sharing under the same conditions (BY NC SA): The rights holder authorizes the exploitation of the original work for purposes other than commercial, as well as the creation of derivative works, provided they are distributed under a license identical to the one governing the original work.",
                },
                7: {
                    title: "CC-BY-NC-ND",
                    body: "Creative Commons: Attribution + No Commercial Use + No Modification (BY NC ND) : The the rights holder authorizes the use of the original work for non-commercial purposes, but does not authorize the creation of derivative works.",
                },
                8: {
                    title: "all-rights-reserved",
                    body: "All rights reserved.",
                },
                9: {
                    title: "royalty-free",
                    body: "Royalty-free",
                },
                10: {
                    title: "Public Domain",
                    body: "Public domain is the absence of copyright, meaning that the given work is not subject to copyright or other legal restrictions.",
                },
                11: {
                    title: "CC0",
                    body: "CC0 (aka CC Zero) is a public dedication tool, which enables creators to give up their copyright and put their works into the worldwide public domain. CC0 enables reusers to distribute, remix, adapt, and build upon the material in any medium or format, with no conditions.",
                },
            },
        },
        pictureInformationModalGetty: {
            upload: "Upload",
            legal: "Legal",
            cropping: "Cropping",
            tagging: "Tagging",
            source: "Source",
            source_url: "URL Source",
            author: "Photographer",
            copyright: "Copyright notice",
            year: "Year",
            copyright_owner: "Copyright owner",
            license: "License",
            complementary: "Complementary",
            description: {
                source: "Website source of the image, from which you upload the image.",
                source_url: "URL of the image from the source website.",
                author: "Author of the image.",
                copyright:
                    "Legal text to be displayed by anyone using our image.",
                year: "The year the image was published.",
                copyright_owner:
                    "The owner of the patrimonial rights of the image. Only if different from the photograph.",
                complementary:
                    "Additional informations about the image such as the collection details and allowed use",
            },
        },
        imageCropModal: {
            head: "Resizing the image",
            width: "Width",
            height: "Height",
            rotateLeft: "Rotate left",
            rotateRight: "Rotate right",
            success: "Resize with success",
            alert: {
                title: "Are you sure?",
                text: "If you close this window, the data you have entered will be lost.",
            },
            upload: "Upload",
            legal: "Legal",
            cropping: "Cropping",
            tagging: "Tagging",
        },
        labelUploadModal: {
            head: "Adding an artist image",
            link: "Add a link here",
            browse: "Browse",
            file: "Click to choose an image on your computer",
            title: "Title",
            copyright: "Copyright",
            takedown: "Takedown date",
            label: "Label",
            success: "The image has been uploaded",
        },
        labelInformationModal: {
            head: "Image information",
            title: "Title",
            copyright: "Copyright",
            takedown: "Takedown date",
            success: "The image information has been saved",
        },
        charts: {
            categoryList: {
                empty: "No Results",
                head: "Category",
                title: "Title",
                source: "Source",
                area: "Area",
                country: "Country",
                main: "Main",
                format: "Format",
                link: "Link",
            },
            chartsList: {
                empty: "No Results",
                head: "Charts List",
                year: "Year",
                month: "Month",
                startDate: "Start Date",
                endDate: "End Date",
                matched: "Matched",
                dataMatched: "Data Matched",
                translated: "Translated",
                userData: "To do",
            },
            charts: {
                empty: "No Results",
                head: "Charts",
                rank: "Rank",
                artist: "Artist",
                album: "Album",
                track: "Track",
                previousRank: "Previous Rank",
                dateIn: "Date In",
                dateOut: "Date Out",
                nbWeek: "Number Weeks",
                rankIn: "Rank In",
                maxRank: "Max Rank",
                rankOut: "Rank Out",
                msArtist: "MS Artist",
                msAlbum: "MS Album",
                msRecordings: "MS Recordings",
                informations: "Information",
                hasBioFR: "Has Bio FR",
                hasBioEN: "Has Bio EN",
                hasBioDE: "Has Bio DE",
                hasBioJA: "Has Bio JA",
                hasBioES: "Has Bio ES",
                hasBioPT: "Has Bio PT",
                hasPictures: "Has Picture",
                hasCuration: "Has Curation",
                hasReview: "Has Review",
                lastUpdated: "Last planning entry",
                nbRelated: "Nb Related",
                albumsWithoutGenre: "Albums Without Genre",
                discarded: "Discarded",
                matched: "Matched",
                translated: "Translated",
                creation: "Creation",
                filter: {
                    matched: {
                        placeholder: "Matched",
                        true: "Matched",
                        false: "Not matched",
                    },
                    newEntry: {
                        placeholder: "New entry",
                        true: "New entry",
                        false: "Old entry",
                    },
                    toBeTreated: {
                        placeholder: "To be treated",
                        true: "To be treated",
                    },
                    discarded: {
                        placeholder: "Discarded",
                        true: "Discarded",
                        false: "Not discarded",
                    },
                },
                albumTranslationsForm: {
                    artist: "Artist",
                    album: "Album",
                    translatedTitle: "Translated title",
                    translatedName: "Translated name",
                    multipleNamesWarning:
                        "The matched artist has multiple translated names." +
                        "The following translations will be erased:",
                    save: {
                        success: "The translations have been saved",
                        error: (message) =>
                            `Error while saving translations: ${message}`,
                        tooltip: "Save translations",
                    },
                    reset: "Reset the form",
                },
                trackTranslationsForm: {
                    artist: "Artist",
                    albums: "Albums",
                    recordings: "Recordings",
                    albumFormat: {
                        album: "Album",
                        ep: "EP",
                        single: "Single",
                        mixtape: "Mixtape",
                        mcd: "Maxi",
                    },
                    albumType: {
                        live: "Live",
                        remix: "Remix",
                        compilation: "Compilation",
                        karaoke: "Karaoke",
                        ring: "Ring",
                        other: "Other",
                        original: "Original",
                    },
                    translatedTitle: "Translated title",
                    translatedSubtitle: "Translated subtitle",
                    translatedName: "Translated name",
                    multipleNamesWarning:
                        "The matched artist has multiple translated names. " +
                        "The following translations will be erased:",
                    multipleTitlesWarning:
                        "All of the matched recordings do not have the same translated title. " +
                        "Please check that the matchings are correct. " +
                        "The following translations will be erased:",
                    noTitle: "No title",
                    noSubtitle: "No subtitle",
                    noTranslation: "No translation",
                    save: {
                        success: "The translations have been saved",
                        error: (message) =>
                            `Error while saving translations: ${message}`,
                        tooltip: "Save translations",
                    },
                    reset: "Reset the form",
                },
                discardButton: {
                    discardTooltip: "Discard",
                    recoverTooltip: "Recover",
                    modalTitle: "Comment",
                },
                addToPlanningButton: {
                    title: "Add to planning",
                },
            },
        },
        picture: {
            action: {
                add: "Add an image",
                edit: "Edit image information",
                tags: "Edit original image tags",
                tagsVersion: "Edit square image tags",
                crop: "Resize the image",
                move: "Move the image",
                del: {
                    title: "Delete image",
                    alert: {
                        title: "Are you sure ?",
                        text: "To want to delete this image ?",
                    },
                    success: "Image deleted",
                    error: (message) =>
                        `Error while deleting this image: ${message}`,
                },
            },
            getty: {
                bas: {
                    head: "Sandbox",
                    empty: "No images in the sandbox",
                    width: "Width",
                    height: "Height",
                    created_at: "Creation date",
                    loading: "Loading...",
                    more: "See more",
                    filters: {
                        search_type: {
                            title: "Type of research",
                            best_match: "Better matches",
                            most_popular: "Most popular",
                        },
                    },
                    action: {
                        detail: {
                            head: "Detail of a picture of the Sandbox",
                            dimensions: "dimensions",
                            id: "id",
                            title: "title",
                            collection_name: "collection_name",
                            caption: "caption",
                            people: "people",
                            date_camera_shot: "date_camera_shot",
                            date_created: "date_created",
                            date_submitted: "date_submitted",
                            success: "Image added",
                            error: (message) =>
                                `Error while adding this image: ${message}`,
                        },
                    },
                },
            },
        },
    },
    utils: {
        select: {
            empty: "No results",
            placeholder: "Search...",
            loading: "Loading...",
            noOptions: "No options",
            create: "Create",
        },
        copy: {
            success: "The text has been copied in the clipboard",
            error: "Cannot copy text to clipboard",
        },
        pagination: {
            total: ({ size }) => `${size} Results`,
            first_page: "First page",
            last_page: "Last page",
            previous_page: "Previous page",
            next_page: "Next page",
        },
        form: {
            required: "This field is required",
            number: "This field is not a number",
            date_placeholder: "YYYY-MM-DD",
            date_format: "The format is invalid (YYYY-MM-DD)",
            date_zero: "The date '0000-00-00' is invalid",
            barcode_format:
                "The barcode should be a number with 12 or 13 digits",
            isrc_format: "ISRC format invalide (AANNN0000000)",
            duration_format: "Duration format is invalid (hh:mm:ss)",
            duration_placeholder: "hh:mm:ss",
            year_format: "Year format is invalid (YYYY)",
            end_date_greater_than_start:
                "The end date must be greater than or equal to the start date",
            url: "The url is not valid",
            wysiwyg: {
                placeholder: "Start writing...",
                bold: "Bold",
                italic: "Italic",
                underline: "Underline",
                strikethrough: "Strikethrough",
            },
            email: "The email is not valid",
            isni_format:
                "The ISNI should be a 16 digits code, or a 15 digits code followed by the letter X",
            upload_max_size: `The file is too big. (Max: ${
                UPLOAD_MAX_SIZE / 1000000
            } Mo)`,
            must_contain_uppercase:
                "Must contain at least one uppercase letter",
            must_contain_lowercase:
                "Must contain at least one lowercase letter",
            must_contain_8_characters: "Must be 8 characters or more",
            must_contain_digit: "Must contain at least one digit",
            must_contain_letter: "Must contain at least one letter",
            passwords_must_match: "Passwords do not match",
            not_hit_discovery_at_same_time:
                "The Hit and Editor's Pick fields cannot be filled in simultaneously.",
        },
        button: {
            cancel: "Cancel",
            confirm: "Confirm",
            create: "Create",
            back: "Back",
            add: "Add",
            edit: "Edit",
            delete: "Delete",
            deleteAll: "Delete all",
            validate: "Validate",
            previous: "Previous",
            next: "Next",
            now: "Now",
            save: "Save",
            close: "Close",
            transfer: "Transfer",
            reset: "Reset",
            approve: "Approve",
            reject: "Reject",
            export: "Export",
            generate: "Generate",
            upload: "Upload",
            search: "Search",
            merge: "Merge",
            continue: "Continue",
            download: "Download",
            browse: "Browse",
            login: "Log in",
            translate: "Translate",
        },
        sweetalert: {
            warning: "Warning",
            error: "Error",
            cancel: "No, I'm cancelling",
            close: "Close",
            confirm: "Yes, I confirm",
            ok: "Ok, I got it",
        },
        filters: {
            all: "all",
            none: "none",
        },
        lang: {
            PT: "Portuguese",
            JA: "Japanese",
            DE: "German",
            EN: "English",
            ES: "Spanish",
            FR: "French",
            KO: "Korean",
            CD_JOURNAL: "CD Journal",
        },
        nth: (n) => (
            <>
                {n?.toLocaleString("en")}
                <sup>
                    {n % 10 === 1
                        ? "st"
                        : n % 10 === 2
                        ? "nd"
                        : n % 10 === 3
                        ? "rd"
                        : "th"}
                </sup>
            </>
        ),
    },
    landing: {
        breadcrumb: "Landing page",
        industry: "Market statistics",
        ms: "Database statistics",
        industryStats: {
            chart_title: (top) => `Based on the official top ${top ?? ""} - `,
            chart_date: ({ start_date, end_date }) =>
                ` (From ${start_date ?? ""} to ${end_date ?? ""})`,
            top_title: (country) =>
                `Current most popular titles - ${country ?? ""}`,
            top_title_description:
                "The most popular songs in the selected country for the current month.",
            top_artist: (country) =>
                `Current most popular artists - ${country ?? ""}`,
            top_artist_description:
                "The most popular artists in the selected country for the current month.",
            top_progression: ({ id_country, country }) =>
                id_country == 33
                    ? `Best entries of the day - ${country ?? ""}`
                    : `Best entries of the week - ${country ?? ""}`,
            top_progression_description: (id_country) =>
                id_country == 33
                    ? "Top chart entries yesterday."
                    : "Top chart entries last week.",
            sexe_repartition: (country) =>
                `Distribution of artists by type - ${country ?? ""}`,
            sexe_repartition_description:
                "Representation of “group” and “solo” artists (and by gender for solo artists) in the official chart of the selected country.",
            genre_repartition: (country) =>
                `Represented musical genres - ${country ?? ""}`,
            genre_repartition_description:
                "Distribution of musical genres represented in the official chart of the selected country.",
            language_repartition: (country) =>
                `Sung languages - ${country ?? ""}`,
            language_repartition_description:
                "Distribution of languages sung in the official chart of the selected country.",
            top_emerging: (country) => `Emerging artists - ${country ?? ""}`,
            top_emerging_description:
                "Most popular artists who first appeared in the charts in the past 3 months.",
            current_catalog: (country) =>
                `Distribution of songs by type of seniority - ${country ?? ""}`,
            current_catalog_description:
                "Representation of “Current” (release date < 18 months) and “Catalog” (release date > 18 months) titles in the official chart of the selected country.",
            no_data: "Unknown",
            other: "Other",
            gold: "Catalog",
            current: "Current",
            person: "Person",
            band: "Band",
            man: "Man",
            woman: "Woman",
            top_3: "Top 3:",
            total: "Total: ",
        },
        msStats: {
            artists: {
                title: "Artists",
                artists_with_photo: "Artists with image(s)",
                artists_with_bio: "Artists with biographie(s)",
                biographies_description: "Biographies available in :",
            },
            albums: {
                title: "Albums",
                canonical_albums: "Canonical albums",
                albums_with_cover_art: "Albums with cover art",
                releases: "Releases",
            },
            recordings: {
                title: "Recordings",
                unique_recording: "Unique recording",
            },
        },
    },
};
