import React, { useState } from "react";
import ApiMonitoring from "./ApiMonitoring";
import { useLocation } from "react-router";

const EnhancedApiMonitoring = (props) => {
    const location = useLocation();
    const [requests, setRequests] = useState();

    return (
        <ApiMonitoring
            {...props}
            location={location}
            requests={requests}
            setRequests={setRequests}
        />
    );
};

export default EnhancedApiMonitoring;
