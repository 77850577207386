import React, { useState, useEffect, useCallback, useContext } from "react";
import CreateOrUpdate from "./CreateOrUpdate";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import { useParams, useLocation } from "react-router";
import I18n from "shared/lib/I18n";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    DiscographyContext,
    ALBUM_ADD,
    ALBUM_UPD,
    TRACK_BASIC_UP_TO_DATE,
} from "pages/ArtistManagement/Discography";

const schema = yup.object().shape({
    title: yup.string().nullable().trim().required("utils.form.required"),
    subtitle: yup.string().nullable().trim(),
    type: yup.string().ensure().required("utils.form.required"),
    format: yup.string().ensure().required("utils.form.required"),
    publisher: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => value || null),
    release_date: yup
        .string()
        .nullable()
        .trim()
        .required("utils.form.required")
        .test(
            "is-not-0",
            "utils.form.date_zero",
            (value) => value !== "0000-00-00"
        )
        .matches("^[0-9]{4}-[0-9]{2}-[0-9]{2}$", "utils.form.date_format"),
});

const defaultValues = {
    title: null,
    type: null,
    format: null,
    label: null,
    publisher: null,
    release_date: null,
    genre: null,
    reference: null,
};

const EnhancedCreateOrUpdate = ({ artistId, albumId, onSuccess, ...props }) => {
    const { dispatchDiscography } = useContext(DiscographyContext);
    const [isLoading, setIsLoading] = useState(false);
    const { locale } = useParams();
    const { api } = useAPI();
    const location = useLocation();
    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { isSubmitting, isDirty, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues,
    });

    const initForm = useCallback(() => {
        if (albumId) {
            setIsLoading(true);
            api.get(`album/${albumId}`, { locale })
                .then((response) => {
                    reset(response);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setIsLoading(false);
                });
        } else if (artistId) {
            setIsLoading(true);
            api.get(`artist/${artistId}/default-album-genre`, { locale })
                .then((response) => {
                    reset({ ...defaultValues, genre: response });
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setIsLoading(false);
                });
        } else {
            reset();
        }
    }, [artistId, albumId, locale, reset]);

    useEffect(initForm, [initForm]);

    const onSubmit = (data) => {
        let apiRequest;
        if (albumId) {
            apiRequest = api.put(`album/${albumId}`, { locale }, data);
        } else {
            apiRequest = api.post(`album`, { id_artist: artistId }, data);
        }
        apiRequest
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.album.basic.${
                            albumId ? "update" : "create"
                        }.success`
                    )
                );
                reset(data);
                dispatchDiscography({
                    type: albumId ? ALBUM_UPD : ALBUM_ADD,
                    data: {
                        id: albumId ? parseInt(albumId, 10) : response.id_album,
                        title: data.title,
                        type: data.type,
                        format: data.format,
                        genre: data.genre?.name,
                        release_date: data.release_date,
                    },
                });
                dispatchDiscography({
                    type: TRACK_BASIC_UP_TO_DATE,
                    data: false,
                });
                onSuccess?.(
                    albumId ? parseInt(albumId, 10) : response.id_album
                );
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `artist.management.disco.objects.album.basic.${
                            albumId ? "update" : "create"
                        }.error`,
                        error.message
                    )
                );
            });
    };

    const typeOptions = [
        {
            value: "LIVE",
            label: I18n.getTranslation(
                location,
                `artist.management.disco.objects.album.basic.typeOptions.live`
            ),
        },
        {
            value: "REMIX",
            label: I18n.getTranslation(
                location,
                `artist.management.disco.objects.album.basic.typeOptions.remix`
            ),
        },
        {
            value: "COMPILATION",
            label: I18n.getTranslation(
                location,
                `artist.management.disco.objects.album.basic.typeOptions.compilation`
            ),
        },
        {
            value: "KARAOKE",
            label: I18n.getTranslation(
                location,
                `artist.management.disco.objects.album.basic.typeOptions.karaoke`
            ),
        },
        {
            value: "RING",
            label: I18n.getTranslation(
                location,
                `artist.management.disco.objects.album.basic.typeOptions.ring`
            ),
        },
        {
            value: "OTHER",
            label: I18n.getTranslation(
                location,
                `artist.management.disco.objects.album.basic.typeOptions.other`
            ),
        },
        {
            value: "ORIGINAL",
            label: I18n.getTranslation(
                location,
                `artist.management.disco.objects.album.basic.typeOptions.original`
            ),
        },
    ];

    const formatOptions = [
        {
            value: "ALBUM",
            label: I18n.getTranslation(
                location,
                `artist.management.disco.objects.album.basic.formatOptions.album`
            ),
        },
        {
            value: "EP",
            label: I18n.getTranslation(
                location,
                `artist.management.disco.objects.album.basic.formatOptions.ep`
            ),
        },
        {
            value: "SINGLE",
            label: I18n.getTranslation(
                location,
                `artist.management.disco.objects.album.basic.formatOptions.single`
            ),
        },
        {
            value: "MIXTAPE",
            label: I18n.getTranslation(
                location,
                `artist.management.disco.objects.album.basic.formatOptions.mixtape`
            ),
        },
        {
            value: "MCD",
            label: I18n.getTranslation(
                location,
                `artist.management.disco.objects.album.basic.formatOptions.mcd`
            ),
        },
    ];

    const referenceOptions = [
        "0",
        "0,5",
        "1",
        "1,5",
        "2",
        "2,5",
        "3",
        "3,5",
        "4",
        "4,5",
        "5",
    ].map((k) => {
        return { value: k, label: k };
    });

    return (
        <CreateOrUpdate
            albumId={albumId}
            initForm={initForm}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            control={control}
            isSubmitting={isSubmitting}
            isDirty={isDirty}
            onSubmit={onSubmit}
            typeOptions={typeOptions}
            formatOptions={formatOptions}
            referenceOptions={referenceOptions}
            location={location}
            locale={locale}
            {...props}
        />
    );
};

export default EnhancedCreateOrUpdate;
